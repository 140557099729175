import {
	AppBar,
	Autocomplete,
	Box,
	Container,
	Divider,
	Grid,
	InputBase,
	Paper,
	Stack,
	Tab,
	Tabs,
	TextField,
	styled,
} from "@mui/material";
import MKInput from "components/MKInput";
import React, { useEffect, useState } from "react";
import logo from "../../../assets/images/artha.png";
import { PlayCircle, Save, Search } from "@mui/icons-material";
import MKTypography from "components/MKTypography";
import { useSelector } from "react-redux";
import _ from "lodash";
import { createFilterOptions } from "@mui/material/Autocomplete";
import Model from "./Model";
import { setProducts } from "features/productSlice";
import MKButton from "components/MKButton";
import MKBox from "components/MKBox";
import { FaMobileScreenButton } from "react-icons/fa6";
import { FaHeadphonesAlt } from "react-icons/fa";
const filter = createFilterOptions();
const EnquiryView = (props) => {
	const [query, setQuuery] = useState("");
	const brands = useSelector((state) => state.brands.brands);
	const models = useSelector((state) => state.models.models);
	const [combos, setCombos] = useState([]);
	const [selectedBrand, setSelectedBrand] = useState(null);
	const [selectedModel, setSelectedModel] = useState(null);
	const [selectedModelValue, setSelectedModelValue] = useState(null);
	const [modelList, setModelList] = useState([]);
	const products = useSelector((state) => state.products.products);
	const variants = useSelector((state) => state.variants.variants);
	const [filteredModels, setFilteredModels] = useState([]);
	const [productList, setProducts] = useState([]);
	const [activeTab, setActiveTab] = useState(0);
	const [queryType, setQueryType] = useState("SPARES");

	const handleTabType = (event, newValue) => {
		setActiveTab(newValue);
		if (newValue == 1) {
			setQueryType("ACCESSORIES");
		} else {
			setQueryType("SPARES");
		}
	};

	const handleDeleteQuery = (query) => {
		console.log(query);
		console.log(modelList);
		const filtered = _.filter(modelList, (m) => {
			return m.modelId != query.modelId;
		});
		setModelList(filtered);
	};
	const handleBrandSelection = (value) => {
		setSelectedModelValue(null);
		setSelectedBrand(value);
		if (value) {
			const modelsAvailable = _.filter(models, (model) => {
				return model.brandId === value.id;
			});

			setFilteredModels(modelsAvailable);
		}
	};

	const buildProducts = () => {
		const pr = _.map(products, (product) => {
			return { id: product.id, label: product.name };
		});
		setProducts(pr);
	};
	const handleSelectModel = (model) => {
		let payload = {};
		setSelectedModelValue(model);
		if (selectedBrand && model) {
			payload["brandId"] = selectedBrand.id;
			payload["brand"] = selectedBrand.name;
			payload["modelName"] = model.name;
			payload["modelId"] = model.id;
			payload["label"] = `${model.name} in ${selectedBrand.name}`;
			setModelList([payload]);
		}
		// setModelList([model]);
	};
	useEffect(() => {
		// const comb = buildBrandModelCombo();
		// console.log(comb);
		// setCombos(comb);
		buildProducts();
	}, []);

	return (
		<MKBox>
			<Container style={styles.container}>
				<Stack
					gap={2}
					sx={{
						width: "100%",
						alignItems: "center",
						justifyContent: "center",
						display: "flex",
						flexDirection: "column",
					}}
				>
					<Box
						style={{
							marginBottom: 10,
							alignItems: "center",
							justifyContent: "center",
							display: "flex",
							flexDirection: "column",
						}}
					>
						{/* <img src={logo} width='100%' height='100%' objectFit='cover' /> */}
						<MKTypography variant='h3' sx={{ color: "#f2727e" }}>
							JUMBUDA
						</MKTypography>
						<MKTypography variant='caption' sx={{ color: "#f2727e" }}>
							Create Enquiries
						</MKTypography>
					</Box>
					<Box sx={{ minWidth: 600 }}>
						<Container>
							<AppBar position='static'>
								<Tabs value={activeTab} onChange={handleTabType}>
									<Tab
										label={
											<MKTypography>
												<FaMobileScreenButton /> Spare Parts
											</MKTypography>
										}
									/>
									<Tab
										label={
											<MKTypography>
												<FaHeadphonesAlt /> Accessories
											</MKTypography>
										}
									/>
								</Tabs>
							</AppBar>
						</Container>
					</Box>

					<Container>
						<Grid container spacing={1}>
							<Grid
								item
								sm={12}
								sx={{ display: { sm: "flex", xs: "none" }, mb: 2 }}
							>
								<Stack
									gap={1}
									direction={"row"}
									sx={{
										border: "1px dashed #000000",
										padding: 2,
										alignItems: "center",
										justifyContent: "center",
										display: "flex",
										flexDirection: "row",
										width: "100%",
									}}
								>
									<Autocomplete
										disablePortal
										freeSolo
										id='brand'
										value={selectedBrand}
										options={brands}
										sx={{ width: "350px" }}
										getOptionLabel={(option) => option.name}
										renderInput={(params) => (
											<TextField {...params} label='Brand' fullWidth />
										)}
										onChange={(e, newValue) => {
											if (newValue) {
												handleBrandSelection(newValue);
											} else {
												setSelectedBrand(null);
											}
										}}
									/>
									<Autocomplete
										disablePortal
										id='combo'
										freeSolo
										options={filteredModels}
										value={selectedModelValue}
										sx={{ width: "100%" }}
										getOptionLabel={(option) => option.name}
										renderInput={(params) => (
											<TextField
												{...params}
												label='Search Product Model'
												fullWidth
											/>
										)}
										onChange={(e, newValue) => {
											if (newValue) {
												handleSelectModel(newValue);
											} else {
												setSelectedModelValue(null);
											}
										}}
									/>
								</Stack>
							</Grid>

							<Grid container sx={{ display: { xs: "flex", sm: "none" } }}>
								{_.map(modelList, (model) => {
									return (
										<Grid item xs={12}>
											{model && (
												<Model
													model={model}
													key={model?.modelId}
													productList={productList}
												/>
											)}
										</Grid>
									);
								})}
							</Grid>

							{_.map(modelList, (model) => {
								return (
									<Grid container sx={{ display: { sm: "flex", xs: "none" } }}>
										<Grid item sm={12}>
											{model && (
												<Model
													model={model}
													key={model?.modelId}
													productList={productList}
													onDelete={handleDeleteQuery}
													variants={variants}
													queryType={queryType}
												/>
											)}
										</Grid>
									</Grid>
								);
							})}
						</Grid>
					</Container>
				</Stack>
			</Container>
		</MKBox>
	);
};
export default EnquiryView;

const styles = {
	container: {
		alignItems: "center",
		justifyContent: "center",
		display: "flex",
		minHeight: "50vh",
		flexDirection: "column",
	},
};
