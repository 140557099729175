import {
	Card,
	CardContent,
	CardHeader,
	IconButton,
	Stack,
} from "@mui/material";
import MKProgress from "components/MKProgress";
import MKTypography from "components/MKTypography";
import React, { useState } from "react";

const MKDataCard = (props) => {
	const [title, setTitle] = useState(props.title);
	return (
		<Card style={styles.card}>
			<CardHeader
				title={
					<MKTypography variant='body2' color='dark'>
						{props.title}
					</MKTypography>
				}
				action={
					<IconButton size='small' sx={{ width: 32, height: 32 }}>
						{props.icon}
					</IconButton>
				}
			/>
			<CardContent>
				{props.variant == "simple" && (
					<Variant1
						line1={props.line1}
						progress={props.progress}
						hint={props.hint}
						endIcon={props.endIcon}
					/>
				)}
				{props.variant == "two-col" && (
					<Variant2
						line1={props.line1}
						line2={props.line2}
						hint={props.hint}
						endIcon={props.endIcon}
						textIcon={props.textIcon}
					/>
				)}
				{props.variant == "custom" && <Variant3>{props.children}</Variant3>}
			</CardContent>
		</Card>
	);
};
export default MKDataCard;

const Variant1 = (props) => {
	const [line1, setLine1] = useState(props.line1);
	const [progress, setProgress] = useState(props.progress);
	const [hint, setHint] = useState(props.hint);
	return (
		<Stack direction={"column"} gap={1}>
			<Stack
				direction={"row"}
				sx={{
					justifyContent: "space-between",
					display: "flex",
					alignItems: "center",
				}}
			>
				<MKTypography variant='h3' color='dark'>
					{line1}
				</MKTypography>
				{props.endIcon}
			</Stack>

			<MKProgress color='success' value={progress} style={{ height: 5 }} />
			<MKTypography variant='caption' color='dark'>
				{hint}
			</MKTypography>
		</Stack>
	);
};

const Variant2 = (props) => {
	const [line1, setLine1] = useState(props.line1);
	const [line2, setLine2] = useState(props.line2);
	const [progress, setProgress] = useState(props.progress);
	const [hint, setHint] = useState(props.hint);

	return (
		<Stack direction={"column"} gap={1}>
			<Stack
				direction={"row"}
				sx={{
					justifyContent: "space-between",
					display: "flex",
					alignItems: "center",
				}}
			>
				<Stack direction={"column"} gap={1}>
					<MKTypography variant='h3' color='dark'>
						{line1}
					</MKTypography>
					<MKTypography variant='caption' color='dark'>
						{props.textIcon}&nbsp;
						{line2}
					</MKTypography>
				</Stack>

				{props.endIcon}
			</Stack>
		</Stack>
	);
};
const Variant3 = (props) => {
	return (
		<Stack direction={"column"} gap={1}>
			{props.children}
		</Stack>
	);
};

const styles = {
	root: {
		width: "100%",
		alignItems: "center",
		justifyContent: "center",
		display: "flex",
	},
	card: {
		width: "100%",
		height: 160,
	},
};
