const { default: jambuda } = require("./core");
class SettlementsService {
	base_url = "";
	shop_settlements_url = "";
	constructor() {
		this.base_url = "/api/settlements";
		this.shop_settlements_url = "/api/store-payments";
		return this;
	}
	async get_settlement_history(store_id, page, count, from_date, to_date) {
		return new Promise((resolve, reject) => {
			jambuda
				.get(
					`${this.shop_settlements_url}/history?storeId=${store_id}&page=${page}&count=${count}&fromDate=${from_date}&toDate=${to_date}`
				)
				.then((result) => {
					return resolve(result.data);
				})
				.catch((err) => {
					return reject(err);
				});
		});
	}

	async submitShopSettlements(payload) {
		return new Promise((resolve, reject) => {
			jambuda
				.post(`${this.shop_settlements_url}`, payload)
				.then((result) => {
					return resolve(result.data);
				})
				.catch((err) => {
					return reject(err);
				});
		});
	}
	async fetchAllDue() {
		return new Promise((resolve, reject) => {
			jambuda
				.get(`${this.base_url}/due`)
				.then((result) => {
					return resolve(result.data);
				})
				.catch((err) => {
					return reject(err);
				});
		});
	}
	async fetchAllDueForStore(storeId) {
		return new Promise((resolve, reject) => {
			jambuda
				.get(`${this.base_url}/due?storeId=${storeId}`)
				.then((result) => {
					return resolve(result.data);
				})
				.catch((err) => {
					return reject(err);
				});
		});
	}

	async fetchAllDueForMerchant(merchantId) {
		return new Promise((resolve, reject) => {
			jambuda
				.get(`${this.base_url}/due?merchantId=${merchantId}`)
				.then((result) => {
					return resolve(result.data);
				})
				.catch((err) => {
					return reject(err);
				});
		});
	}
}
export default SettlementsService;
