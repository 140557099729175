const { default: jambuda } = require("./core");

const productQuery = {
	addProductQuery,
	getProductQueries,
	storeQueryStats,
	searchBetweenDates,
	addQueryGroup,
};
export default productQuery;
function addProductQuery(payload) {
	return new Promise((resolve, reject) => {
		jambuda
			.post("/api/product-query-form", payload)
			.then((result) => {
				return resolve(result.data);
			})
			.catch((err) => {
				return reject(err);
			});
	});
}

function addQueryGroup(payload) {
	return new Promise((resolve, reject) => {
		jambuda
			.post("/api/query-group", payload)
			.then((result) => {
				return resolve(result.data);
			})
			.catch((err) => {
				return reject(err);
			});
	});
}
function getProductQueries(storeId, page, count, status) {
	let condition = `store=${storeId}&page=${page}&count=${count}`;
	if (status) {
		condition += `&status=${status}`;
	}
	return new Promise((resolve, reject) => {
		jambuda
			.get(`/api/product-query?${condition}`)
			.then((result) => {
				return resolve(result.data);
			})
			.catch((err) => {
				return reject(err);
			});
	});
}

function searchBetweenDates(storeId, page, count, status, startDate, endDate) {
	let condition = `storeId=${storeId}&page=${page}&count=${count}&startDate=${startDate}&endDate=${endDate}`;
	if (status) {
		condition += `&status=${status}`;
	}
	return new Promise((resolve, reject) => {
		jambuda
			.get(`/api/product-query/bydate?${condition}`)
			.then((result) => {
				return resolve(result.data);
			})
			.catch((err) => {
				return reject(err);
			});
	});
}
function storeQueryStats(storeId) {
	return new Promise((resolve, reject) => {
		jambuda
			.get(`/api/product-query/stats?storeId=${storeId}`)
			.then((result) => {
				return resolve(result.data);
			})
			.catch((err) => {
				return reject(err);
			});
	});
}
