const { default: jambuda } = require("./core");
const deliveryService = { getDeliveryCharge };

function getDeliveryCharge(zoneId, storeId, locationId, items) {
	return new Promise((resolve, reject) => {
		jambuda
			.get(
				`/api/delivery-config/slab?locationId=${locationId}&storeId=${storeId}&zoneId=${zoneId}&items=${items}`
			)
			.then((res) => {
				return resolve(res.data);
			})
			.catch((err) => {
				return reject(err);
			});
	});
}
export default deliveryService;
