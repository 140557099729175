import { createSlice } from "@reduxjs/toolkit";

export const notificationSlice = createSlice({
	name: "notifications",
	initialState: {
		count: 0,
		notifications: [],
	},
	reducers: {
		setNotifications: (state, action) => {
			state.notifications = action.payload;
			state.count = action.payload.length;
		},
		markRead: (state, action) => {
			state.notifications = state.notifications.filter((notification) => {
				return notification.id != action.payload;
			});
		},
		markAllAsRead: (state, action) => {
			state.notifications = [];
			state.count = 0;
		},
	},
});

// Action creators are generated for each case reducer function
export const { setNotifications, markRead, markAllAsRead } =
	notificationSlice.actions;
export default notificationSlice.reducer;
