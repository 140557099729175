import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import io, { Socket } from "socket.io-client";
import { FiSlack, FiTwitter } from "react-icons/fi";
import {
	AccountBox,
	AccountCircle,
	Alarm,
	CheckCircle,
	Dashboard,
	NotificationsActive,
	QueryStats,
	Settings,
	Tag,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import arthaLogo from "assets/images/jumbuda.png";
import brands from "api/brands";
import models from "api/models";
import products from "api/products";
import productVariant from "api/product-variants";
import { setBrands } from "features/brandSlice";
import { setBrandsCount } from "features/brandSlice";
import { setModels } from "features/modelSlice";
import { setModelsCount } from "features/modelSlice";
import { setProducts } from "features/productSlice";
import { setProductsCount } from "features/productSlice";
import { setVariants } from "features/productVariantSlice";
import { setVariantCount } from "features/productVariantSlice";
import initialize from "../../api/initialize";
import { setLocations } from "features/locationSlice";
import { setZones } from "features/locationSlice";
import productQuery from "../../api/product-query";
import { setStoreQueries } from "features/storeSlice";
import MKTypography from "components/MKTypography";
import { setUser } from "features/userSlice";
import { logout } from "features/userSlice";
import { setStore } from "features/storeSlice";
import { setNotifications } from "features/notificationSlice";
import { Badge, Modal, Paper, Snackbar, Stack } from "@mui/material";
import _ from "lodash";
import MKButton from "components/MKButton";
import { markAllAsRead } from "features/notificationSlice";
import { ShoppingCartSimple } from "@phosphor-icons/react";
import { setMerchant } from "features/merchantSlice";
import { clearCart } from "features/cartSlice";
import { clearMerchant } from "features/merchantSlice";
import { removeStore } from "features/storeSlice";
import { TbCreditCardPay, TbReorder } from "react-icons/tb";
import { BiSolidBug } from "react-icons/bi";
import wallet from "../../api/wallet";
import { updateWallet } from "features/walletSlice";
import { clearWallet } from "features/walletSlice";
import { CiWallet } from "react-icons/ci";
import { resetQuery } from "features/queryGroupCartSlice";
import { FaCaretDown } from "react-icons/fa6";
import orderService from "../../api/orders";

import { setTransitions } from "features/transitionsSlice";
const pages = [
	{
		id: 1,
		label: "Price Enquiry",
		icon: <Tag />,
		link: "/query",
		guarded: false,
	},
	{
		id: 2,
		label: "My Queries",
		icon: <QueryStats />,
		link: "/store-queries",
		guarded: true,
	},
	{
		id: 2,
		label: "Order History",
		icon: <QueryStats />,
		link: "/settlements/store/history",
		guarded: true,
	},
];
const globalPages = [
	{
		id: 1,
		label: "Store Settlements",
		icon: <Tag />,
		link: "/settlements/store",
		guarded: true,
	},
];

const deliveryPartnerPages = [
	{
		id: 1,
		label: "Store Settlements",
		icon: <Tag />,
		link: "/settlements/store",
		guarded: true,
	},
	{
		id: 1,
		label: "Deliveries",
		icon: <Tag />,
		link: "/store/orders-list",
		guarded: true,
	},
];

const merchantPages = [
	{
		id: 2,
		label: "Dashboard",
		icon: <Dashboard />,
		link: "/m-dashboard",
		guarded: true,
	},
	{
		id: 1,
		label: "My Queries",
		icon: <QueryStats />,
		link: "/merchant-queries",
		guarded: true,
	},
	{
		id: 3,
		label: "Orders",
		icon: <TbReorder />,
		link: "#",
		guarded: true,
		submenu: [
			{
				id: 5,
				label: "My Active Orders",
				icon: <TbReorder />,
				link: "/m-dashboard?tab=1",
				guarded: true,
			},
			{
				id: 6,
				label: "Returned Orders",
				icon: <BiSolidBug />,
				link: "/m-dashboard?tab=2",
				guarded: true,
			},
			{
				id: 4,
				label: "Settlements",
				icon: <TbCreditCardPay />,
				link: "/settlements",
				guarded: true,
			},
		],
	},
];

const merchantSettings = [
	{
		id: 2,
		name: "My Profile",
		guarded: true,
		icon: <AccountBox />,
		url: "/merchant-profile",
	},
	{
		id: 3,
		name: "Settings",
		icon: <Settings />,
		url: "/merchant-account",
		guarded: true,
	},
	{
		id: 4,
		name: "Logout",
		guarded: true,
		icon: <AccountCircle />,
		url: "/login",
	},
];
const defaults = [
	{
		id: 1,
		name: "Login",
		guarded: false,
		icon: <AccountCircle />,
		url: "/login",
	},
];
// const settings = ["Profile", "Account", "Dashboard", "Login"];
const settings = [
	{
		id: 2,
		name: "My Profile",
		guarded: true,
		icon: <AccountBox />,
		url: "/store-profile",
	},

	{
		id: 3,
		name: "Logout",
		guarded: true,
		icon: <AccountCircle />,
		url: "/logout",
	},
];
const globalSettings = [
	{
		id: 1,
		name: "Logout",
		guarded: true,
		icon: <AccountCircle />,
		url: "/logout",
	},
];
const partnerSettings = [
	{
		id: 1,
		name: "Logout",
		guarded: true,
		icon: <AccountCircle />,
		url: "/logout",
	},
];
const socket = io(
	// "http://localhost:8001"

	"https://websocket.jumbuda.com"
);
function MKShell(props) {
	const [anchorElNav, setAnchorElNav] = React.useState(null);
	const [anchorElUser, setAnchorElUser] = React.useState(null);
	const store = useSelector((state) => state.stores.store);
	const user = useSelector((state) => state.user);
	const dispatch = useDispatch();
	const merchant = useSelector((state) => state.merchants.merchant);
	const navigate = useNavigate();
	const [open, setOpen] = React.useState(false);
	const [openSnack, setOpenSnack] = React.useState(false);
	const [message, setMessage] = React.useState("");
	const [userType, setUserType] = React.useState(user?.user?.user?.userType);
	const cartProducts = useSelector((state) => state.cart.products);
	const mywallet = useSelector((state) => state.wallet.wallet);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const notifications = useSelector(
		(state) => state.notifications.notifications
	);
	const filteredNotifications = () => {
		const grouped = _.groupBy(notifications, "notificationType");
		return grouped;
	};

	const handleOpenNavMenu = (event) => {
		setAnchorElNav(event.currentTarget);
	};
	const handleOpenUserMenu = (event) => {
		setAnchorElUser(event.currentTarget);
	};

	const handleLogout = () => {
		dispatch(clearCart());
		dispatch(setUser({}));
		dispatch(clearMerchant());
		dispatch(markAllAsRead());
		dispatch(removeStore());
		dispatch(logout());
		dispatch(clearWallet());
		dispatch(resetQuery());
		navigate("/login");
	};
	const handleCloseNavMenu = () => {
		setAnchorElNav(null);
	};

	const handleCloseUserMenu = () => {
		setAnchorElUser(null);
	};

	const handleMenu = (item) => {
		if (item.name == "Logout") {
			handleLogout();
			return;
		}
		if (item.name == "My Profile") {
			if (user.user?.userType == "STORE_ADMIN") {
				navigate("/store-profile");
				return;
			} else if (user.user.user?.userType == "MERCHANT_ADMIN") {
				navigate("/merchant-profile");
				return;
			}
		}
		if (item.name == "Settings") {
			if (user.user?.user?.userType == "STORE_ADMIN") {
				navigate("/store-profile");
				return;
			} else if (user.user?.user?.userType == "MERCHANT_ADMIN") {
				navigate("/merchant-account");
				return;
			}
		}
		navigate(item.url);
	};

	React.useEffect(() => {
		brands.getAllBrands().then((result) => {
			const { brands, count } = result;
			dispatch(setBrands(brands));
			dispatch(setBrandsCount(count));
		});
		models.getAllModels().then((result) => {
			const { models, count } = result;
			dispatch(setModels(models));
			dispatch(setModelsCount(count));
		});
		products.getAllProducts().then((result) => {
			const { products, count } = result;
			dispatch(setProducts(products));
			dispatch(setProductsCount(count));
		});
		productVariant.getProductVariants().then((result) => {
			const { variants, count } = result;
			dispatch(setVariants(variants));
			dispatch(setVariantCount(count));
		});
		initialize.getAllLocations().then((result) => {
			dispatch(setLocations(result));
		});
		initialize.getAllZones().then((result) => {
			dispatch(setZones(result));
		});
		orderService
			.get_order_transitions()
			.then((result) => {
				dispatch(setTransitions(result));
			})
			.catch((err) => {
				console.log("ERROR CALLING", err);
			});
		if (user.user?.user?.loggedIn) {
			console.log("LOgged in User");
			if (user.user?.user?.userType == "STORE_ADMIN") {
				wallet
					.fetchWalletForStore(store.id)
					.then((result) => {
						dispatch(updateWallet(result));
					})
					.catch((err) => console.log(err));
			} else if (user.user?.user?.userType == "MERCHANT_ADMIN") {
				wallet.fetchWalletForMerchant(merchant.id).then((result) => {
					dispatch(updateWallet(result));
				});
			}
		} else {
			navigate("/login");
		}
	}, []);

	const handleReadAll = () => {
		setOpen(false);
		dispatch(markAllAsRead());
	};
	const handleWalletClick = () => {
		if (user.user.user.userType == "DELIVERY_PARTNER") {
			navigate("/wallets/partner");
		}
	};

	const handleClick = (event, page) => {
		console.log("PAGE, event", page, event);
		if (page.submenu) {
			setAnchorEl(event.currentTarget);
		} else {
			navigate(page.link);
		}
	};
	const handleClose = () => {
		setAnchorEl(undefined);
	};

	return (
		<>
			{user.loggedIn && (
				<AppBar
					position='sticky'
					sx={{
						backgroundColor: "#fafafa",
						scrollBehavior: "smooth",
						scrollbarWidth: 0,
					}}
				>
					<Container maxWidth='xl'>
						<Toolbar disableGutters>
							<Box
								sx={{
									width: 52,
									height: 52,
									objectFit: "cover",
									display: { xs: "none", sm: "flex" },
								}}
							>
								<img src={arthaLogo} width='100%' height='100%' />
							</Box>
							<Snackbar
								open={openSnack}
								autoHideDuration={4000}
								onClose={() => setOpenSnack(false)}
								message={
									<MKTypography variant='caption' color='white'>
										{message}
									</MKTypography>
								}
								action={
									<MKButton
										iconOnly
										color='white'
										circular
										size='small'
										onClick={() => {
											setOpen(true);
											setOpenSnack(false);
										}}
									>
										<CheckCircle />
									</MKButton>
								}
							/>
							<Typography
								variant='h6'
								noWrap
								component='a'
								href='/'
								sx={{
									mr: 2,
									display: { xs: "none", md: "flex" },
									fontFamily: "monospace",
									fontWeight: 700,
									letterSpacing: ".3rem",
									color: "#f2727e",
									textDecoration: "none",
								}}
							>
								JUMBUDA
							</Typography>

							<Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
								<IconButton
									size='large'
									aria-label='account of current user'
									aria-controls='menu-appbar'
									aria-haspopup='true'
									onClick={handleOpenNavMenu}
									color='inherit'
								>
									<MenuIcon />
								</IconButton>
								<Menu
									id='menu-appbar'
									anchorEl={anchorElNav}
									anchorOrigin={{
										vertical: "bottom",
										horizontal: "left",
									}}
									keepMounted
									transformOrigin={{
										vertical: "top",
										horizontal: "left",
									}}
									open={Boolean(anchorElNav)}
									onClose={handleCloseNavMenu}
									sx={{
										display: { xs: "block", md: "none" },
									}}
								>
									{user.user?.user?.userType == "STORE_ADMIN" ? (
										pages.map(function (page) {
											<MenuItem key={page} onClick={handleCloseNavMenu}>
												<Typography textAlign='center'>{page}</Typography>
											</MenuItem>;
										})
									) : user.user?.user?.userType == "MERCHANT_ADMIN" ? (
										merchantPages.map(function (page) {
											<MenuItem key={page} onClick={handleCloseNavMenu}>
												<Typography textAlign='center'>{page}</Typography>
											</MenuItem>;
										})
									) : (
										<></>
									)}
								</Menu>
							</Box>
							{/* <AdbIcon sx={{ display: { xs: "flex", md: "none" }, mr: 1 }} /> */}
							<Box
								sx={{
									width: 52,
									height: 52,
									objectFit: "cover",
									display: { xs: "none", sm: "none" },
								}}
							>
								<img src={arthaLogo} width='100%' height='100%' />
							</Box>
							<MKTypography
								variant='body2'
								noWrap
								component='a'
								href='/'
								sx={{
									mr: 2,
									display: { xs: "flex", md: "none" },
									flexGrow: 1,
									fontFamily: "monospace",
									fontWeight: 700,
									letterSpacing: ".1rem",
									color: "#f2727e",
									textDecoration: "none",
								}}
							>
								JUMBUDA
							</MKTypography>
							<Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
								{user.user.user?.userType == "GLOBAL" &&
									globalPages.map((page) => (
										<>
											<Button
												aria-controls={open ? "basic-menu" : undefined}
												aria-haspopup='true'
												aria-expanded={open ? "true" : undefined}
												key={page.id}
												onClick={(event) => handleClick(event, page)}
												sx={{ my: 2, color: "white", display: "block" }}
											>
												<MKTypography
													variant='body2'
													color='dark'
													fontWeight='bold'
												>
													{page.icon}&nbsp;{page.label}
												</MKTypography>
											</Button>
											{page.submenu && (
												<Menu
													id='basic-menu'
													anchorEl={anchorEl}
													open={Boolean(anchorEl)}
													onClose={handleClose}
													MenuListProps={{
														"aria-labelledby": "basic-button",
													}}
												>
													{_.map(page.submenu, (sPage) => {
														return (
															<MenuItem>
																{sPage.icon}&nbsp;{sPage.label}
															</MenuItem>
														);
													})}
												</Menu>
											)}
										</>
									))}
								{user.user.user?.userType == "DELIVERY_PARTNER" &&
									deliveryPartnerPages.map((page) => (
										<>
											<Button
												aria-controls={open ? "basic-menu" : undefined}
												aria-haspopup='true'
												aria-expanded={open ? "true" : undefined}
												key={page.id}
												onClick={(event) => handleClick(event, page)}
												sx={{ my: 2, color: "white", display: "block" }}
											>
												<MKTypography
													variant='body2'
													color='dark'
													fontWeight='bold'
												>
													{page.icon}&nbsp;{page.label}
												</MKTypography>
											</Button>
											{page.submenu && (
												<Menu
													id='basic-menu'
													anchorEl={anchorEl}
													open={Boolean(anchorEl)}
													onClose={handleClose}
													MenuListProps={{
														"aria-labelledby": "basic-button",
													}}
												>
													{_.map(page.submenu, (sPage) => {
														return (
															<MenuItem>
																{sPage.icon}&nbsp;{sPage.label}
															</MenuItem>
														);
													})}
												</Menu>
											)}
										</>
									))}
								{user.user.user?.userType == "STORE_ADMIN" &&
									pages.map((page) => (
										<>
											<Button
												aria-controls={open ? "basic-menu" : undefined}
												aria-haspopup='true'
												aria-expanded={open ? "true" : undefined}
												key={page.id}
												onClick={(event) => handleClick(event, page)}
												sx={{ my: 2, color: "white", display: "block" }}
											>
												<MKTypography
													variant='body2'
													color='dark'
													fontWeight='bold'
												>
													{page.icon}&nbsp;{page.label}
												</MKTypography>
											</Button>
											{page.submenu && (
												<Menu
													id='basic-menu'
													anchorEl={anchorEl}
													open={Boolean(anchorEl)}
													onClose={handleClose}
													MenuListProps={{
														"aria-labelledby": "basic-button",
													}}
												>
													{_.map(page.submenu, (sPage) => {
														return (
															<MenuItem>
																{sPage.icon}&nbsp;{sPage.label}
															</MenuItem>
														);
													})}
												</Menu>
											)}
										</>
									))}
								{user.user.user?.userType == "MERCHANT_ADMIN" &&
									merchantPages.map((page) => (
										<>
											<Button
												aria-controls={open ? "basic-menu" : undefined}
												aria-haspopup='true'
												aria-expanded={open ? "true" : undefined}
												key={page.id}
												onClick={(e) => handleClick(e, page)}
												sx={{ my: 2, color: "white", display: "block" }}
											>
												<MKTypography
													variant='body2'
													color='dark'
													fontWeight='bold'
												>
													{page.icon}&nbsp;{page.label} &nbsp;
													{page.submenu && <FaCaretDown />}
												</MKTypography>
											</Button>
											{page.submenu && (
												<Menu
													id='basic-menu'
													anchorEl={anchorEl}
													open={Boolean(anchorEl)}
													onClose={handleClose}
													MenuListProps={{
														"aria-labelledby": "basic-button",
													}}
												>
													{_.map(page.submenu, (sPage) => {
														return (
															<MenuItem
																onClick={() => {
																	setAnchorEl(undefined);
																	navigate(sPage.link);
																}}
															>
																{sPage.icon}&nbsp;{sPage.label}
															</MenuItem>
														);
													})}
												</Menu>
											)}
										</>
									))}
							</Box>

							<Box sx={{ flexGrow: 0, width: 100 }}>
								{user.user?.user?.loggedIn &&
								user.user?.user?.userType == "STORE_ADMIN" ? (
									<Badge
										badgeContent={
											notifications.filter((note) => {
												return note.notificationType == "store";
											}).length
										}
										color='#1E156A'
									>
										<IconButton onClick={() => setOpen(!open)}>
											<NotificationsActive
												color='#7740E5'
												style={{ color: "#1E156A" }}
											/>
										</IconButton>
									</Badge>
								) : user.user?.user?.loggedIn &&
								  user.user?.user?.userType == "MERCHANT_ADMIN" ? (
									<Badge
										badgeContent={
											notifications.filter((note) => {
												return note.notificationType == "merchant";
											}).length
										}
										color='#1E156A'
									>
										<IconButton onClick={() => setOpen(!open)}>
											<NotificationsActive
												color='#7740E5'
												style={{ color: "#1E156A" }}
											/>
										</IconButton>
									</Badge>
								) : (
									<></>
								)}

								<Modal
									keepMounted
									open={open}
									onClose={() => setOpen(false)}
									aria-labelledby='keep-mounted-modal-title'
									aria-describedby='keep-mounted-modal-description'
								>
									<Box
										sx={{
											position: "absolute",
											top: "15%",
											left: "80%",
											transform: "translate(-50%, -50%)",
											width: 400,
											bgcolor: "#ffffff",
											border: "1px dashed #444444",
											boxShadow: 24,
											p: 1,
										}}
									>
										<div
											style={{
												maxHeight: 400,
												overflow: "scroll",
												alignItems: "center",
												display: "flex",
												flexDirection: "column",
											}}
										>
											{user.user?.user?.userType == "STORE_ADMIN"
												? _.map(
														notifications.filter((note) => {
															return note.notificationType == "store";
														}),
														(notification) => {
															return (
																<div
																	style={{ cursor: "pointer", width: "100%" }}
																	onClick={() =>
																		navigate(notification.redirectUrl)
																	}
																>
																	<Paper
																		elevation={0}
																		sx={{
																			p: 1,
																			backgroundColor: "#fffffd",
																			height: 80,
																			width: "100%",
																			mb: 2,
																		}}
																	>
																		<Stack
																			width={"100%"}
																			gap={1}
																			sx={{
																				alignItems: "flex-start",
																				justifyContent: "center",
																				display: "flex",
																				backgroundColor: "#FCFCFA",
																				border: "1px dashed #444444",
																				p: 1,
																			}}
																		>
																			<MKTypography
																				variant='caption'
																				color='dark'
																			>
																				Date:&nbsp;{notification.date}
																			</MKTypography>
																			<MKTypography
																				variant='caption'
																				color='dark'
																			>
																				<FiTwitter />
																				&nbsp;
																				{notification.description}
																			</MKTypography>
																			{/* {notification.brand && notification.model && (
															<MKTypography variant='caption' color='dark'>
																<FiSlack />
																&nbsp;
																{notification.brand}&nbsp;{notification.model}
																&nbsp;
																{notification.product}
															</MKTypography>
														)} */}
																		</Stack>
																	</Paper>
																</div>
															);
														}
												  )
												: _.map(
														notifications.filter((note) => {
															return note.notificationType == "merchant";
														}),
														(notification) => {
															return (
																<div
																	id={notification.id}
																	style={{ cursor: "pointer", width: "100%" }}
																	onClick={() => {
																		console.log(
																			"Will navigate to order",
																			notification
																		);
																		if (notification.type == "order") {
																			const grouped = _.groupBy(
																				notification.orders,
																				(o) => {
																					return o.ProductQuery.queryGroupId;
																				}
																			);
																			navigate(notification.redirectUrl, {
																				state: { order: grouped },
																			});
																		} else {
																			navigate(notification.redirectUrl);
																		}
																	}}
																>
																	<Paper
																		elevation={0}
																		sx={{
																			p: 1,
																			backgroundColor: "#fffffd",
																			height: 80,
																			width: "100%",
																			mb: 2,
																		}}
																	>
																		<Stack
																			width={"100%"}
																			gap={1}
																			sx={{
																				alignItems: "flex-start",
																				justifyContent: "center",
																				display: "flex",
																				backgroundColor: "#FCFCFA",
																				border: "1px dashed #444444",
																				p: 1,
																			}}
																		>
																			<MKTypography
																				variant='caption'
																				color='dark'
																			>
																				Date:&nbsp;{notification.date}
																			</MKTypography>
																			<MKTypography
																				variant='caption'
																				color='dark'
																			>
																				<FiTwitter />
																				&nbsp;
																				{notification.description}
																			</MKTypography>
																			{/* {notification.brand && notification.model && (
															<MKTypography variant='caption' color='dark'>
																<FiSlack />
																&nbsp;
																{notification.brand}&nbsp;{notification.model}
																&nbsp;
																{notification.product}
															</MKTypography>
														)} */}
																		</Stack>
																	</Paper>
																</div>
															);
														}
												  )}
										</div>

										<div
											onClick={handleReadAll}
											style={{
												cursor: "pointer",
												alignItems: "center",
												justifyContent: "center",
												display: "flex",
											}}
										>
											{notifications.length ? (
												<MKTypography variant='caption' color='error'>
													Mark All As Read
												</MKTypography>
											) : (
												<MKTypography variant='caption' color='error'>
													No New Notifications
												</MKTypography>
											)}
										</div>
									</Box>
								</Modal>
							</Box>
							<Box sx={{ flexGrow: 0, width: 100 }}>
								{user.user?.user?.loggedIn &&
									user.user?.user?.userType == "STORE_ADMIN" && (
										<Badge badgeContent={cartProducts.length} color='#1E156A'>
											<IconButton onClick={() => navigate("/store-cart")}>
												<ShoppingCartSimple style={{ color: "#1E156A" }} />
											</IconButton>
										</Badge>
									)}
							</Box>

							{mywallet && user.user?.user?.loggedIn && (
								<Box
									sx={{
										padding: 1,
										backgroundColor: "#F0FEF1",
										marginRight: 2,
									}}
								>
									<div
										onClick={handleWalletClick}
										style={{ cursor: "pointer" }}
									>
										<Stack
											direction='row'
											sx={{
												alignItems: "center",
												justifyContent: "center",
												display: "flex",
												minWidth: 120,
											}}
										>
											<CiWallet />
											<Box display={{ xs: "none", sm: "flex" }}>
												<MKTypography
													variant='body2'
													color='black'
													fontWeight='bold'
												>
													&nbsp; {mywallet?.currency} &nbsp;
													{Number(mywallet?.balance).toFixed(3)}
												</MKTypography>
											</Box>
											<Box display={{ xs: "flex", sm: "none" }}>
												<MKTypography
													variant='caption'
													color='black'
													fontWeight='bold'
												>
													&nbsp; {mywallet?.currency} &nbsp;
													{Number(mywallet?.balance).toFixed(3)}
												</MKTypography>
											</Box>
										</Stack>
									</div>
								</Box>
							)}

							<Box sx={{ flexGrow: 0 }}>
								<Tooltip title='Open settings'>
									<IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
										<Avatar alt='User Profile' src={arthaLogo} />
									</IconButton>
								</Tooltip>
								<Menu
									sx={{ mt: "45px" }}
									id='menu-appbar'
									anchorEl={anchorElUser}
									anchorOrigin={{
										vertical: "top",
										horizontal: "right",
									}}
									keepMounted
									transformOrigin={{
										vertical: "top",
										horizontal: "right",
									}}
									open={Boolean(anchorElUser)}
									onClose={handleCloseUserMenu}
								>
									{user.user?.user?.userType == "MERCHANT_ADMIN" &&
										merchantSettings.map(function (setting) {
											if (setting.guarded) {
												if (user.loggedIn) {
													return (
														<MenuItem
															key={setting.id}
															onClick={() => handleMenu(setting)}
														>
															<MKTypography
																textAlign='center'
																variant='body2'
																color='dark'
															>
																{setting.icon}&nbsp; {setting.name}
															</MKTypography>
														</MenuItem>
													);
												}
											} else {
												if (!user.loggedIn) {
													return (
														<MenuItem
															key={setting.id}
															onClick={() => handleMenu(setting)}
														>
															<MKTypography
																textAlign='center'
																variant='body2'
																color='dark'
															>
																{setting.icon}&nbsp; {setting.name}
															</MKTypography>
														</MenuItem>
													);
												}
											}
										})}
									{user.user?.user?.userType == "GLOBAL" &&
										globalSettings.map(function (setting) {
											if (setting.guarded) {
												if (user.loggedIn) {
													return (
														<MenuItem
															key={setting.id}
															onClick={() => handleMenu(setting)}
														>
															<MKTypography
																textAlign='center'
																variant='body2'
																color='dark'
															>
																{setting.icon}&nbsp; {setting.name}
															</MKTypography>
														</MenuItem>
													);
												}
											} else {
												if (!user.loggedIn) {
													return (
														<MenuItem
															key={setting.id}
															onClick={() => handleMenu(setting)}
														>
															<MKTypography
																textAlign='center'
																variant='body2'
																color='dark'
															>
																{setting.icon}&nbsp; {setting.name}
															</MKTypography>
														</MenuItem>
													);
												}
											}
										})}
									{user.user?.user?.userType == "DELIVERY_PARTNER" &&
										partnerSettings.map(function (setting) {
											if (setting.guarded) {
												if (user.loggedIn) {
													return (
														<MenuItem
															key={setting.id}
															onClick={() => handleMenu(setting)}
														>
															<MKTypography
																textAlign='center'
																variant='body2'
																color='dark'
															>
																{setting.icon}&nbsp; {setting.name}
															</MKTypography>
														</MenuItem>
													);
												}
											} else {
												if (!user.loggedIn) {
													return (
														<MenuItem
															key={setting.id}
															onClick={() => handleMenu(setting)}
														>
															<MKTypography
																textAlign='center'
																variant='body2'
																color='dark'
															>
																{setting.icon}&nbsp; {setting.name}
															</MKTypography>
														</MenuItem>
													);
												}
											}
										})}
									{!user.loggedIn &&
										defaults.map(function (setting) {
											return (
												<MenuItem
													key={setting.id}
													onClick={() => handleMenu(setting)}
												>
													<MKTypography
														textAlign='center'
														variant='body2'
														color='dark'
													>
														{setting.icon}&nbsp; {setting.name}
													</MKTypography>
												</MenuItem>
											);
										})}

									{user.user?.user?.userType == "STORE_ADMIN" &&
										settings.map(function (setting) {
											if (setting.guarded) {
												if (user.loggedIn) {
													return (
														<MenuItem
															key={setting.id}
															onClick={() => handleMenu(setting)}
														>
															<MKTypography
																textAlign='center'
																variant='body2'
																color='dark'
															>
																{setting.icon}&nbsp; {setting.name}
															</MKTypography>
														</MenuItem>
													);
												}
											} else {
												if (!user.loggedIn) {
													return (
														<MenuItem
															key={setting.id}
															onClick={() => handleMenu(setting)}
														>
															<MKTypography
																textAlign='center'
																variant='body2'
																color='dark'
															>
																{setting.icon}&nbsp; {setting.name}
															</MKTypography>
														</MenuItem>
													);
												}
											}
										})}
								</Menu>
							</Box>
						</Toolbar>
					</Container>
				</AppBar>
			)}
		</>
	);
}
export default MKShell;
