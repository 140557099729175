import { createSlice } from "@reduxjs/toolkit";

export const brandSlice = createSlice({
	name: "brands",
	initialState: {
		count: 0,
		brands: [],
	},
	reducers: {
		setBrands: (state, action) => {
			state.brands = action.payload;
		},
		setBrandsCount: (state, action) => {
			state.count = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const { setBrands, setBrandsCount } = brandSlice.actions;

export default brandSlice.reducer;
