import React from "react";
import { Box, Typography, Button, Container } from "@mui/material";
import { styled } from "@mui/system";
import { useSpring, animated } from "react-spring";
import googleplay from "assets/images/google-play-badge.png";
import apple from "assets/images/apple-app-store-badge.webp";
import hero from "../../../assets/images/hero.jpg";
const HeroContainer = styled(Box)`
	height: 100vh;
	display: flex;
	align-items: center;
	background-image: url(${hero});
	background-size: cover;
	background-position: center;
	position: relative;
`;

const ContentWrapper = styled(Container)`
	position: relative;
	z-index: 1;
`;

const Overlay = styled(Box)`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.5);
`;

const AnimatedTypography = animated(Typography);

const HeroSection = () => {
	const fadeIn = useSpring({
		opacity: 1,
		from: { opacity: 0 },
		delay: 500,
	});

	return (
		<HeroContainer>
			<Overlay />
			<ContentWrapper maxWidth='md'>
				<AnimatedTypography
					variant='h2'
					component='h1'
					color='white'
					gutterBottom
					style={fadeIn}
				>
					Streamline Your Mobile Repair Business
				</AnimatedTypography>
				<AnimatedTypography variant='h5' color='white' paragraph style={fadeIn}>
					Connect with suppliers, get instant quotes, and find the best spare
					parts for your service center.
				</AnimatedTypography>
				<Box mt={4}>
					<Button
						variant='contained'
						color='primary'
						size='large'
						sx={{ mr: 2 }}
					>
						Get a Quote
					</Button>
					<Button variant='outlined' color='secondary' size='large'>
						Join Now
					</Button>
				</Box>
				<Box mt={4} display='flex' justifyContent='center'>
					<img
						src={googleplay}
						alt='Get it on Google Play'
						width={160}
						height={60}
						style={{ marginRight: "16px" }}
					/>
					<img
						src={apple}
						alt='Download on the App Store'
						width={160}
						height={60}
					/>
				</Box>
			</ContentWrapper>
		</HeroContainer>
	);
};

export default HeroSection;
