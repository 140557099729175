const { default: jambuda } = require("./core");

const products = { getAllProducts };

function getAllProducts() {
	return new Promise((resolve, reject) => {
		jambuda.get(`/api/product`).then((result) => {
			const models = result.data;
			const count = result.data.length;
			return resolve({ products: models, count: count });
		});
	});
}
export default products;
