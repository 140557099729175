import { styled } from "@mui/material/styles";
import {
	CloudUploadOutlined,
	CloudUploadRounded,
	DeleteForever,
	DoneAllRounded,
} from "@mui/icons-material";
import {
	Button,
	Container,
	Divider,
	IconButton,
	Paper,
	Stack,
} from "@mui/material";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import _ from "lodash";
import React, { useState } from "react";
import { getBase64 } from "api/helper/util";
import { toBase64 } from "api/helper/util";
import media from "../../api/media";
import loader from "assets/images/spinner.gif";
const VisuallyHiddenInput = styled("input")({
	clip: "rect(0 0 0 0)",
	clipPath: "inset(50%)",
	height: 1,
	overflow: "hidden",
	position: "absolute",
	bottom: 0,
	left: 0,
	whiteSpace: "nowrap",
	width: 1,
});

const MKFileupload = (props) => {
	const [files, setFiles] = useState([]);
	const [uploadedFiles, setUploadedFiles] = useState([]);

	const [uploadingFiles, setUploadingFiles] = useState([]);

	function handleMultipleChange(event) {
		setFiles([...event.target.files]);
	}

	async function handleMultipleSubmit(event) {
		event.preventDefault();
		let uploaded = [];
		_.map(files, async (file) => {
			try {
				const base64File = await toBase64(file);
				if (base64File) {
					const payload = {
						fileName: file.name, // Use file.name instead of files.name
						file: base64File,
						tags: ["tag1", "tag2"],
						path: props.path,
					};
					setUploadingFiles((prevUploadingFiles) => [
						...prevUploadingFiles,
						file.name,
					]);
					media
						.upload(base64File, file.name, props.path)
						.then((result) => {
							uploaded.push(file.name);
							setUploadedFiles((prevUploadedFiles) => [
								...prevUploadedFiles,
								file.name,
							]);
							setUploadingFiles((prevUploadingFiles) =>
								prevUploadingFiles.filter((fileName) => fileName !== file.name)
							);
							if (props.onMediaUpload) {
								props.onMediaUpload(result.url, result);
							}
						})
						.catch((err) => {
							setUploadingFiles((prevUploadingFiles) =>
								prevUploadingFiles.filter((fileName) => fileName !== file.name)
							);
							console.error("Error uploading file", err);
						});
				}
			} catch (error) {
				console.error("Error converting file to Base64:", error);
			}
		});
	}
	const removeFileFromList = (f) => {
		const remaining = _.filter(files, function (file) {
			return file.name != f.name;
		});

		setFiles([...remaining]);
	};
	return (
		<Paper
			sx={{
				width: "100%",
				borderRadius: 2,
				padding: 1,
			}}
		>
			<Stack style={styles.uploadArea} gap={1}>
				<Button
					size='small'
					component='label'
					role={undefined}
					variant='contained'
					tabIndex={-1}
					startIcon={<CloudUploadOutlined />}
					sx={{ backgroundColor: "#E12F6C", color: "#ffffff" }}
				>
					Broswe Files...
					<VisuallyHiddenInput
						type='file'
						onChange={handleMultipleChange}
						multiple
					/>
				</Button>
				<MKTypography variant='caption' color='primary'>
					Upload Relavent Documents
				</MKTypography>
				<MKTypography variant='caption' color='primary'>
					Max File Size: <strong>20MB</strong>. Formats :{" "}
					<strong>pdf & images</strong>
				</MKTypography>
			</Stack>

			<Container
				sx={{
					alignItems: "center",
					justifyContent: "center",
					display: "flex",
					flexDirection: "column",
					mt: 3,
				}}
			>
				<MKTypography variant='caption' color='primary'>
					Add image of the product or damaged item. (Optional)
				</MKTypography>
			</Container>
			<Divider style={{ background: "1px dashed black" }} />
			<Stack gap={1}>
				{_.map(files, function (file, index) {
					console.log(file.name, uploadedFiles, uploadingFiles);
					return (
						<Paper style={styles.fileList} key={index} elevation={0}>
							{uploadingFiles.includes(file.name) && (
								<div style={{ width: 24, height: 24, objectFit: "cover" }}>
									<img src={loader} width={"100%"} height={"100%"} />
								</div>
							)}
							{uploadedFiles.includes(file.name) && (
								<DoneAllRounded
									style={{ fontSize: 24, color: "green" }}
									fontSize='24px'
								/>
							)}
							<MKTypography
								variant='caption'
								color='primary'
								style={{ flexGrow: 1, marginLeft: 2 }}
							>
								{file.name.slice(0, 25)}
							</MKTypography>
							<IconButton onClick={() => removeFileFromList(file)}>
								<DeleteForever
									style={{ flexGrow: 0, color: "#E12F6C", fontSize: "18px" }}
									fontSize='14px'
								/>
							</IconButton>
						</Paper>
					);
				})}
				{files.length ? (
					<MKButton
						color='primary'
						variant='contained'
						onClick={handleMultipleSubmit}
						size='small'
					>
						Upload Selected Files
					</MKButton>
				) : (
					<></>
				)}
			</Stack>
		</Paper>
	);
};
export default MKFileupload;
const styles = {
	uploadArea: {
		borderRadius: 2,
		border: "1px dashed #444444",
		backgroundColor: "#F4F8FB",
		padding: 5,
		paddingBottom: 1,
		height: 200,
		width: "100%",
		alignItems: "center",
		justifyContent: "center",
		display: "flex",
		flexDirection: "column",
	},
	fileList: {
		borderRadius: 2,
		border: "1px dashed #444444",
		backgroundColor: "#ffffff",
		padding: 2,

		width: "100%",
		alignItems: "center",
		justifyContent: "space-between",
		display: "flex",
		flexDirection: "row",
	},
};
