import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
	name: "user",
	initialState: {
		loggedIn: false,
		user: {},
	},
	reducers: {
		login: (state, action) => {
			state.loggedIn = true;
		},
		setUser: (state, action) => {
			state.user = action.payload;
		},
		logout: (state, action) => {
			state.user = {};
			state.loggedIn = false;
		},
	},
});

// Action creators are generated for each case reducer function
export const { login, logout, setUser } = userSlice.actions;

export default userSlice.reducer;
