import { Box, IconButton } from "@mui/material";
import MKInput from "components/MKInput";
import React, { useEffect, useState } from "react";
import { CiCirclePlus } from "react-icons/ci";
import { CiCircleMinus } from "react-icons/ci";
const MKQuantity = (props) => {
	const [min, setMin] = useState(props.min ? props.min : 1);
	const [quantity, setQuantity] = useState(props.quantity);
	const [step, setStep] = useState(props.step);
	const handleChange = (e) => {
		if (e.target.value >= min) setQuantity(e.target.value);
	};
	const handleAdd = () => {
		setQuantity(quantity + step);
	};
	const handleMinus = () => {
		let qty = quantity - step;
		if (qty >= min) setQuantity(qty);
	};
	useEffect(() => {
		if (props.onChange) {
			props.onChange(quantity);
		}
	}, [quantity]);
	return (
		<Box style={styles.container}>
			<IconButton onClick={handleMinus}>
				<CiCircleMinus />
			</IconButton>
			<MKInput
				value={quantity}
				onChange={handleChange}
				inputProps={{ min: 0, style: { textAlign: "center" } }}
				style={{ width: 50 }}
			/>
			<IconButton onClick={handleAdd}>
				<CiCirclePlus />
			</IconButton>
		</Box>
	);
};
export default MKQuantity;
export const styles = {
	container: {
		width: 180,
		padding: 10,
		alignItems: "center",
		justifyContent: "space-between",
		display: "flex",
		flexDirection: "row",
	},
};
