import { Autocomplete, Container, Grid, Stack } from "@mui/material";
import orderService from "../../api/orders";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import MKButton from "components/MKButton";
import "./qr.css";
import QrReader from "./Scanner";
import MKTypography from "components/MKTypography";
import MKDate from "components/MKDate";
import _ from "lodash";
import { OrderHeader } from "screens/Global/sections/OrderHeader";
import { OrderRow } from "screens/Global/sections/OrderRow";
const StoreOrdersList = (props) => {
	const [selectedStoreId, setSelectedStoreId] = useState(1);

	const [page, setPage] = useState(1);
	const [count, setCount] = useState(10);
	const [totalCount, setTotalCount] = useState(0);
	const [loading, setLoading] = useState(false);
	const [orders, setOrders] = useState([]);
	const [selectedStoreInput, setSelectedInput] = useState(null);
	const [showScanner, setShowScanner] = useState(false);
	const [scannedData, setScannedData] = useState(null);
	const [lineItems, setLineItems] = useState([]);
	const [refreshKey, setRefreshKey] = useState(1);
	const handleScanSuccess = (data) => {
		setShowScanner(false);

		const parsed = JSON.parse(data);
		if (parsed?.storeId) {
			console.log(parsed);
			setScannedData(parsed);
			setSelectedStoreId(parsed?.storeId);
			orderService
				.fetchPendingDeliveryOrdersForStore(parsed?.storeId, page, count, [
					"ready_for_pickup",
					"picked_up_from_supplier",
				])
				.then((response) => {
					setTotalCount(response.totalCount);
					setOrders(response.orders);
					const lines = _.map(response.orders, (order) => {
						return order.orderLineItems;
					});
					console.log("Lines", lines);
					setLineItems(_.flatten(lines));
				})
				.catch((err) => {
					console.log("error", err.response?.data?.message);
				});
		}
	};

	useEffect(() => {
		if (selectedStoreId && refreshKey) {
			orderService
				.fetchPendingDeliveryOrdersForStore(selectedStoreId, page, count, [
					"ready_for_pickup",
					"picked_up_from_supplier",
				])
				.then((response) => {
					setTotalCount(response.totalCount);
					setOrders(response.orders);
					const lines = _.map(response.orders, (order) => {
						return order.orderLineItems;
					});
					console.log(lines);
					setLineItems(_.flatten(lines));
				})
				.catch((err) => {
					console.log("error", err.response?.data?.message);
				});
		}
	}, [refreshKey]);

	const handleStateChange = (
		orderProductId,
		lineItemStatus,
		transitionStatus
	) => {
		orderService
			.transition_order_status(orderProductId, {
				from: lineItemStatus,
				to: transitionStatus,
			})
			.then((result) => {
				setRefreshKey((prev) => prev + 1);
			})
			.catch((err) => {
				console.log(err);
			});
	};
	return (
		<Container style={styles.container}>
			<Stack gap={2}>
				<MKButton
					variant='contained'
					color='dark'
					onClick={() => setShowScanner(!showScanner)}
				>
					Scan Store QR Code
				</MKButton>
				<MKButton
					variant='contained'
					color='dark'
					onClick={() => setRefreshKey((prev) => prev + 1)}
				>
					Refresh
				</MKButton>
				<div>
					{showScanner && <QrReader onScanSuccess={handleScanSuccess} />}
				</div>
				{_.map(lineItems, (item) => {
					return (
						<OrderRow
							key={refreshKey}
							orderProductId={item.id}
							lineItemStatus={item.lineItemStatus}
							product={`${item.ProductQuery.Brand.name} ${
								item.ProductQuery.Models.name
							} ${item.Product.name} ${
								item.ProductQuery.qualityVariant == "ORIGINAL"
									? "Original"
									: item.ProductQuery?.Variant?.name
							}`}
							quantity={item.ProductQuery.quantity}
							color={item.ProductQuery.color}
							totalPayable={
								item.Settlements.length
									? Number(item.Settlements[0].totalStoreReceivable).toFixed(2)
									: "Unknown"
							}
							totalPaid={
								item.Settlements.length
									? _.sumBy(item.Settlements[0].StorePayments, (settlement) => {
											return Number(settlement.amount);
									  })
									: 0
							}
							deliveredDate={item.deliveredDate}
							onStateChange={handleStateChange}
						/>
					);
				})}
			</Stack>
		</Container>
	);
};
export default StoreOrdersList;
const styles = {
	container: {
		alignItems: "center",
		justifyContent: "center",
		display: "flex",
		minHeight: "30vh",
	},
};
