import { createSlice } from "@reduxjs/toolkit";

export const transitionSlice = createSlice({
	name: "transitions",
	initialState: {
		transitions: {},
	},
	reducers: {
		setTransitions: (state, action) => {
			state.transitions = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const { setTransitions } = transitionSlice.actions;
export default transitionSlice.reducer;
