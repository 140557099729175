const { default: jambuda } = require("./core");
const orderService = {
	createOrder,
	getOrders,
	fetchAnOrderDetails,
	fetchStoreOrders,
	fetchPendingDeliveryOrdersForStore,
	updateOrderStatus,
	createReturn,
	updateOrderProductStatus,
	orderReturnFlow,
	fetchOrdersBetweenDates,
	get_order_history,
	get_order_transitions,
	transition_order_status,
};
export default orderService;

function get_order_transitions() {
	return new Promise((resolve, reject) => {
		jambuda
			.get(`/api/order-transitions`)
			.then((result) => {
				return resolve(result.data);
			})
			.catch((err) => {
				return reject(err);
			});
	});
}
function transition_order_status(id, payload) {
	return new Promise((resolve, reject) => {
		jambuda
			.patch(`/api/order-transitions/${id}`, payload)
			.then((result) => {
				return resolve(result.data);
			})
			.catch((err) => {
				return reject(err);
			});
	});
}
function get_order_history(store_id, page, count, from_date, to_date, status) {
	return new Promise((resolve, reject) => {
		jambuda
			.get(
				`api/order/history/store?storeId=${store_id}&page=${page}&count=${count}&fromDate=${from_date}&toDate=${to_date}&status=${status}`
			)
			.then((result) => {
				return resolve(result.data);
			})
			.catch((err) => {
				return reject(err);
			});
	});
}

function createOrder(payload) {
	return new Promise((resolve, reject) => {
		jambuda
			.post("/api/order", payload)
			.then((response) => {
				return resolve(response.data);
			})
			.catch((err) => reject(err));
	});
}

function createReturn(payload) {
	return new Promise((resolve, reject) => {
		jambuda
			.post("/api/order-returns", payload)
			.then((response) => {
				return resolve(response.data);
			})
			.catch((err) => reject(err));
	});
}

function orderReturnFlow(orderReturnsId, payload) {
	return new Promise((resolve, reject) => {
		jambuda
			.patch(`/api/order-returns/${orderReturnsId}`, payload)
			.then((response) => {
				return resolve(response.data);
			})
			.catch((err) => reject(err));
	});
}

function updateOrderStatus(orderId, status) {
	return new Promise((resolve, reject) => {
		jambuda
			.patch(`/api/order/${orderId}`, { status: status })
			.then((response) => {
				return resolve(response.data);
			})
			.catch((err) => reject(err));
	});
}
function updateOrderProductStatus(id, status) {
	return new Promise((resolve, reject) => {
		jambuda
			.patch(`/api/order-products/${id}`, { lineItemStatus: status })
			.then((response) => {
				return resolve(response.data);
			})
			.catch((err) => reject(err));
	});
}

function getOrders(merchantId, status, page, count) {
	let condition = `merchantId=${merchantId}&page=${page}&count=${count}`;
	if (status) {
		condition += `&status=returns`;
	}

	return new Promise((resolve, reject) => {
		jambuda
			.get(`/api/order-products?${condition}`)
			.then((response) => {
				return resolve(response.data);
			})
			.catch((err) => reject(err));
	});
}

function fetchOrdersBetweenDates(
	merchantId,
	page,
	count,
	startDate,
	endDate,
	paymentStatus
) {
	let condition = `merchantId=${merchantId}&page=${page}&count=${count}&startDate=${startDate}&endDate=${endDate}&paymentStatus=${paymentStatus}`;
	return new Promise((resolve, reject) => {
		jambuda
			.get(`/api/order-products/bydate?${condition}`)
			.then((response) => {
				return resolve(response.data);
			})
			.catch((err) => reject(err));
	});
}

function fetchStoreOrders(
	storeId,
	page,
	count,
	startDate,
	endDate,
	orderStatus
) {
	let condition = `storeId=${storeId}`;
	if (page) {
		condition += `&page=${page}`;
	}
	if (count) {
		condition += `&count=${count}`;
	}
	if (startDate) {
		condition += `&startDate=${startDate}`;
	}
	if (endDate) {
		condition += `&endDate=${endDate}`;
	}
	if (orderStatus) {
		condition += `&status=${orderStatus}`;
	}
	return new Promise((resolve, reject) => {
		jambuda
			.get(`/api/order?${condition}`)
			.then((response) => {
				return resolve(response.data);
			})
			.catch((err) => reject(err));
	});
}

function fetchPendingDeliveryOrdersForStore(storeId, page, count, orderStatus) {
	const payload = {
		storeId: storeId,
		page: page,
		count: count,
		status: orderStatus,
	};
	return new Promise((resolve, reject) => {
		jambuda
			.post(`/api/order/by-status`, payload)
			.then((response) => {
				return resolve(response.data);
			})
			.catch((err) => reject(err));
	});
}

function fetchAnOrderDetails(orderId) {
	return new Promise((resolve, reject) => {
		jambuda
			.get(`/api/order/${orderId}`)
			.then((response) => {
				return resolve(response.data);
			})
			.catch((err) => reject(err));
	});
}
