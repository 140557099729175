import React, { useEffect, useState } from "react";
import io, { Socket } from "socket.io-client";
import useSound from "use-sound";
import notifier from "../../assets/sounds/notifications.mp3";
import { setNotifications } from "features/notificationSlice";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
const socket = io(
	// "http://localhost:8001"
	"https://websocket.jumbuda.com"
);
const MKNotificationContainer = (props) => {
	const [play] = useSound(notifier);
	const store = useSelector((state) => state.stores.store);
	const merchant = useSelector((state) => state.merchants.merchant);
	const [counter, setCounter] = useState(0);
	const dispatch = useDispatch();
	const [notificationSound, setNotificationSound] = useState(false);
	const [newOrderCame, setNewOrderCame] = useState(false);
	const [newEnquiryCame, setNewEnquiryCame] = useState(false);
	const notifications = useSelector(
		(state) => state.notifications.notifications
	);
	useEffect(() => {
		socket.on("newOrder", (order) => {
			const items = order.orderProducts;
			let notes = [];

			_.map(items, (item) => {
				if (items.merchantId == merchant.id) {
					setCounter((prev) => 0);
					setNewOrderCame(true);
					notes.push({
						id: notes.length + 1,
						notificationFor: item.merchantId,
						notificationType: "merchant",
						date: new Date(item.createdAt).toLocaleDateString("en-US", {
							year: "numeric",
							month: "short",
							day: "numeric",
						}),
						description: "New Order Recieved.",
						product: item.Product.name,
						redirectUrl: "/order-detail",
						createdAt: item.createdAt,
						itemId: item.id,
						order: items,
						type: "order",
					});
				}
			});
			const s = _.uniqBy(notes, (note) => {
				return note.itemId;
			});

			let curr = [...notifications, ...s];
			const d = _.sortBy(curr, "createdAt", "desc");
			dispatch(setNotifications(d));
		});
		socket?.on("newProductQuery", (query) => {
			let notes = [];

			_.map(query, (item) => {
				if (item.merchantId == merchant.id) {
					setCounter((prev) => 0);
					setNewEnquiryCame(true);
					notes.push({
						id: notes.length + 1,
						notificationFor: item.merchantId,
						notificationType: "merchant",
						date: new Date(item.createdAt).toLocaleDateString("en-US", {
							year: "numeric",
							month: "short",
							day: "numeric",
						}),
						description: "New Enquiry Recieved.",
						product: item.ProductQuery?.Products?.name,
						brand: item.ProductQuery?.Brand?.name,
						model: item.ProductQuery?.Models?.name,
						redirectUrl: "/merchant-queries",
						createdAt: item.createdAt,
						type: "query",
					});
				}
			});
			const s = _.uniqBy(notes, (note) => {
				return note.productQueryId;
			});
			if (s.length) {
				// setMessage("You have a new Enquiry");
				// setOpenSnack(true);
			}
			let curr = [...notifications, ...s];
			const d = _.sortBy(curr, "createdAt", "desc");
			dispatch(setNotifications(d));
		});

		return () => {
			socket.off("newOrder");
			socket.off("newProductQuery");
		};
	}, []);

	useEffect(() => {
		if (counter < 5 && (newOrderCame || newEnquiryCame)) {
			const timer = setTimeout(() => {
				// Your function logic here
				playSound();

				// Update the counter
				setCounter((prevCounter) => prevCounter + 1);
			}, 2000);

			// Clear the timeout if the component unmounts
			return () => clearTimeout(timer);
		} else {
			setNewOrderCame(false);
			setNewEnquiryCame(false);
		}
	}, [newOrderCame, newEnquiryCame, counter]);

	const playSound = () => {
		//play();
	};

	return <>{props.children}</>;
};
export default MKNotificationContainer;
