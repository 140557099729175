import { createSlice } from "@reduxjs/toolkit";

export const modelSlice = createSlice({
	name: "models",
	initialState: {
		count: 0,
		models: [],
	},
	reducers: {
		setModels: (state, action) => {
			state.models = action.payload;
		},
		setModelsCount: (state, action) => {
			state.count = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const { setModels, setModelsCount } = modelSlice.actions;
export default modelSlice.reducer;
