const { default: jambuda } = require("./core");
const initialize = {
	getAllLocations,
	getAllZones,
};

function getAllZones() {
	return new Promise((resolve, reject) => {
		jambuda
			.get("/api/zone")
			.then((result) => {
				return resolve(result.data);
			})
			.catch((err) => {
				return reject(err);
			});
	});
}

function getAllLocations() {
	return new Promise((resolve, reject) => {
		jambuda
			.get("/api/location")
			.then((result) => {
				return resolve(result.data);
			})
			.catch((err) => {
				return reject(err);
			});
	});
}
export default initialize;
