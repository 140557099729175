const { default: jambuda } = require("./core");

const cartService = { addToCart, removeFromCart, editCart, fetchCart };
export default cartService;

function fetchCart(storeId) {
	return new Promise((resolve, reject) => {
		jambuda
			.get(`/api/cart?storeId=${storeId}`)
			.then((result) => {
				return resolve(result.data);
			})
			.catch((err) => {
				return reject(err);
			});
	});
}
function addToCart(payload) {
	return new Promise((resolve, reject) => {
		jambuda
			.post("/api/cart", payload)
			.then((result) => {
				return resolve(result.data);
			})
			.catch((err) => {
				return reject(err);
			});
	});
}
function removeFromCart(id) {
	return new Promise((resolve, reject) => {
		jambuda
			.delete(`/api/cart/${id}`)
			.then((result) => {
				return resolve(result.data);
			})
			.catch((err) => {
				return reject(err);
			});
	});
}
function editCart(id, payload) {
	return new Promise((resolve, reject) => {
		jambuda
			.patch(`/api/cart/${id}`, payload)
			.then((result) => {
				return resolve(result.data);
			})
			.catch((err) => {
				return reject(err);
			});
	});
}
