const { default: jambuda } = require("./core");

const models = { getAllModels };

function getAllModels() {
	return new Promise((resolve, reject) => {
		jambuda.get(`/api/model`).then((result) => {
			const models = result.data;
			const count = result.data.length;
			return resolve({ models: models, count: count });
		});
	});
}
export default models;
