import { createSlice } from "@reduxjs/toolkit";

export const productVariantSlice = createSlice({
	name: "variants",
	initialState: {
		count: 0,
		variants: [],
	},
	reducers: {
		setVariants: (state, action) => {
			state.variants = action.payload;
		},
		setVariantCount: (state, action) => {
			state.count = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const { setVariants, setVariantCount } = productVariantSlice.actions;
export default productVariantSlice.reducer;
