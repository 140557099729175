import { Container } from "@mui/material";
import MKLoader from "components/MKLoader";
import MKTypography from "components/MKTypography";
import React from "react";
const OrderSuccess = () => {
	return (
		<Container style={styles.container}>
			<MKLoader size={100} />
			<MKTypography variant='h2' color='dark'>
				Order Successfully Placed
			</MKTypography>
		</Container>
	);
};
export default OrderSuccess;
const styles = {
	container: {
		minHeight: "100vh",
		alignItems: "center",
		justifyContent: "center",
		display: "flex",
		flexDirections: "row",
	},
};
