import { NavigateNext } from "@mui/icons-material";
import { Box, Breadcrumbs, Link } from "@mui/material";
import MKTypography from "components/MKTypography";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const MKBreadCrumbs = (props) => {
	const [crumbs, setCrumbs] = useState(props.crumbs);
	const [breadcrumbs, setBreadCrumbs] = useState([]);
	const navigate = useNavigate();
	const handleClick = (e, crumb) => {
		e.preventDefault();
		navigate(crumb.link);
	};
	useEffect(() => {
		const refs = _.map(crumbs, (crumb, index) => {
			return (
				<Link
					underline='hover'
					key={index.toString()}
					color='inherit'
					href={crumb.link}
					onClick={(e) => handleClick(e, crumb)}
				>
					<MKTypography variant='caption' color='dark'>
						{crumb.name}
					</MKTypography>
				</Link>
			);
		});
		setBreadCrumbs(refs);
	}, []);
	return (
		<Box sx={{ padding: 2, m: 1 }}>
			<Breadcrumbs
				separator={<NavigateNext fontSize='small' sx={{ color: "#3E506E" }} />}
			>
				{breadcrumbs}
			</Breadcrumbs>
		</Box>
	);
};
export default MKBreadCrumbs;
