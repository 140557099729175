import SettlementsService from "api/settlements";
import React, { useEffect, useState } from "react";
import SettlementService from "../../api/settlements";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Checkbox,
	Container,
	Divider,
	Grid,
	Paper,
	Stack,
} from "@mui/material";
import MKTypography from "components/MKTypography";
import { DataGrid } from "@mui/x-data-grid";
import _ from "lodash";
import MKButton from "components/MKButton";
import MKInput from "components/MKInput";
import { useDispatch, useSelector } from "react-redux";
import { updateWallet } from "features/walletSlice";
const StoreSettlements = (props) => {
	const [storeSettlementData, setStoreSettlementData] = useState([]);
	const [service, setService] = useState(new SettlementService());
	const [storeSettlementInfo, setStoreSettlementInfo] = useState([]);
	const [loading, setLoading] = useState(false);
	const [fetchForStoreId, setFetchForStoreId] = useState(null);
	const [orderTotals, setOrderTotals] = useState({});
	const [error, setError] = useState("");
	const [message, setMessage] = useState("");
	const [open, setOpen] = useState(false);
	const dispatch = useDispatch();

	const [refreshKey, setRefreshKey] = useState(Math.random() * 10000);
	useEffect(() => {
		service
			.fetchAllDue()
			.then((result) => {
				const due = result.storeResponse;

				setStoreSettlementData(due);
				console.log(due);
			})
			.catch((err) => {
				console.log(err);
			});
	}, [refreshKey]);
	useEffect(() => {
		if (fetchForStoreId) {
			fetchStoreSettlement(fetchForStoreId);
		}
	}, [refreshKey]);
	const handleOrderTotalChange = (total) => {
		let current = { ...orderTotals };
		current[fetchForStoreId] = total;
		setOrderTotals(current);
	};
	const handleSettlement = (payload) => {
		service
			.submitShopSettlements(payload)
			.then((response) => {
				const updatedWallet = response.updatedWallet;
				dispatch(updateWallet(updatedWallet));
				setRefreshKey(Math.random() * 10000);
			})
			.catch((err) => {
				console.log(err);
				setError(err.response.data.message);
			});
	};

	const fetchStoreSettlement = (store_id) => {
		setLoading(true);
		service
			.fetchAllDueForStore(store_id)
			.then((result) => {
				setStoreSettlementInfo(result.settlementInfo);
				setLoading(false);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	return (
		<Container>
			{_.map(storeSettlementData, function (item) {
				return (
					<Accordion
						sx={{ width: "100%" }}
						expanded={item.storeId == fetchForStoreId}
					>
						<AccordionSummary sx={{ width: "100%" }}>
							<Paper
								sx={{
									alignItems: "center",
									justifyContent: "center",
									display: "flex",
									backgroundColor: "#fafafa",
									padding: 2,
									width: "100%",
								}}
								elevation={0}
							>
								<Grid container spacing={1}>
									<Grid item xs={3} sm={2}>
										<Stack
											gap={1}
											sx={{
												alignItems: "center",
												justifyContent: "center",
												display: "flex",
											}}
										>
											<MKTypography
												variant='caption'
												color='dark'
												fontWeight='bold'
											>
												Store Id
											</MKTypography>
											<MKTypography variant='caption' color='dark'>
												{item.storeId}
											</MKTypography>
										</Stack>
									</Grid>
									<Grid item xs={3} sm={2}>
										<Stack
											gap={1}
											sx={{
												alignItems: "center",
												justifyContent: "center",
												display: "flex",
											}}
										>
											<MKTypography
												variant='caption'
												color='dark'
												fontWeight='bold'
											>
												Store Name
											</MKTypography>
											<MKTypography variant='caption' color='dark'>
												{item.storeName}
											</MKTypography>
										</Stack>
									</Grid>
									<Grid item xs={3} sm={2}>
										<Stack
											gap={1}
											sx={{
												alignItems: "center",
												justifyContent: "center",
												display: "flex",
											}}
										>
											<MKTypography
												variant='caption'
												color='dark'
												fontWeight='bold'
											>
												Delivery Charge
											</MKTypography>
											<MKTypography variant='caption' color='dark'>
												AED&nbsp;{Number(item.deliveryCharge).toFixed(2)}
											</MKTypography>
										</Stack>
									</Grid>
									<Grid item xs={3} sm={2}>
										<Stack
											gap={1}
											sx={{
												alignItems: "center",
												justifyContent: "center",
												display: "flex",
											}}
										>
											<MKTypography
												variant='caption'
												color='dark'
												fontWeight='bold'
											>
												Order Total
											</MKTypography>
											<MKTypography variant='caption' color='dark'>
												AED&nbsp;
												{orderTotals[fetchForStoreId]
													? Number(orderTotals[fetchForStoreId]).toFixed(2)
													: Number(item.total).toFixed(2)}
											</MKTypography>
										</Stack>
									</Grid>
									<Grid item xs={12} sm={4}>
										<Stack
											gap={1}
											sx={{
												alignItems: "center",
												justifyContent: "center",
												display: "flex",
											}}
										>
											<MKTypography
												variant='caption'
												color='dark'
												fontWeight='bold'
											>
												Action
											</MKTypography>
											<MKTypography variant='caption' color='dark'>
												<MKButton
													variant='contained'
													color='info'
													size='small'
													onClick={() => {
														setFetchForStoreId(item.storeId);
														fetchStoreSettlement(item.storeId);
													}}
												>
													Collect Cash
												</MKButton>
											</MKTypography>
										</Stack>
									</Grid>
									<Grid item xs={12}>
										<Paper
											sx={{ minHeight: "5vh", backgroundColor: "#fafafa" }}
											elevation={0}
										>
											<MKTypography variant='caption' color='error'>
												{error}
											</MKTypography>
										</Paper>
									</Grid>
								</Grid>
							</Paper>
						</AccordionSummary>
						<AccordionDetails>
							{storeSettlementInfo.length ? (
								<ShopSettlementRecord
									key={storeSettlementInfo[0].id}
									loading={loading}
									orders={storeSettlementInfo}
									onSubmit={handleSettlement}
									onOrderTotalChage={handleOrderTotalChange}
								/>
							) : (
								<></>
							)}
						</AccordionDetails>
					</Accordion>
				);
			})}
		</Container>
	);
};
export default StoreSettlements;

const ShopSettlementRecord = (props) => {
	const [loading, setLoading] = useState(props.loading);
	const [orders, setOrders] = useState([]);
	const [selectedOrders, setSelectedOrders] = useState([]);
	const [amountSetteled, setAmountSettled] = useState(0);
	const [totalForSelectedOrders, setTotalForSelectedOrders] = useState(0);
	const [totalDeliveryCharge, setTotalDeliveryCharge] = useState(0);
	const wallet = useSelector((state) => state.wallet.wallet);

	const handleSelectOrder = (e, orderId) => {
		console.log("orderId", orderId);
		if (e.target.checked) {
			setSelectedOrders((prev) => [...prev, orderId]);
		} else {
			const remaining = _.filter(selectedOrders, (id) => {
				return id != orderId;
			});
			console.log(remaining);
			setSelectedOrders(remaining);
		}
	};

	const handleSubmitSettlements = () => {
		const payload = {
			storeId: orders[0].storeId,
			orderProductIds: selectedOrders,
			amountSettlement: Number(amountSetteled),
			walletId: wallet.id,
		};

		if (props.onSubmit) {
			console.log("Payload", payload);
			props.onSubmit(payload);
		}
	};

	useEffect(() => {
		let total = 0;
		_.map(orders, (order) => {
			if (selectedOrders.includes(order.orderProductId)) {
				total += Number(order.totalStoreReceivable) - Number(order.paidSoFar);
			}
		});
		setTotalForSelectedOrders(total);
		if (props.onOrderTotalChage) {
			props.onOrderTotalChage(total);
		}
	}, [selectedOrders]);

	useEffect(() => {
		let orderIds = [];
		const totalDeliveryChargeFromStore = _.sumBy(props.orders, (o) => {
			if (o.OrderProduct.Order.deliveryChargeCollected) return 0;
			else return o.OrderProduct.Order.deliveryChargeTotal;
		});
		setTotalDeliveryCharge(totalDeliveryChargeFromStore);
		const records = _.map(props.orders, (order) => {
			orderIds.push(order.orderProductId);
			const store_payment_total_on_this_product = _.sumBy(
				order.OrderProduct?.StorePayments,
				(p) => p.amount
			);

			const payload = {
				orderProductId: order?.orderProductId,
				storeId: order?.storeId,
				totalStoreReceivable: order.totalStoreReceivable,

				settlementStatus: order.settlementStatus,
				color: order.OrderProduct?.ProductQuery?.color,
				quantity: order.OrderProduct?.quantity,
				product: `${order.OrderProduct?.ProductQuery?.Brand?.name} ${order.OrderProduct?.ProductQuery?.Models?.name}`,
				variant: order?.OrderProduct?.ProductQuery?.variantId
					? order?.OrderProduct?.ProductQuery?.Variant?.name
					: order?.OrderProduct?.ProductQuery.qualityVariant,
				paidSoFar: store_payment_total_on_this_product,
				paymentStatus:
					store_payment_total_on_this_product == 0
						? "Unpaid"
						: "Partially Paid",
			};
			return payload;
		});
		setOrders(records);
		setSelectedOrders(orderIds);
	}, []);
	return (
		<Box>
			{loading
				? "Loading..."
				: _.map(orders, (order) => {
						return (
							<Grid container spacing={1} sx={{ mt: 2 }} key={order.paidSoFar}>
								<Grid item xs={12}></Grid>
								<Grid item xs={12} sm={1}>
									<Stack gap={1}>
										<Checkbox
											checked={selectedOrders.includes(order.orderProductId)}
											onChange={(e) =>
												handleSelectOrder(e, order.orderProductId)
											}
										/>
									</Stack>
								</Grid>

								<Grid item xs={12} sm={2}>
									<Stack gap={1}>
										<MKTypography
											variant='caption'
											color='dark'
											fontWeight='bold'
										>
											Product
										</MKTypography>
										<MKTypography variant='caption' color='dark'>
											{order.product}&nbsp;{order.variant} X {order.quantity}
										</MKTypography>
									</Stack>
								</Grid>
								<Grid item xs={6} sm={2}>
									<Stack gap={1}>
										<MKTypography
											variant='caption'
											color='dark'
											fontWeight='bold'
										>
											Color
										</MKTypography>
										<Stack direction={"row"} gap={1}>
											<Box
												sx={{
													width: 20,
													height: 20,
													backgroundColor: order.color.hex,
												}}
											/>
											<MKTypography variant='caption' color='dark'>
												{order.color.name}
											</MKTypography>
										</Stack>
									</Stack>
								</Grid>
								<Grid item xs={6} sm={2}>
									<Stack gap={1}>
										<MKTypography
											variant='caption'
											color='dark'
											fontWeight='bold'
										>
											Total Order Value
										</MKTypography>
										<MKTypography variant='caption' color='dark'>
											AED&nbsp;{Number(order.totalStoreReceivable).toFixed(2)}
										</MKTypography>
									</Stack>
								</Grid>
								<Grid item xs={6} sm={2}>
									<Stack gap={1}>
										<MKTypography
											variant='caption'
											color='dark'
											fontWeight='bold'
										>
											Paid Sofar
										</MKTypography>
										<MKTypography variant='caption' color='dark'>
											AED&nbsp;{Number(order.paidSoFar).toFixed(2)}
										</MKTypography>
									</Stack>
								</Grid>
								<Grid item xs={6} sm={2}>
									<Stack gap={1}>
										<MKTypography
											variant='caption'
											color='dark'
											fontWeight='bold'
										>
											Status
										</MKTypography>
										<MKTypography
											variant='caption'
											color='dark'
											fontWeight='bold'
										>
											{order.paymentStatus}
										</MKTypography>
									</Stack>
								</Grid>

								<Grid item xs={12} sm={12}>
									<Divider sx={{ width: "100%" }} />
								</Grid>
							</Grid>
						);
				  })}
			<Grid container spacing={1}>
				<Grid item xs={12} sm={6}>
					<Stack gap={1}>
						<MKInput
							placeholder={"Enter Amount Collected"}
							label='Amount Collected'
							value={amountSetteled}
							onChange={(e) => setAmountSettled(e.target.value)}
							fullWidth
						/>
						<MKTypography variant='caption' color='dark' fontWeight='bold'>
							Subtotal AED&nbsp;
							{Number(totalForSelectedOrders).toFixed(2)}
						</MKTypography>
					</Stack>
					{totalDeliveryCharge > 0 ? (
						<Stack gap={1}>
							<MKTypography variant='caption' color='dark' fontWeight='bold'>
								+ Delivery Charge AED&nbsp;{totalDeliveryCharge}
							</MKTypography>
						</Stack>
					) : (
						<></>
					)}
				</Grid>
				<Grid item xs={12} sm={6}>
					<MKButton
						variant='contained'
						onClick={handleSubmitSettlements}
						fullWidth
						color='dark'
					>
						Submit
					</MKButton>
				</Grid>
			</Grid>
		</Box>
	);
};
