import {
	Autocomplete,
	Box,
	Container,
	IconButton,
	Paper,
	Snackbar,
	Stack,
	TextField,
} from "@mui/material";
import stores from "../../api/stores";

import MKButton from "components/MKButton";
import MKInput from "components/MKInput";
import MKTypography from "components/MKTypography";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setStore } from "features/storeSlice";
import { RiEditCircleFill } from "react-icons/ri";
import { CheckCircle, Edit } from "@mui/icons-material";
import _ from "lodash";
const StoreProfile = (props) => {
	const [locations, setLocations] = useState([]);
	const [zones, setZones] = useState([]);
	const [countries, setCountries] = useState([]);
	const localities = useSelector((state) => state.locations);
	const [selectedZone, setSelectedZone] = useState(null);
	const [selectedLocation, setSelectedLocation] = useState(null);
	const [name, setName] = useState("");
	const [address1, setAddress1] = useState("");
	const user = useSelector((state) => state.user);
	const store = useSelector((state) => state.stores.store);
	const [error, setError] = useState(null);
	const [lat, setLat] = useState(0);
	const [lng, setLng] = useState(0);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	useEffect(() => {
		if (localities) {
			setZones(localities.zones);
			setLocations(localities.locations);
			setCountries(localities.countries);
		}
	}, []);

	const handleSubmit = () => {
		if (
			!name ||
			!address1 ||
			!selectedZone ||
			!selectedLocation ||
			!lat ||
			!lng
		) {
			setError("This field is required");
		}
		const payload = {
			name: name,
			adminId: user.user.user.id,
			location: { lat: lat, lng: lng },
			address1: address1,
			street: "",
			state: selectedZone.name,
			country: "UAE",
			locationId: selectedLocation.id,
			zoneId: selectedZone.id,
		};
		stores.createStore(payload).then((result) => {
			dispatch(setStore(result.store));
			navigate("/query");
		});
	};
	return store?.id ? (
		<EditProfile />
	) : (
		<Container
			style={{
				alignItems: "center",
				justifyContent: "center",
				display: "flex",
				flexDirection: "column",
				minHeight: "60vh",
			}}
		>
			<Paper sx={styles.paper} elevation={0}>
				<Stack
					gap={2}
					sx={{
						width: "100%",
						alignItems: "center",
						justifyContent: "center",
						display: "flex",
						flexDirection: "column",
					}}
				>
					<MKTypography variant='body1' color='dark'>
						Register Your Store
					</MKTypography>

					<MKInput
						placeholder='Store Name'
						fullWidth
						onChange={(e) => setName(e.target.value)}
						error={error && !name}
					/>
					<MKInput
						placeholder='Building Number'
						fullWidth
						onChange={(e) => setAddress1(e.target.value)}
						error={error && !address1}
					/>
					<Stack direction={"row"} gap={1}>
						<MKInput
							placeholder='Lat'
							onChange={(e) => setLat(e.target.value)}
							error={error && !lat}
						/>
						<MKInput
							placeholder='Lng'
							onChange={(e) => setLng(e.target.value)}
							error={error && !lng}
						/>
					</Stack>
					<Autocomplete
						options={zones}
						value={selectedZone}
						getOptionLabel={(option) => option.name}
						sx={{ width: "100%" }}
						onChange={(e, newValue) => {
							if (newValue) {
								setSelectedZone(newValue);
							}
						}}
						renderInput={(params) => (
							<TextField
								required
								error={error && !selectedZone}
								size='small'
								{...params}
								label='Choose a Zone'
								inputProps={{
									...params.inputProps,
									autoComplete: "new-password", // disable autocomplete and autofill
								}}
							/>
						)}
					/>
					<Autocomplete
						options={locations}
						value={selectedLocation}
						getOptionLabel={(option) => option.name}
						sx={{ width: "100%" }}
						onChange={(e, newValue) => {
							if (newValue) {
								setSelectedLocation(newValue);
							}
						}}
						renderInput={(params) => (
							<TextField
								size='small'
								error={error && !selectedLocation}
								{...params}
								label='Choose a Locality'
								inputProps={{
									...params.inputProps,
									autoComplete: "new-password", // disable autocomplete and autofill
								}}
							/>
						)}
					/>

					<MKButton
						fullWidth
						variant='contained'
						color='primary'
						size='large'
						onClick={handleSubmit}
					>
						Submit
					</MKButton>
				</Stack>
			</Paper>
		</Container>
	);
};
export default StoreProfile;

const EditProfile = (props) => {
	const store = useSelector((state) => state.stores.store);
	const storeSlice = useSelector((state) => state.stores);
	const [locations, setLocations] = useState([]);
	const [zones, setZones] = useState([]);
	const [countries, setCountries] = useState([]);
	const localities = useSelector((state) => state.locations);
	const [selectedZone, setSelectedZone] = useState(null);
	const [selectedLocation, setSelectedLocation] = useState(null);
	const [name, setName] = useState("");
	const [address1, setAddress1] = useState("");
	const user = useSelector((state) => state.user);

	const [error, setError] = useState(null);
	const [lat, setLat] = useState(0);
	const [lng, setLng] = useState(0);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [edit, setEdit] = useState(false);
	const [open, setOpen] = useState(false);
	const [message, setMessage] = useState("");
	useEffect(() => {
		if (localities) {
			setZones(localities.zones);
			setLocations(localities.locations);
			setCountries(localities.countries);
		}
		if (store?.id) {
			const selectedZ = _.find(localities.zones, (z) => {
				return z.id == store?.zoneId;
			});
			setSelectedZone(selectedZ);
			const selectedL = _.find(localities?.locations, (l) => {
				return l.id == store?.locationId;
			});
			setSelectedLocation(selectedL);
			setName(store.name);
			setAddress1(store.address1);
			setLat(store.location?.lat);
			setLng(store.location?.lng);
		}
	}, []);

	const handleSubmit = () => {
		if (
			!name ||
			!address1 ||
			!selectedZone ||
			!selectedLocation ||
			!lat ||
			!lng
		) {
			setError("This field is required");
		}
		const payload = {
			name: name,
			adminId: user.user.id,
			location: { lat: lat, lng: lng },
			address1: address1,
			street: "",
			state: selectedZone.name,
			country: "UAE",
			locationId: selectedLocation.id,
			zoneId: selectedZone.id,
		};
		stores
			.updateStore(store.id, payload)
			.then((result) => {
				dispatch(setStore(result));
				setMessage("Updated Successfully");
				setOpen(true);
			})
			.catch((err) => {
				console.log(err);
				setMessage("Update Failed!");
				setOpen(true);
			});
	};
	return (
		<Container
			style={{
				alignItems: "center",
				justifyContent: "center",
				display: "flex",
				flexDirection: "column",
				minHeight: "60vh",
			}}
		>
			<Snackbar
				open={open}
				autoHideDuration={4000}
				onClose={() => setOpen(false)}
				message={
					<MKTypography variant='caption' color='white'>
						{message}
					</MKTypography>
				}
				action={
					<MKButton
						iconOnly
						color='white'
						circular
						size='small'
						onClick={() => setOpen(false)}
					>
						<CheckCircle />
					</MKButton>
				}
			/>
			<Paper sx={styles.paper} elevation={0}>
				<Stack
					gap={2}
					sx={{
						width: "100%",
						alignItems: "center",
						justifyContent: "center",
						display: "flex",
						flexDirection: "column",
					}}
				>
					<MKTypography variant='body1' color='dark'>
						Edit Your Store Profile &nbsp;&nbsp;
						<IconButton onClick={() => setEdit(!edit)}>
							<RiEditCircleFill />
						</IconButton>
					</MKTypography>

					<MKInput
						placeholder='Store Name'
						fullWidth
						onChange={(e) => setName(e.target.value)}
						error={error && !name}
						value={name}
						disabled={!edit}
					/>
					<MKInput
						placeholder='Building Number'
						fullWidth
						onChange={(e) => setAddress1(e.target.value)}
						error={error && !address1}
						value={address1}
						disabled={!edit}
					/>
					<Stack direction={"row"} gap={1}>
						<MKInput
							placeholder='Lat'
							onChange={(e) => setLat(e.target.value)}
							error={error && !lat}
							value={lat}
							disabled={!edit}
						/>
						<MKInput
							placeholder='Lng'
							onChange={(e) => setLng(e.target.value)}
							error={error && !lng}
							value={lng}
							disabled={!edit}
						/>
					</Stack>
					<Autocomplete
						options={zones}
						disabled={!edit}
						value={selectedZone}
						getOptionLabel={(option) => option.name}
						sx={{ width: "100%" }}
						onChange={(e, newValue) => {
							if (newValue) {
								setSelectedZone(newValue);
							}
						}}
						renderInput={(params) => (
							<TextField
								disabled={!edit}
								required
								error={error && !selectedZone}
								size='small'
								{...params}
								label='Choose a Zone'
								inputProps={{
									...params.inputProps,
									autoComplete: "new-password", // disable autocomplete and autofill
								}}
							/>
						)}
					/>
					<Autocomplete
						options={locations}
						disabled={!edit}
						value={selectedLocation}
						getOptionLabel={(option) => option.name}
						sx={{ width: "100%" }}
						onChange={(e, newValue) => {
							if (newValue) {
								setSelectedLocation(newValue);
							}
						}}
						renderInput={(params) => (
							<TextField
								disabled={!edit}
								size='small'
								error={error && !selectedLocation}
								{...params}
								label='Choose a Locality'
								inputProps={{
									...params.inputProps,
									autoComplete: "new-password", // disable autocomplete and autofill
								}}
							/>
						)}
					/>

					<MKButton
						fullWidth
						variant='contained'
						color='primary'
						size='large'
						onClick={handleSubmit}
					>
						Submit
					</MKButton>
				</Stack>
			</Paper>
		</Container>
	);
};

const styles = {
	paper: {
		padding: 3,
		minWidth: "400px",

		alignItems: "center",
		justifyContent: "center",
		display: "flex",
		backgroundColor: "#FBFCFA",
	},
};
