import React, { useEffect, useState } from "react";
import img from "assets/images/divbg.jpg";
import MKBox from "components/MKBox";
import {
	Divider,
	FormControl,
	FormControlLabel,
	Grid,
	IconButton,
	Link,
	Paper,
	Radio,
	RadioGroup,
	Stack,
} from "@mui/material";
import MKInput from "components/MKInput";
import { validateEmail } from "../../api/helper/util";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import { Facebook, FacebookRounded, Google } from "@mui/icons-material";
import MKShell from "components/MKShell";
import auth from "../../api/auth";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { login } from "features/userSlice";
import { setUser } from "features/userSlice";
import stores from "../../api/stores";
import { setStore } from "features/storeSlice";
import wallet from "../../api/wallet";
import { updateWallet } from "features/walletSlice";
import merchants from "../../api/merchants";
import { setMerchant } from "features/merchantSlice";
import { setMerchantProfile } from "features/merchantSlice";
import { logout } from "features/userSlice";
import { setMerchantBrands } from "features/merchantSlice";
import Partners from "../../api/partners";
const SignIn = (props) => {
	const [email, setEmail] = useState("");
	const [validEmail, setValidEmail] = useState(false);
	const [name, setName] = useState("");
	const [password, setPassword] = useState("");
	const [loginAs, setLoginAs] = useState("STORE_ADMIN");
	const [view, setView] = useState("singin");
	const user = useSelector((state) => state.user);
	const [partnerService, setPartnerService] = useState(new Partners());
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const handleChange = (e) => {
		setLoginAs(e.target.value);
	};
	useEffect(() => {
		if (user.loggedIn) {
			if (user.user?.user?.userType == "STORE_ADMIN") {
				navigate("/store-queries");
			} else if (user.user?.user?.userType == "MERCHANT_ADMIN") {
				navigate("/m-dashboard");
			} else if (user.user?.user?.userType == "GLOBAL") {
				navigate("/settlements/store");
			}
		}
	}, []);

	const handleEmailChange = (e) => {
		setEmail(e);
		if (validateEmail(e)) {
			setValidEmail(true);
		}
	};
	const hnadleSignup = () => {
		const [firstName, lastName] = name.split(" ");
		const payload = {
			firstName: firstName,
			lastName: lastName,
			email: email,
			password: password,
			userType: loginAs,
		};
		auth.register(payload).then((user) => {
			dispatch(setUser(user));
			navigate("/otp", { state: { email: user.email } });
		});
	};

	const handleSignin = () => {
		const payload = {
			email: email,
			password: password,
		};
		auth.login(payload).then((user) => {
			const memberships = user.membership;
			console.log("Membershiops", memberships);
			if (memberships.merchant || memberships.store) {
				navigate("/selector", {
					state: { user: user, memberships: memberships },
				});
				return;
			}
			dispatch(setUser(user));
			dispatch(login());
			if (user.user.userType == "DELIVERY_PARTNER") {
				partnerService.fetch_partner_data(user.user.id).then((result) => {
					dispatch(updateWallet(result.wallet));
				});
				navigate("/settlements/store");
			} else if (user.user.userType == "STORE_ADMIN") {
				stores
					.getStoreDataByUser(user?.user?.id)
					.then((data) => {
						dispatch(setStore(data));
						wallet
							.fetchWalletForStore(data.id)
							.then((result) => {
								dispatch(updateWallet(result));
								navigate("/query");
							})
							.catch((err) => console.log(err));
					})
					.catch((error) => {});
			} else if (user.user.userType == "MERCHANT_ADMIN") {
				merchants
					.findMerchantByAdminId(user?.user?.id)
					.then((ms) => {
						const mers = ms.merchants;

						wallet.fetchWalletForMerchant(mers[0].id).then((result) => {
							dispatch(updateWallet(result));
						});
						dispatch(setMerchant(mers[0]));
						if (mers.length) {
							console.log(mers[0]);
							dispatch(setMerchantBrands(mers[0].MerchantBrands));
							merchants
								.getMerchantProfile(mers[0].id)
								.then((profiles) => {
									console.log(profiles);
									if (profiles.length) {
										const profile = profiles[0];
										dispatch(setMerchantProfile(profile));

										navigate("/m-dashboard");
									} else {
										navigate("/merchant-profile");
									}
								})
								.catch((err) => {
									console.log(err);
								});
						} else {
							navigate("/query");
						}
					})
					.catch((err) => {
						console.log(err);
					});
			} else {
				navigate("/query");
			}
		});
	};

	return (
		<>
			<MKBox
				minHeight='60vh'
				width='100%'
				sx={{
					display: "grid",
					placeItems: "center",
				}}
			>
				<Grid container spacing={1}>
					<Grid
						item
						xs={12}
						sm={12}
						sx={{
							alignItems: "center",
							justifyContent: "center",
							display: "flex",
						}}
					>
						<Paper sx={styles.form}>
							<Stack gap={1}>
								<MKTypography variant='body1' fontWeight='bold'>
									{view === "signup" ? "Sign Up" : "Sign In"}
								</MKTypography>
								{view === "signup" && (
									<MKInput
										label='Your Full Name'
										fullWidth={true}
										required
										onChange={(e) => setName(e.target.value)}
										value={name}
										error={!name || !name.includes(" ")}
										success={name && name.includes(" ")}
									/>
								)}

								<MKInput
									label='Your Email'
									fullWidth={true}
									type='email'
									required
									value={email}
									error={!validEmail}
									success={validEmail}
									onChange={(e) => handleEmailChange(e.target.value)}
								/>
								<MKInput
									label='Create Password'
									fullWidth={true}
									type='password'
									required
									value={password}
									error={!password}
									success={password}
									onChange={(e) => setPassword(e.target.value)}
								/>
								{props.error && (
									<MKTypography color='error' variant='caption'>
										{props.error}
									</MKTypography>
								)}

								{view === "signup" ? (
									<Stack gap={1}>
										<FormControl>
											<RadioGroup
												row
												aria-labelledby='login-as-group'
												name='controlled-radio-buttons-group-login-as'
												value={loginAs}
												onChange={handleChange}
											>
												<FormControlLabel
													value='STORE_ADMIN'
													control={<Radio size='small' />}
													label={
														<MKTypography variant='caption' color='dark'>
															I'm a Service Center
														</MKTypography>
													}
												/>
												<FormControlLabel
													value='MERCHANT_ADMIN'
													control={<Radio size='small' />}
													label={
														<MKTypography variant='caption' color='dark'>
															I'm a Supplier
														</MKTypography>
													}
												/>
											</RadioGroup>
										</FormControl>
										<MKButton
											color='primary'
											variant='contained'
											fullWidth
											onClick={hnadleSignup}
										>
											Sign up
										</MKButton>
									</Stack>
								) : (
									<MKButton
										color='primary'
										variant='contained'
										fullWidth
										onClick={handleSignin}
									>
										Sign In
									</MKButton>
								)}

								<Divider />
								<Stack
									direction={"row"}
									gap={1}
									sx={{
										alignItems: "center",
										justifyContent: "flex-start",
										display: "flex",
									}}
								>
									<MKTypography variant='caption'>Continue With</MKTypography>
									<IconButton>
										<FacebookRounded />
									</IconButton>
									<IconButton>
										<Google />
									</IconButton>
								</Stack>
								{view === "signup" ? (
									<div onClick={() => setView("signin")}>
										<MKTypography variant='caption' color='dark'>
											Already has an account? &nbsp;
											<MKTypography
												variant='caption'
												color='primary'
												style={{ cursor: "pointer" }}
											>
												Sign In
											</MKTypography>
										</MKTypography>
									</div>
								) : (
									<div onClick={() => setView("signup")}>
										<MKTypography variant='caption' color='dark'>
											Don't have an account? &nbsp;
											<MKTypography
												variant='caption'
												color='primary'
												style={{ cursor: "pointer" }}
											>
												Register
											</MKTypography>
										</MKTypography>
									</div>
								)}
							</Stack>
						</Paper>
					</Grid>
				</Grid>
			</MKBox>
		</>
	);
};
export default SignIn;

const styles = {
	form: {
		minWidth: 350,
		height: "20h",
		padding: 5,
	},
};
