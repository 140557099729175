import {
	Box,
	Checkbox,
	Chip,
	Container,
	Divider,
	Grid,
	IconButton,
	Paper,
	Stack,
} from "@mui/material";
import cartService from "../../api/cart";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCart } from "features/cartSlice";
import MKTypography from "components/MKTypography";
import _ from "lodash";
import { RiDeleteBinFill } from "react-icons/ri";
import { MdOutlineSupervisedUserCircle } from "react-icons/md";
import { FaApple, FaGoogle } from "react-icons/fa";
import { SiHuawei, SiSamsung } from "react-icons/si";
import deliveryService from "../../api/delivery";
import { TbTruckDelivery } from "react-icons/tb";
import { SlLocationPin } from "react-icons/sl";
import MKButton from "components/MKButton";
import MKInput from "components/MKInput";
import MKQuantity from "components/MKQuantity";
import { CurrencyYenTwoTone, DeleteForever } from "@mui/icons-material";
import orderService from "../../api/orders";
import { useNavigate } from "react-router-dom";
import { FaWineGlassEmpty } from "react-icons/fa6";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
const breadcrumb = [
	{ id: 1, name: "Dashboard", link: "/store-queries" },
	{ id: 2, name: "My Cart", link: "" },
];
const logos = {
	Apple: <FaApple />,
	Samsung: <SiSamsung />,
	Huawei: <SiHuawei />,
	Google: <FaGoogle />,
};

const StoreCart = (props) => {
	const cartProducts = useSelector((state) => state.cart.products);
	const dispatch = useDispatch();
	const store = useSelector((state) => state.stores.store);
	const [addedForOrder, setAddedForOrder] = useState([]);
	const [deliveryCharges, setDeliveryCharges] = useState(0);
	const [cashback, SetCashback] = useState(0);
	const [totalOrder, setTotalOrder] = useState(0);
	const [paymentMode, setPaymentMode] = useState("CASH_ON_DELIVERY");
	const [paymentModeTitle, setPaymentModeTitle] = useState("Pay on Delivery");
	const navigate = useNavigate();
	const apiRef = useGridApiRef();
	const [totalQtyOfItemsOnOrder, setTotalQuantityOfItemsOnOrder] = useState(0);
	useEffect(() => {
		calculateTotals();
	}, [addedForOrder]);

	const placeOrder = () => {
		console.log("ADDED FR ORDEr", addedForOrder);
		let totalMarkup = 0;
		const lineItems = _.map(addedForOrder, (item) => {
			totalMarkup += item.BestQuote?.Response?.markup?.amount;
			const s = {
				queryId: item.ProductQuery.id,
				productId: item.ProductQuery.productId,
				merchantId: item.BestQuote?.Response?.merchantId,
				quantity: item.ProductQuery.quantity,
				markup: item.BestQuote?.Response?.markup?.amount,
				cashback: item.BestQuote?.Response?.cashback,
				totalPrice: {
					currency: "AED",
					amount:
						Number(item.ProductQuery.quantity) *
						(Number(item.BestQuote?.Response?.quotedPricePerUnit?.amount) +
							Number(item.BestQuote?.Response?.markup?.amount)),
				},
				variantId: item.ProductQuery.variantId,
				discount: 0,
			};
			console.log(s);
			return s;
		});
		const payload = {
			createdVia: "WEB",
			cashbackTotal: cashback,
			deliveryChargeTotal: deliveryCharges,
			deliveryChargeTax: 0,
			total: totalOrder,
			totalMerchantPayable: totalOrder - totalMarkup,
			totalMerchantPayableTax: 0,
			totalMarkup: totalMarkup,
			totalTax: 0,
			storeId: store.id,
			locationId: null,
			zoneId: store.zoneId,
			orderNote: "",
			// cartId: 1,
			paymentMode: paymentMode,
			paymentModeTitle: paymentModeTitle,
			transactionId: "asdjh45434hdyk",
			cart_hash: "$#$",
			paymentStatus: "UNPAID",
			lineItems: lineItems,
			queryIds: _.map(lineItems, "queryId"),
		};
		console.log(payload);

		orderService
			.createOrder(payload)
			.then(function (result) {
				dispatch(setCart([]));
				_.map(addedForOrder, (query) => {
					handleDelete(query);
				});
				navigate("/order-success");
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const calculateTotals = (newValues) => {
		let sum = 0;
		let total = 0;
		let candidate = addedForOrder;
		if (newValues) {
			candidate = newValues;
		}
		let totalQty = 0;
		_.map(candidate, (product) => {
			console.log("Best Price", product?.BestQuote?.Response);
			let ourMarkup = Number(product?.BestQuote?.Response?.markup.amount);
			sum += Number(product?.BestQuote?.Response?.cashback);
			if (ourMarkup && ourMarkup > 0) {
				ourMarkup *= product.ProductQuery.quantity;
			}
			const pricePerUnit =
				product?.BestQuote?.Response?.quotedPricePerUnit.amount *
				Number(product.ProductQuery.quantity);
			if (pricePerUnit && Number(pricePerUnit) > 0) {
				total += Number(pricePerUnit) + ourMarkup;
			}
			totalQty += product.ProductQuery.quantity;
		});
		setTotalQuantityOfItemsOnOrder(totalQty);
		SetCashback(sum);
		setTotalOrder(total);
		setAddedForOrder(candidate);
	};

	const handleQuantityChange = (newValue, product) => {
		let current = [...addedForOrder];

		const newValues = _.map(current, function (d) {
			if (d.id == product.id) {
				let s = { ...d.ProductQuery, quantity: newValue };

				let m = { ...d, ProductQuery: s };

				return m;
			} else {
				return d;
			}
		});

		setAddedForOrder(newValues);
		calculateTotals(newValues);
	};

	useEffect(() => {
		if (store) {
			let total = 0;
			cartService
				.fetchCart(store.id)
				.then((cartFetched) => {
					dispatch(setCart(cartFetched));
					setAddedForOrder(cartFetched);
					// apiRef.current.autosizeColumns(autosizeOptions);
					_.map(cartFetched, (prod) => {
						total += prod.ProductQuery.quantity;
					});
					deliveryService
						.getDeliveryCharge(store.zoneId, store.id, store.locationId, total)
						.then((config) => {
							setDeliveryCharges(config);
						})
						.catch((err) => {
							console.log(err);
						});
				})
				.catch((err) => {
					console.log(err);
				});
		}
	}, []);
	useEffect(() => {
		let total = 0;
		_.map(addedForOrder, (prod) => {
			total += prod.ProductQuery.quantity;
		});
		deliveryService
			.getDeliveryCharge(store.zoneId, store.id, store.locationId, total)
			.then((config) => {
				setDeliveryCharges(config);
			})
			.catch((err) => {
				console.log(err);
			});
	}, [addedForOrder]);
	const handleDelete = (cartToDelete) => {
		cartService
			.removeFromCart(cartToDelete.id)
			.then(() => {
				const remaining = _.filter(cartProducts, (prod) => {
					return prod.id != cartToDelete.id;
				});

				dispatch(setCart(remaining));
			})
			.catch((err) => {
				console.log(err);
			})
			.catch((err) => {
				console.log(err);
			});
	};
	const handleOrderCartChange = (query, checked) => {
		if (checked) {
			const exists = _.find(addedForOrder, function (d) {
				return d.id == query.id;
			});
			if (exists) {
				return;
			} else {
				setAddedForOrder((prev) => [...prev, query]);
			}
		} else {
			const remaining = _.filter(addedForOrder, (prod) => {
				return prod.id != query.id;
			});
			setAddedForOrder(remaining);
		}
	};

	const columns = [
		{
			field: "brand",
			headerName: "Brand",
			width: 200,
			renderCell: (params) => {
				console.log(params.row);
				return (
					<MKTypography variant='body2' color='dark'>
						{params.row?.ProductQuery?.Brand?.name}
					</MKTypography>
				);
			},
		},
		{
			field: "model",
			headerName: "Model",
			width: 250,

			renderCell: (params) => {
				return (
					<MKTypography variant='body2' color='dark'>
						{params.row?.ProductQuery?.Models?.name}
					</MKTypography>
				);
			},
		},
		{
			field: "product",
			headerName: "Product",
			width: 200,

			renderCell: (params) => {
				return (
					<MKTypography variant='body2' color='dark'>
						{params.row?.ProductQuery?.Products?.name}
					</MKTypography>
				);
			},
		},
		{
			field: "color",
			headerName: "Color",
			width: 150,

			renderCell: (params) => {
				return (
					<Stack
						direction={"row"}
						sx={{
							alignItems: "center",
							justifyContent: "center",
							display: "flex",
						}}
						gap={1}
					>
						<Box
							sx={{
								width: 14,
								height: 14,
								backgroundColor: params.row?.ProductQuery?.color?.hex,
							}}
						></Box>
						<MKTypography variant='body2' color='dark'>
							{params.row?.ProductQuery?.color?.name}
						</MKTypography>
					</Stack>
				);
			},
		},
		{
			field: "quality",
			headerName: "Quality",
			width: 150,

			renderCell: (params) => {
				return (
					<MKTypography variant='body2' color='dark'>
						{params.row?.ProductQuery.variantId
							? params.row?.ProductQuery?.Variant?.name
							: variatnt[params.row?.ProductQuery?.qualityVariant]}
					</MKTypography>
				);
			},
		},
		{
			field: "price",
			headerName: "Price",
			width: 150,

			renderCell: (params) => {
				const bestQ = params.row?.BestQuote.Response;
				let total = 0;
				if (bestQ) {
					const pricePerUnit = Number(bestQ.quotedPricePerUnit.amount);
					const ourMarkup = Number(bestQ.markup.amount);
					total = pricePerUnit + ourMarkup;
				}

				return (
					<MKTypography variant='body2' color='dark' fontWeight='bold'>
						{total
							? `${bestQ.quotedPricePerUnit?.currency}  ${total.toFixed(2)}`
							: "-:-"}
					</MKTypography>
				);
			},
		},
		{
			field: "quantity",
			headerName: "Qty",
			width: 150,

			renderCell: (params) => {
				return (
					<MKQuantity
						min={params.row.quantity}
						step={1}
						quantity={params.row.quantity}
						onChange={(newValue) => handleQuantityChange(newValue, params.row)}
					/>
				);
			},
		},
		{
			field: "actions",
			headerName: "Action",
			width: 150,

			renderCell: (params) => {
				return (
					<IconButton onClick={() => handleDelete(params.row)}>
						<RiDeleteBinFill color='#1E156A' />
					</IconButton>
				);
			},
		},
	];
	// const autosizeOptions = {
	// 	includeHeaders: true,
	// 	includeOutliers: true,
	// 	columns: [
	// 		"brand",
	// 		"model",
	// 		"product",
	// 		"color",
	// 		"quality",
	// 		"quantity",
	// 		"price",
	// 		"actions",
	// 	],
	// 	outliersFactor: 1.5,
	// 	expand: true,
	// };
	return (
		<Box style={styles.root}>
			<div style={{ width: "90%" }}>
				{cartProducts.length ? (
					<Grid container spacing={3}>
						<Grid item xs={12} sm={12}>
							<Stack gap={3} direction={"column"}>
								<Paper id='header' style={styles.header} elevation={0}>
									<Stack direction='row' style={styles.heading}>
										<MKTypography
											variant='body1'
											color='dark'
											fontWeight='bold'
										>
											My Cart&nbsp;&nbsp;
											<Chip
												label={
													<MKTypography
														variant='caption'
														color='dark'
														fontWeight='bold'
													>
														{cartProducts.length}
													</MKTypography>
												}
												sx={{ backgroundColor: "#FFF1F6" }}
												size='small'
											/>
										</MKTypography>
									</Stack>
								</Paper>
								<div style={{ width: "100%" }}>
									<div style={{ width: "100%" }}>
										<DataGrid
											columns={columns}
											rows={cartProducts}
											autoHeight
											autosizeOnMount
											apiRef={apiRef}
										/>
									</div>
								</div>
							</Stack>
						</Grid>
						<Grid item xs={12} sm={12}>
							<Paper id='summary' style={styles.summary} elevation={0}>
								<MKTypography variant='body1' color='dark' fontWeight='bold'>
									Order Summary
								</MKTypography>
								{addedForOrder.length ? (
									<Box sx={{ width: "100%", marginTop: 5 }}>
										{_.map(addedForOrder, (product) => {
											const pricePerUnit =
												product?.BestQuote?.Response?.quotedPricePerUnit
													.amount * Number(product?.ProductQuery.quantity);
											const ourMarkup =
												product?.BestQuote?.Response?.markup.amount *
												Number(product?.ProductQuery.quantity);
											const total = Number(pricePerUnit) + Number(ourMarkup);
											return (
												<Grid container spacing={1}>
													<Grid item xs={4} sm={2}>
														<MKTypography
															variant='body2'
															color='dark'
															fontWeight='bold'
														>
															{product.ProductQuery.quantity} X
														</MKTypography>
													</Grid>
													<Grid item xs={4} sm={2}>
														<MKTypography variant='body2' color='dark'>
															{product.ProductQuery?.Products?.name}
														</MKTypography>
													</Grid>
													<Grid item xs={4} sm={2}>
														<MKTypography variant='body2' color='dark'>
															{product.ProductQuery?.Brand?.name}
														</MKTypography>
													</Grid>
													<Grid item xs={4} sm={2}>
														<MKTypography variant='body2' color='dark'>
															{product.ProductQuery?.Models?.name}
														</MKTypography>
													</Grid>
													<Grid item xs={4} sm={2}>
														<Stack direction='column'>
															<Stack
																direction={"row"}
																gap={1}
																sx={{ alignItems: "center", display: "flex" }}
															>
																<MKTypography variant='body2' color='dark'>
																	{product.ProductQuery?.color?.name}
																</MKTypography>
																<Box
																	sx={{
																		width: 14,
																		height: 14,
																		backgroundColor:
																			product.ProductQuery?.color?.hex,
																	}}
																/>
															</Stack>
														</Stack>
													</Grid>
													<Grid item xs={4} sm={2}>
														<MKTypography
															variant='body2'
															color='dark'
															fontWeight='bold'
														>
															{
																product?.BestQuote?.Response?.quotedPricePerUnit
																	?.currency
															}
															&nbsp;
															{total}
														</MKTypography>
													</Grid>
													<Grid item xs={12} sm={12}>
														<Divider />
													</Grid>
												</Grid>
											);
										})}

										<Stack gap={1}>
											<Stack
												direction='row'
												sx={{
													justifyContent: "space-between",
													display: "flex",
													alignItems: "center",
												}}
											>
												<MKTypography variant='body2' color='dark'>
													Sub total (Incl. Tax)
												</MKTypography>
												<MKTypography variant='body2' color='dark'>
													AED &nbsp;
													{totalOrder}
												</MKTypography>
											</Stack>
										</Stack>
										<Stack gap={1} sx={{ width: "100%" }}>
											<Divider sx={{ width: "100%" }} />
											<Stack
												direction='row'
												sx={{
													justifyContent: "space-between",
													display: "flex",
													alignItems: "center",
												}}
											>
												<MKTypography variant='body2' color='dark'>
													<TbTruckDelivery />
													&nbsp; Express Delivery
												</MKTypography>
												<MKTypography variant='body2' color='dark'>
													<SlLocationPin />
													&nbsp; {store.address1},{store.state}
												</MKTypography>
											</Stack>
											<Stack
												direction='row'
												sx={{
													justifyContent: "space-between",
													display: "flex",
													alignItems: "center",
												}}
											>
												<MKTypography variant='body2' color='dark'>
													Delivery Charges
												</MKTypography>
												<MKTypography variant='body2' color='dark'>
													AED &nbsp;
													{deliveryCharges}
												</MKTypography>
											</Stack>
											<Stack
												direction='row'
												sx={{
													justifyContent: "space-between",
													display: "flex",
													alignItems: "center",
												}}
											>
												<MKTypography variant='body2' color='dark'>
													Cashback
												</MKTypography>
												<MKTypography variant='body2' color='dark'>
													AED &nbsp;
													{Number(cashback).toFixed(2)}
												</MKTypography>
											</Stack>
											<Divider />
											<Stack
												direction='row'
												sx={{
													justifyContent: "space-between",
													display: "flex",
													alignItems: "center",
												}}
											>
												<MKTypography
													variant='body2'
													color='dark'
													fontWeight='bold'
												>
													Order Total (Incl. Tax)
												</MKTypography>
												<MKTypography
													variant='body2'
													color='dark'
													fontWeight='bold'
												>
													AED &nbsp;{" "}
													{(
														totalOrder +
														Number(deliveryCharges) -
														cashback
													).toFixed(2)}
												</MKTypography>
											</Stack>
										</Stack>
										<Divider />
										<Stack
											sx={{
												width: "100%",
												mt: 5,
											}}
										>
											<MKInput
												label='Coupon Code'
												placeholder='Do you have any coupon code?'
											/>
										</Stack>
										<Stack
											direction='row'
											sx={{
												width: "100%",
												mt: 2,
												justifyContent: "center",
												display: "flex",
											}}
										>
											<MKButton
												size='large'
												variant='contained'
												color='primary'
												onClick={placeOrder}
												sx={{ width: 350 }}
											>
												Submit Order
											</MKButton>
										</Stack>
									</Box>
								) : (
									<></>
								)}
							</Paper>
						</Grid>
					</Grid>
				) : (
					<Container>
						<Paper
							sx={{
								alignItems: "center",
								justifyContent: "center",
								display: "flex",
								flexDirection: "row",
								backgroundColor: "#fafafa",
								minHeight: "60vh",
							}}
							elevation={0}
						>
							<MKTypography variant='h2' color='dark'>
								<FaWineGlassEmpty size={56} /> &nbsp;&nbsp;Your cart is empty
							</MKTypography>
						</Paper>
					</Container>
				)}
			</div>
		</Box>
	);
};
export default StoreCart;

const variatnt = {
	ORIGINAL: "Original",
	FIRST_COPY: "Other",
};
const Product = (props) => {
	const [query, setQuery] = useState(props.product?.ProductQuery);
	const [cart, setCart] = useState(props.product);
	const [bestQuote, setBestQuote] = useState(
		props.product?.BestQuote?.Response
	);
	const [amount, setAmount] = useState(0);
	const handleDelete = () => {
		props.onDelete(cart);
	};
	const handleChecked = (e) => {
		props.onOrderCartChange(cart, e.target.checked);
	};

	useEffect(() => {
		const pricePerUnit =
			props.product?.BestQuote?.Response?.quotedPricePerUnit.amount;
		const ourMarkup = props.product?.BestQuote?.Response?.markup.amount;
		const total = Number(pricePerUnit) + Number(ourMarkup);
		setAmount(total);
	}, []);
	const handleQuantityChange = (newValue) => {
		if (props.onQuatyChange) {
			props.onQuatyChange(newValue);
		}
	};
	return (
		<Container style={styles.innerContainerRoot}>
			<Stack
				gap={2}
				direction='row'
				sx={{
					width: "100%",
					alignItems: "center",
					justifyContent: "space-between",
					display: "flex",
				}}
			>
				<MKTypography variant='body2' color='dark'>
					<Checkbox onChange={handleChecked} defaultChecked />{" "}
					{query?.Products?.name}
				</MKTypography>
				<Chip
					icon={<MdOutlineSupervisedUserCircle fontSize={18} />}
					label={
						<MKTypography variant='caption' color='dark'>
							{query?.QueryGroup?.name}
						</MKTypography>
					}
				/>
			</Stack>

			<Grid container spacing={2}>
				<Grid item xs={2} sm={2}>
					<Stack direction='column'>
						<MKTypography variant='caption' color='dark'>
							Brand
						</MKTypography>
						<MKTypography variant='caption' color='dark'>
							{query?.Brand?.name}
						</MKTypography>
					</Stack>
				</Grid>
				<Grid item xs={2} sm={2}>
					<Stack direction='column'>
						<MKTypography variant='caption' color='dark'>
							Model
						</MKTypography>
						<MKTypography variant='caption' color='dark'>
							{query?.Models?.name}
						</MKTypography>
					</Stack>
				</Grid>
				<Grid item xs={2} sm={2}>
					<Stack direction='column'>
						<MKTypography variant='caption' color='dark'>
							Colour
						</MKTypography>
						<Stack direction={"row"} gap={1}>
							<MKTypography variant='caption' color='dark'>
								{query?.color?.name}
							</MKTypography>
							<Box
								sx={{
									width: 14,
									height: 14,
									backgroundColor: query?.color?.hex,
								}}
							/>
						</Stack>
					</Stack>
				</Grid>
				<Grid item xs={2} sm={2}>
					<Stack direction='column'>
						<MKTypography variant='caption' color='dark'>
							Quality
						</MKTypography>
						<MKTypography variant='caption' color='dark'>
							{query?.variantId
								? query?.Variant?.name
								: variatnt[query?.qualityVariant]}
						</MKTypography>
					</Stack>
				</Grid>

				<Grid item xs={12} sm={12}>
					<Stack
						direction='row'
						gap={3}
						sx={{
							alignItems: "center",
							justifyContent: "flex-end",
							display: "flex",
						}}
					>
						<Stack
							direction='row'
							gap={3}
							sx={{
								alignItems: "center",
								justifyContent: "space-between",
								display: "flex",
							}}
						>
							<MKTypography variant='body2' color='dark'>
								{bestQuote?.quotedPricePerUnit?.currency} &nbsp;
								{amount}
							</MKTypography>
							<IconButton onClick={handleDelete}>
								<RiDeleteBinFill color='#1E156A' />
							</IconButton>
							{/* <MKTypography variant='body2' color='dark'>
								{query?.quantity}
							</MKTypography> */}
							<MKQuantity
								min={query?.quantity}
								step={1}
								quantity={query?.quantity}
								onChange={handleQuantityChange}
							/>
						</Stack>
					</Stack>
				</Grid>
			</Grid>
		</Container>
	);
};
const styles = {
	innerContainerRoot: {
		width: "100%",
		backgroundColor: "#FFFEFE",
		padding: 20,
	},
	root: {
		padding: 15,
		width: "100%",
		alignItems: "center",
		justifyContent: "center",
		display: "flex",
		flexDirection: "column",
		backgroundColor: "#fafafa",
		minHeight: "100vh",
		mt: 10,
	},
	heading: {
		width: "100%",
		alignItems: "center",
		justifyContent: "space-between",
		display: "flex",
		padding: 10,
	},
	summary: {
		minHeight: "60vh",
		width: "100%",
		display: "flex",
		flexDirection: "column",
		alignItems: "fle-start",
		justifyContent: "flex-start",
		backgroundColor: "#FDFEF8",
		border: "1px solid #FFF1F6",
		padding: 20,
	},
	header: {
		height: 80,
		width: "100%",
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-evenly",
		backgroundColor: "#FDFEF8",
		border: "1px solid #FFF1F6",
	},
};
