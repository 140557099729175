import { Avatar, Box, Container, Divider, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/images/jumbuda.png";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import { FcBusiness } from "react-icons/fc";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "features/userSlice";
import { login } from "features/userSlice";
import Partners from "../../api/partners";
import stores from "api/stores";
import merchants from "api/merchants";
import wallet from "api/wallet";
import { setMerchant } from "features/merchantSlice";
import { setMerchantBrands } from "features/merchantSlice";
import { setStore } from "features/storeSlice";
import { updateWallet } from "features/walletSlice";
import { setMerchantProfile } from "features/merchantSlice";

const Selector = (props) => {
	const location = useLocation();
	const [users, setUsers] = useState({});
	const [memberships, setMemberships] = useState([]);
	const partnerService = new Partners();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	useEffect(() => {
		if (location.state) {
			setMemberships(location.state.memberships);
			setUsers(location.state.user);
			console.log(location.state.user, "user ds");
		}
	});
	const onRoleSelect = (membership) => {
		if (membership === "merchant") {
			let u = { ...users };
			u.user.userType = "MERCHANT_ADMIN";
			setUsers(u);
			dispatch(setUser(u));
			dispatch(login());
			loginAs(u);
			window.electronAPI?.sendLoginSuccess(u);
		} else {
			let u = { ...users };
			u.user.userType = "STORE_ADMIN";
			setUsers(u);
			dispatch(setUser(u));
			dispatch(login());
			loginAs(u);
			window.electronAPI?.sendLoginSuccess(u);
		}
	};

	const loginAs = (user) => {
		console.log("USER", user);
		if (user.user.userType == "DELIVERY_PARTNER") {
			partnerService.fetch_partner_data(user.user.id).then((result) => {
				dispatch(updateWallet(result.wallet));
			});
			navigate("/settlements/store");
		} else if (user.user.userType == "STORE_ADMIN") {
			stores
				.getStoreDataByUser(user?.user?.id)
				.then((data) => {
					dispatch(setStore(data));
					wallet
						.fetchWalletForStore(data.id)
						.then((result) => {
							dispatch(updateWallet(result));
							navigate("/query");
						})
						.catch((err) => console.log(err));
				})
				.catch((error) => {});
		} else if (user.user.userType == "MERCHANT_ADMIN") {
			merchants
				.findMerchantByAdminId(user?.user?.id)
				.then((ms) => {
					const mers = ms.merchants;

					wallet.fetchWalletForMerchant(mers[0].id).then((result) => {
						dispatch(updateWallet(result));
					});
					dispatch(setMerchant(mers[0]));
					if (mers.length) {
						console.log(mers[0]);
						dispatch(setMerchantBrands(mers[0].MerchantBrands));
						merchants
							.getMerchantProfile(mers[0].id)
							.then((profiles) => {
								console.log(profiles);
								if (profiles.length) {
									const profile = profiles[0];
									dispatch(setMerchantProfile(profile));

									navigate("/m-dashboard");
								} else {
									navigate("/merchant-profile");
								}
							})
							.catch((err) => {
								console.log(err);
							});
					} else {
						navigate("/query");
					}
				})
				.catch((err) => {
					console.log(err);
				});
		} else {
			navigate("/query");
		}
	};

	return (
		<Box
			sx={{
				width: "100%",
				height: "80vh",
				alignItems: "center",
				justifyContent: "center",
				display: "flex",
			}}
		>
			<Container
				sx={{
					alignItems: "center",
					justifyContent: "center",
					display: "flex",
				}}
			>
				<Box
					sx={{
						padding: 3,
						border: "1px dotted #444444",
						width: 400,
						alignItems: "center",
						justifyContent: "center",
						display: "flex",
						borderRadius: 3,
					}}
				>
					<Stack
						gap={2}
						sx={{
							alignItems: "center",
							justifyContent: "center",
							display: "flex",
							width: "100%    ",
						}}
					>
						<Stack
							direction={"row"}
							gap={2}
							sx={{ alignItems: "center", display: "flex" }}
						>
							<Avatar src={logo} />
							<MKTypography color='dark' variant='body1'>
								Jumbuda.
							</MKTypography>
						</Stack>
						<Stack
							gap={2}
							sx={{
								alignItems: "center",
								justifyContent: "center",
								display: "flex",
								width: "100%    ",
							}}
						>
							<MKTypography color='dark' variant='h4'>
								Login As
							</MKTypography>
							{memberships?.merchant && (
								<MKButton
									variant='text'
									color='dark'
									onClick={() => onRoleSelect("merchant")}
								>
									<FcBusiness /> &nbsp;Merchant
								</MKButton>
							)}

							{memberships?.store && (
								<MKButton
									variant='text'
									color='dark'
									onClick={() => onRoleSelect("store")}
								>
									<FcBusiness /> &nbsp;Store
								</MKButton>
							)}
						</Stack>
					</Stack>
				</Box>
			</Container>
		</Box>
	);
};
export default Selector;
