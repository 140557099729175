import { createSlice } from "@reduxjs/toolkit";

export const storeSlice = createSlice({
	name: "stores",
	initialState: {
		store: {},
		queries: [],
		count: 0,
	},
	reducers: {
		setStore: (state, action) => {
			state.store = action.payload;
		},
		removeStore: (state, action) => {
			state.store = {};
			state.queries = [];
			state.count = 0;
		},
		setStoreQueries: (state, action) => {
			state.queries = action.payload;
		},
		addStoreCount: (state, action) => {
			state.count = state.count + action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const { setStore, removeStore, setStoreQueries, addStoreCount } =
	storeSlice.actions;
export default storeSlice.reducer;
