import {
	Box,
	Container,
	Divider,
	Grid,
	IconButton,
	Menu,
	MenuItem,
	Pagination,
	Stack,
} from "@mui/material";
import orderService from "../../api/orders";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import MKTypography from "components/MKTypography";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import _ from "lodash";
import dayjs from "dayjs";
import { Filter } from "@mui/icons-material";
import { CiFilter } from "react-icons/ci";
import MKButton from "components/MKButton";
import MKLoader from "components/MKLoader";
const lineItemStatus = {
	returnAccepted_for_refund: "Refund Accepted",
	returnAccepted_for_replacement: "Replacement Pending",
	pending: "New Order",
	ready_for_pickup: "Pending Pickup",
	returnRejected: "Return Rejected",
};

const paymentStatus = {
	UNPAID: { color: "error", label: "Pending Payment" },
	PARTLY_PAID: { color: "warning", label: "Patly Payed" },
	PAID_IN_FULL: { color: "success", label: "Settled" },
};

const Settlements = () => {
	const [page, setPage] = useState(1);
	const [count, setCount] = useState(10);
	const [totalCount, setTotalCount] = useState(0);
	const [fromDate, setFromDate] = useState(dayjs(new Date()));
	const [toDate, setToDate] = useState(dayjs(new Date()));
	const [myOrders, setOrders] = useState([]);
	const [loading, setLoading] = useState(false);
	const merchant = useSelector((state) => state.merchants.merchant);
	const [selectedPaymentStatus, setSelectedPaymentStatus] = useState("UNPAID");
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	useEffect(() => {
		const currentDate = new Date();
		// Subtract 7 days from the current date
		const dateMinusSevenDays = new Date(currentDate);
		dateMinusSevenDays.setDate(currentDate.getDate() - 7);
		setFromDate(dayjs(dateMinusSevenDays));
		orderService
			.fetchOrdersBetweenDates(
				merchant?.id,
				page,
				count,
				dateMinusSevenDays.toISOString(),
				toDate.toISOString(),
				selectedPaymentStatus
			)
			.then((results) => {
				const { totalCount, orders } = results;
				setOrders(orders);
				setTotalCount(totalCount);
				console.log(orders);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	useEffect(() => {
		orderService
			.fetchOrdersBetweenDates(
				merchant?.id,

				page,
				count,
				fromDate.toISOString(),
				toDate.toISOString(),
				selectedPaymentStatus
			)
			.then((results) => {
				const { totalCount, orders } = results;
				setOrders(orders);
				setTotalCount(totalCount);
				console.log(orders);
			})
			.catch((err) => {
				console.log(err);
			});
	}, [page, fromDate, toDate, selectedPaymentStatus]);

	return (
		<Container>
			{loading ? (
				<MKLoader size={100} />
			) : (
				<>
					<Grid
						container
						spacing={2}
						sx={{
							mt: 10,
						}}
					>
						<Grid item xs={2}>
							<MKTypography variant='h4' color='dark'>
								Settlement
							</MKTypography>
						</Grid>
						<Grid
							item
							xs={2}
							sm={2}
							sx={{
								alignItems: "center",
								justifyContent: "center",
								display: "flex",
							}}
						>
							<Stack
								spacing={2}
								sx={{
									alignItems: "center",
									justifyContent: "center",
									display: "flex",
								}}
							>
								<MKTypography color='dark' variant='caption'>
									Page: {page}
								</MKTypography>
								<Pagination
									variant='outlined'
									color='primary'
									count={Math.ceil(totalCount / count)}
									page={page}
									onChange={(e, newVal) => setPage(newVal)}
								/>
							</Stack>
						</Grid>

						<Grid
							item
							xs={6}
							sx={{
								alignItems: "center",
								justifyContent: "flex-end",
								display: "flex",
							}}
						>
							<Stack gap={2} direction='row'>
								<Stack direction='column'>
									<MKTypography variant='caption' color='dark'>
										From Date
									</MKTypography>
									<LocalizationProvider dateAdapter={AdapterDayjs}>
										<DemoContainer components={["DatePicker"]}>
											<DatePicker
												value={fromDate}
												onChange={(newValue) => {
													setPage(1);
													setFromDate(newValue);
												}}
											/>
										</DemoContainer>
									</LocalizationProvider>
								</Stack>
								<Stack direction='column'>
									<MKTypography variant='caption' color='dark'>
										To Date
									</MKTypography>
									<LocalizationProvider dateAdapter={AdapterDayjs}>
										<DemoContainer components={["DatePicker"]}>
											<DatePicker
												value={toDate}
												onChange={(newValue) => {
													setPage(1);
													setToDate(newValue);
												}}
											/>
										</DemoContainer>
									</LocalizationProvider>
								</Stack>
							</Stack>
						</Grid>
						<Grid
							item
							xs={2}
							sm={2}
							sx={{
								alignItems: "flex-end",
								justifyContent: "center",
								display: "flex",
							}}
						>
							<MKButton
								aria-controls={open ? "basic-menu" : undefined}
								aria-haspopup='true'
								aria-expanded={open ? "true" : undefined}
								onClick={handleClick}
								variant='contained'
								color='dark'
								size='small'
							>
								<MKTypography color='white' variant='caption'>
									Status :{selectedPaymentStatus}
								</MKTypography>
								&nbsp;&nbsp;
								<CiFilter />
							</MKButton>

							<Menu
								id='basic-menu'
								anchorEl={anchorEl}
								open={open}
								onClose={handleClose}
								MenuListProps={{
									"aria-labelledby": "basic-button",
								}}
							>
								<MenuItem
									onClick={() => {
										setSelectedPaymentStatus("UNPAID");
										handleClose();
									}}
								>
									Unpaid
								</MenuItem>
								<MenuItem
									onClick={() => {
										setSelectedPaymentStatus("PARTLY_PAID");
										handleClose();
									}}
								>
									Partially paid
								</MenuItem>
								<MenuItem
									onClick={() => {
										setSelectedPaymentStatus("PAID_IN_FULL");
										handleClose();
									}}
								>
									Settled
								</MenuItem>
							</Menu>
						</Grid>
					</Grid>

					{_.map(myOrders, (order) => {
						return (
							<Grid
								container
								spacing={2}
								sx={{ padding: 1, backgroundColor: "#fafafa", mt: 2 }}
							>
								<Grid item sm={2} xs={6}>
									<Stack direction='column' gap={1}>
										<MKTypography
											variant='caption'
											color='dark'
											fontWeight='bold'
										>
											Date
										</MKTypography>
										<MKTypography
											variant='caption'
											color='dark'
											sx={{ minWidth: 100 }}
										>
											{new Date(order?.createdAt).toLocaleDateString("en-US", {
												year: "numeric",
												month: "short",
												day: "numeric",
											})}
										</MKTypography>
									</Stack>
								</Grid>
								<Grid item sm={2} xs={6}>
									<Stack direction='column' gap={1}>
										<MKTypography
											variant='caption'
											color='dark'
											fontWeight='bold'
										>
											OrderId
										</MKTypography>
										<MKTypography
											variant='caption'
											color='dark'
											sx={{ minWidth: 100 }}
										>
											{order?.id}
										</MKTypography>
									</Stack>
								</Grid>

								<Grid item sm={2} xs={2}>
									<Stack direction='column' gap={1}>
										<MKTypography
											variant='caption'
											color='dark'
											fontWeight='bold'
										>
											Color
										</MKTypography>
										<Stack
											direction={"row"}
											sx={{
												alignItems: "center",
												justifyContent: "flex-start",
												display: "flex",
											}}
											gap={1}
										>
											<Box
												sx={{
													width: 14,
													height: 14,
													backgroundColor: order?.ProductQuery?.color.hex,
												}}
											></Box>
											<MKTypography variant='caption' color='dark'>
												{order?.ProductQuery?.color.name}
											</MKTypography>
										</Stack>
									</Stack>
								</Grid>
								<Grid item xs={12} sm={4}>
									<Stack direction='column' gap={1}>
										<MKTypography
											variant='caption'
											color='dark'
											fontWeight='bold'
										>
											Product Details
										</MKTypography>
										<MKTypography variant='caption' color='dark'>
											{order.ProductQuery?.Brand.name} &nbsp;
											{order.ProductQuery?.Models.name} &nbsp;
											{order.Product.name}&nbsp;
											<strong>
												({order?.Variant ? order.Variant?.name : "Original"})
												&nbsp;X&nbsp;{order.quantity}
											</strong>
										</MKTypography>
									</Stack>
								</Grid>

								<Grid item xs={6} sm={2}>
									<Stack direction='column' gap={1}>
										<MKTypography
											variant='caption'
											color='dark'
											fontWeight='bold'
										>
											Order Status
										</MKTypography>
										<MKTypography variant='caption' color='black' size='small'>
											{lineItemStatus[order.lineItemStatus]}
										</MKTypography>
									</Stack>
								</Grid>

								<Grid
									container
									spacing={2}
									sx={{
										mt: 2,
										alignItems: "center",
										justifyContent: "center",
										display: "flex",
									}}
								>
									<Grid item xs={12} sm={8}>
										{order.ProductQuery.queryText && (
											<MKTypography variant='body2' color='error'>
												Remarks: {order.ProductQuery.queryText}
											</MKTypography>
										)}
									</Grid>
									<Grid itemxs={6} sm={2}>
										<Stack direction='column' gap={1}>
											<MKTypography
												variant='caption'
												color='dark'
												fontWeight='bold'
											>
												Total
											</MKTypography>
											<MKTypography variant='caption' color='dark'>
												AED&nbsp;
												{order.totalPrice.amount - order.markup}
											</MKTypography>
										</Stack>
									</Grid>
									<Grid item xs={6} sm={2} sx={{ mt: -2 }}>
										<Stack direction='column' gap={1}>
											<MKTypography
												variant='caption'
												color='dark'
												fontWeight='bold'
											>
												Payment Status
											</MKTypography>
											<MKTypography
												variant='caption'
												color={paymentStatus[order.paymentStatus]?.color}
												fontWeight='bold'
											>
												{paymentStatus[order.paymentStatus]?.label}
											</MKTypography>
										</Stack>
									</Grid>
								</Grid>

								<Grid item xs={12} sm={12}>
									<Divider sx={{ width: "100%" }} />
								</Grid>
							</Grid>
						);
					})}
				</>
			)}
		</Container>
	);
};
export default Settlements;
