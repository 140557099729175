import React, { useState, useEffect } from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import { fontFamily, fontWeight, styled } from "@mui/system";
import logo from "../../assets/images/jumbuda.png";
import Header from "./components/Header";
import HeroSection from "./components/HeroSection";
import img from "../../assets/images/hero.png";
import "./landing.css";
const ParallaxContainer = styled(Box)(() => ({
	height: "100vh",
	overflowX: "hidden",
	overflowY: "scroll",
	perspective: "1px",
	perspectiveOrigin: "0 0",
	position: "relative",
}));

const ParallaxBackground = styled(Box, {
	shouldForwardProp: (prop) => prop !== "$scrollPosition",
})(({ $scrollPosition }) => ({
	position: "absolute",
	top: 0,
	right: 0,
	bottom: 0,
	left: 0,
	backgroundImage: `url(${logo})`,
	backgroundSize: "cover",
	backgroundPosition: "center",
	transform: `translateY(${$scrollPosition * 0.5}px)`,
	transition: "transform 0.1s ease-out",
}));

const Content = styled(Box)(({ theme }) => ({
	position: "relative",
	padding: theme.spacing(4),
	backgroundColor: "rgba(255, 255, 255, 0.8)",
	borderRadius: theme.shape.borderRadius,
	marginBottom: theme.spacing(4),
}));

export default function Home() {
	const [scrollPosition, setScrollPosition] = useState(0);

	const handleScroll = (event) => {
		const { scrollTop } = event.currentTarget;
		setScrollPosition(scrollTop);
	};

	useEffect(() => {
		const container = document.getElementById("parallax-container");
		if (container) {
			container.addEventListener("scroll", handleScroll);
		}
		return () => {
			if (container) {
				container.removeEventListener("scroll", handleScroll);
			}
		};
	}, []);

	return (
		<Box sx={{ minHeight: "100vh", backgroundColor: "#ffffff" }}>
			{/* <Header /> */}
			<div
				className='dottedBackgroundBox'
				style={{
					padding: 10,
				}}
			>
				<Box sx={{ marginTop: 20 }}>
					<Grid container spacing={1}>
						<Grid item xs={12} sm={6}>
							<Typography variant='h2' sx={styles.title}>
								Boost Your Service Business,
							</Typography>
							<Typography variant='h2' sx={styles.title}>
								with AI
								<span style={{ ...styles.title, color: "red" }}>.</span>
							</Typography>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Box sx={styles.bg}>
								<div style={{ width: "100%", height: "600px" }}>
									<img src={img} width='100%' height='80%' />
								</div>
							</Box>
						</Grid>
					</Grid>
				</Box>
			</div>
		</Box>
	);
}
const styles = {
	title: {
		fontFamily: "Lato",
		fontWeight: "bold",
		textAlign: "center",
		color: "#121212",
		fontSize: 80,
	},
	bg: {},
};
