import React, { useEffect, useState } from "react";

const MKCountdown = ({ createdAt, onExpiry }) => {
	const [countdown, setCountdown] = useState("");

	useEffect(() => {
		const startCountdown = () => {
			const totalMilliseconds = 300 * 60 * 1000;
			const endTime = new Date(createdAt).getTime() + totalMilliseconds;

			const countdownInterval = setInterval(() => {
				const remainingTime = endTime - Date.now();
				if (remainingTime <= 0) {
					clearInterval(countdownInterval);
					setCountdown("Expired");
					onExpiry();
				} else {
					const minutes = Math.floor(remainingTime / (60 * 1000));
					const seconds = Math.floor((remainingTime % (60 * 1000)) / 1000);
					setCountdown(`${minutes} : ${seconds}`);
				}
			}, 1000);

			return () => clearInterval(countdownInterval);
		};

		startCountdown();
	}, [createdAt, onExpiry]);

	return <div>{countdown}</div>;
};

export default MKCountdown;
