import { createSlice } from "@reduxjs/toolkit";

export const productSlice = createSlice({
	name: "products",
	initialState: {
		count: 0,
		products: [],
	},
	reducers: {
		setProducts: (state, action) => {
			state.products = action.payload;
		},
		setProductsCount: (state, action) => {
			state.count = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const { setProducts, setProductsCount } = productSlice.actions;
export default productSlice.reducer;
