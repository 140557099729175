import { Add } from "@mui/icons-material";
import {
	AppBar,
	Box,
	Container,
	Divider,
	Grid,
	Paper,
	Stack,
	Tab,
	Tabs,
	Typography,
} from "@mui/material";
import MKBreadCrumbs from "components/MKBreadCrumbs";
import MKButton from "components/MKButton";
import MKDataCard from "components/MKDataCard";
import MKSplitButton from "components/MKSplitButton";
import React, { useEffect, useState } from "react";
import { CiCircleChevRight, CiExport } from "react-icons/ci";
import { GoTasklist } from "react-icons/go";
import { FaArrowTrendDown } from "react-icons/fa6";
import MKTypography from "components/MKTypography";
import { SiGoogleanalytics } from "react-icons/si";
import MKProgress from "components/MKProgress";
import { FaArrowTrendUp } from "react-icons/fa6";
import { PieChart } from "@mui/x-charts/PieChart";
import { BarChart } from "@mui/x-charts";
import MerchantOrders from "./MyOrders";
import MyReturnedProducts from "./MyReturnedProducts";
const breadcrumb = [
	{ id: 1, name: "Home", link: "/m-dashboard" },
	{ id: 2, name: "Dashboard", link: "" },
];
const uData = [4000, 3000, 2000, 2780, 1890, 2390];
const pData = [2400, 1398, 9800, 3908, 4800, 3800];
const xLabels = ["Jan", "Feb", "Mar", "Apr", "May", "June"];

const MerchantDashboard = (props) => {
	const [activeTab, setActiveTab] = useState(0);
	const handleTabType = (event, newValue) => setActiveTab(newValue);
	useEffect(() => {
		let params = new URL(document.location.toString()).searchParams;
		let name = params.get("tab");
		setActiveTab(name);
	}, []);
	return (
		<Box style={styles.root}>
			<Container style={styles.container}>
				<Stack gap={2}>
					<MKBreadCrumbs crumbs={breadcrumb} />
					<Box sx={styles.topRow}>
						<Stack sx={{ width: 250 }}>
							<AppBar position='static'>
								<Tabs value={activeTab} onChange={handleTabType}>
									<Tab label='Overview' />
									<Tab label='Orders' />
									<Tab label='Returns' />
								</Tabs>
							</AppBar>
						</Stack>
						<Stack gap={1} direction={"row"}>
							<MKSplitButton />
							<MKButton variant='contained' color='dark'>
								<CiExport />
								&nbsp; Export
							</MKButton>
						</Stack>
					</Box>
					{activeTab == 1 ? (
						<MerchantOrders />
					) : activeTab == 2 ? (
						<MyReturnedProducts />
					) : (
						<Grid container spacing={2}>
							<Grid item xs={12} sm={4}>
								<MKDataCard
									title='Queries Tasks'
									icon={<CiCircleChevRight />}
									variant='simple'
									line1={"92%"}
									progress={90}
									hint='Please repond to Queries'
									endIcon={<GoTasklist fontSize={40} />}
								/>
							</Grid>
							<Grid item xs={12} sm={4}>
								<MKDataCard
									title='Sales Revenue'
									icon={<CiCircleChevRight />}
									variant='two-col'
									line1={"AED 12.5k"}
									textIcon={<FaArrowTrendDown color='#F2727E' />}
									line2={"Your sales is down by 10%"}
									endIcon={<SiGoogleanalytics fontSize={40} />}
								/>
							</Grid>
							<Grid item xs={12} sm={4}>
								<MKDataCard
									title='Conversion Rates'
									icon={<CiCircleChevRight />}
									variant='custom'
								>
									<Stack direction={"row"} gap={1}>
										<MKProgress
											color='success'
											value={100}
											sx={{ width: "70%" }}
										/>
										<MKProgress
											color='warning'
											value={100}
											sx={{ width: "30%" }}
										/>
									</Stack>
									<Stack
										direction={"row"}
										gap={1}
										sx={{ alignItems: "center", display: "flex" }}
									>
										<Box
											sx={{
												height: 20,
												width: "5px",
												backgroundColor: "#4baf51",
												borderRadius: 2,
											}}
										></Box>
										<MKTypography
											variant='body1'
											color='dark'
											fontWeight='bold'
										>
											75.3%
										</MKTypography>
										&nbsp;&nbsp;
										<MKTypography
											variant='caption'
											color='dark'
											fontWeight='bold'
										>
											<FaArrowTrendUp /> &nbsp; 4485
										</MKTypography>
										<MKTypography variant='caption' color='dark'>
											enquiries
										</MKTypography>
									</Stack>

									<Stack
										direction={"row"}
										gap={1}
										sx={{
											alignItems: "center",
											display: "flex",
										}}
									>
										<Box
											sx={{
												height: 20,
												width: "5px",
												backgroundColor: "#fb8c00",
												borderRadius: 2,
											}}
										></Box>
										<MKTypography
											variant='body1'
											color='dark'
											fontWeight='bold'
										>
											24.7%
										</MKTypography>
										&nbsp;&nbsp;
										<MKTypography
											variant='caption'
											color='dark'
											fontWeight='bold'
										>
											<FaArrowTrendUp /> &nbsp; 2434
										</MKTypography>
										<MKTypography variant='caption' color='dark'>
											orders
										</MKTypography>
									</Stack>
								</MKDataCard>
							</Grid>
							<Grid item xs={8}>
								<Paper sx={{ width: "100%", height: 350 }}>
									<BarChart
										// width={650}

										series={[
											{ data: pData, label: "Queries", id: "pvId" },
											{ data: uData, label: "Orders", id: "uvId" },
										]}
										xAxis={[{ data: xLabels, scaleType: "band" }]}
									/>
								</Paper>
							</Grid>
							<Grid item xs={4}>
								<Paper sx={{ width: "100%", height: 350 }}>
									<PieChart
										series={[
											{
												data: [
													{
														id: 0,
														value: 450,
														label: "450",
													},
													{ id: 1, value: 215, label: "215" },
													{ id: 2, value: 150, label: "150" },
													{ id: 3, value: 98, label: "98" },
												],
												innerRadius: 30,
												outerRadius: 100,
												paddingAngle: 5,
												cornerRadius: 5,
												startAngle: -90,
												endAngle: 180,

												cx: 150,
												cy: 150,
											},
										]}
									/>
								</Paper>
							</Grid>
							<Grid item xs={12} sm={12}>
								<Paper sx={{ width: "100%", height: "30vh" }}></Paper>
							</Grid>
						</Grid>
					)}
				</Stack>
			</Container>
		</Box>
	);
};
export default MerchantDashboard;

const styles = {
	root: {
		width: "100%",
		alignItems: "center",
		justifyContent: "center",
		display: "flex",
	},
	container: {
		width: "100%",
		flexDirection: "column",
		display: "flex",
		padding: 10,
	},
	topRow: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		flexDirection: "row",
	},
};
