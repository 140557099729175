import { Add, Info } from "@mui/icons-material";
import { Container, Grid, Paper, Stack } from "@mui/material";
import MKButton from "components/MKButton";
import MKInput from "components/MKInput";
import MKTypography from "components/MKTypography";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "assets/images/jumbuda.png";
import auth from "../../api/auth";
import { useDispatch } from "react-redux";
import { login } from "features/userSlice";
import { setUser } from "features/userSlice";
const OTPVerification = (props) => {
	const [email, setEmail] = useState("");
	const [otp, setOtp] = useState("");
	const [error, setError] = useState("");
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	useEffect(() => {
		const mail = location.state?.email;
		if (mail) setEmail(mail);
	}, []);
	const handleVerify = () => {
		auth
			.verify({ email: email, token: otp })
			.then((user) => {
				dispatch(login());
				dispatch(setUser(user));
				if (user.userType == "STORE_ADMIN") {
					navigate("/store-profile");
				} else if (user.userType == "MERCHANT_ADMIN") {
					navigate("/merchant-profile");
				} else {
					navigate("/query");
				}
			})
			.catch((error) => {
				setError("OTP Verification Failed. Please enter correct OTP");
			});
	};
	return (
		<Container
			sx={{
				alignItems: "center",
				justifyContent: "center",
				display: "flex",
				minHeight: "70vh",
			}}
		>
			<Stack
				sx={{
					alignItems: "center",
					justifyContent: "center",
					display: "flex",
					p: 5,
					backgroundColor: "#fafafa",
				}}
				gap={3}
			>
				<div style={styles.logo}>
					<img src={logo} width='100%' height='100%' />
				</div>
				<Paper elevation={0} sx={styles.paper}>
					<MKTypography color='dark' variant='body2'>
						<Info />
						&nbsp; You might have recieved an email at{" "}
						{email?.slice(0, 4) + "*****@" + email?.split("@")[1]} .Please enter
						the activation code below.
					</MKTypography>
				</Paper>
				<Grid container spacing={1}>
					<Grid item xs={12} sm={3}></Grid>
					<Grid item xs={12} sm={6}>
						<MKInput
							sx={{ width: "100%" }}
							onChange={(e) => setOtp(e.target.value)}
						/>
					</Grid>
					<Grid item xs={12} sm={3}></Grid>
				</Grid>
				<Grid container spacing={1}>
					<Grid item xs={12} sm={3}></Grid>
					<Grid item xs={12} sm={6}>
						<MKButton
							variant='contained'
							color='primary'
							fullWidth
							size='large'
							onClick={handleVerify}
						>
							<Add /> &nbsp;Submit
						</MKButton>
					</Grid>
					<Grid item xs={12} sm={3}></Grid>
				</Grid>
			</Stack>
		</Container>
	);
};
export default OTPVerification;
const styles = {
	paper: {
		padding: 1,
		backgroundColor: "#F6F9FF",
		width: "100%",
		minHeight: "5vh",
	},
	logo: {
		width: 80,
		height: 80,
	},
};
