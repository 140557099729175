import { configureStore, combineReducers } from "@reduxjs/toolkit";
import brandReducer from "../features/brandSlice";
import modelReducer from "../features/modelSlice";
import productReducer from "../features/productSlice";
import variantReducer from "../features/productVariantSlice";
import queryGroupCartReducer from "../features/queryGroupCartSlice";
import userReducer from "../features/userSlice";
import locationReducer from "../features/locationSlice";
import storeReducer from "../features/storeSlice";
import merchantReducer from "../features/merchantSlice";
import notificationsReducer from "../features/notificationSlice";
import cartReducer from "../features/cartSlice";
import walletReducer from "../features/walletSlice";
import transitionsReducer from "../features/transitionsSlice";
import { persistStore, persistReducer } from "redux-persist";

import storage from "redux-persist/lib/storage";
const reducers = combineReducers({
	brands: brandReducer,
	models: modelReducer,
	products: productReducer,
	variants: variantReducer,
	queryGroupCart: queryGroupCartReducer,
	user: userReducer,
	locations: locationReducer,
	stores: storeReducer,
	merchants: merchantReducer,
	notifications: notificationsReducer,
	cart: cartReducer,
	wallet: walletReducer,
	transitions: transitionsReducer,
});

const persistConfig = {
	key: "jumbuda-web",
	storage,
};
const persistedReducer = persistReducer(persistConfig, reducers);
export const store = configureStore({
	reducer: persistedReducer,
});
