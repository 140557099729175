import { ArrowDropDown } from "@mui/icons-material";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Divider,
	Grid,
	Menu,
	MenuItem,
	Paper,
	Stack,
} from "@mui/material";
import orderService from "../../api/orders";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { IoReturnDownBack } from "react-icons/io5";
import { BiChevronDownCircle } from "react-icons/bi";
import { DataGrid } from "@mui/x-data-grid";

const menus = {
	completed: [
		{
			id: 1,
			label: "Return & Ask Replacement",
			value: "returned_for_replacement",
		},
		{ id: 2, label: "Return & Ask Refund", value: "returned_for_refund" },
	],
	pending: [
		{
			id: 1,
			label: "completed",
			value: "completed",
		},
	],
	ready_for_pickup: [
		{
			id: 1,
			label: "completed",
			value: "completed",
		},
	],
};

const statusMap = {
	ready_for_pickup: "Ready",
	completed: "Completed",
	pending: "Pending",
	returned_for_replacement: "Replacement Awaiting",
	returned_for_refund: "Refund Awaiting",
	returnAccepted_for_refund: "Refund",
	returnAccepted_for_replacement: "Replacement Accepted",
	returnRejected: "Return Rejected",
};
const MyOrderList = (props) => {
	const [list, setList] = useState(props.list);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [currenStatus, setCurrentStatus] = useState(null);
	const [newStatus, setNewStatus] = useState(null);
	const [dialogOpen, setDialogOpen] = useState(false);
	const [message, setMessage] = useState("");
	const [snackOpen, setSnackOpen] = useState(false);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleSelect = (current, selected, product, order) => {
		handleClose();
		setCurrentStatus(current);
		setNewStatus(selected);

		if (
			selected.value == "returned_for_replacement" ||
			selected.value == "returned_for_refund"
		) {
			returnProduct(order, product, selected.value);
		} else {
			console.log("ER");
			orderService
				.updateOrderProductStatus(product.id, selected.value)
				.then((o) => {
					console.log("Status Updated", o);
				})
				.catch((ex) => {
					console.log("Status Updated Failed", ex);
				});
		}
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	useEffect(() => {
		console.log("List", list);
	}, []);

	const returnProduct = (item, li, status) => {
		console.log("RODER");
		const payload = {
			orderProductId: li.id,
			variantId: li.variantId,
			storeId: item.storeId,
			merchantId: li.merchantId,
			orderStatus: status,
			returnStatus: status,
			returnDescription: "SPEC_MISMATCH",
			returnAmount: 0,
			settlementChannel: "WALLET",
			orderId: item.id,
			orderReturnReason: "SPEC_MISMATCH",
			productQueryId: li.productQueryId,
		};

		console.log("PAYLOAD", payload);
		orderService.createReturn(payload).then((res) => {
			const { returns, updatedOrder } = res;
			let currentOrders = [...list];
			const updated = _.map(currentOrders, (current) => {
				if (current.id == li.id) {
					console.log("Updating");
					let listItems = [...current.lineItems];
					const updatedLineItems = _.map(listItems, (item) => {
						if (item.id == li.id) {
							return { ...item, lineItemStatus: status };
						} else {
							return item;
						}
					});
					return updatedLineItems;
				} else {
					return current;
				}
			});
			console.log("Updated", updated);
			setList(updated);
		});
	};

	/**
	 * queryGroupName: item.ProductQuery.queryGroupName,
								totalPrice: item.totalPrice,
								quantity: item.ProductQuery.quantity,
								color: item.ProductQuery.color,
								qualityVariant: item.ProductQuery.qualityVariant,
								brand: item.ProductQuery.Brand.name,
								model: item.ProductQuery.Models.name,
								product: item.Product.name,
								id: item.id,
								storeId: item.storeId,
								merchantId: item.merchantId,
								status: item.lineItemStatus,
								cashback: item.cashback,
								productQueryId: item.ProductQuery.id,
								variantId: item.variantId,
								lineItemStatus: item.lineItemStatus,
	 */

	const columns = [
		{
			field: "brand",
			headerName: "Brand",
			width: 80,
			renderCell: (params) => {
				return (
					<MKTypography variant='caption' color='dark'>
						{params.row?.brand}
					</MKTypography>
				);
			},
		},
		{
			field: "model",
			headerName: "Model",
			width: 150,
			renderCell: (params) => {
				return (
					<MKTypography variant='caption' color='dark'>
						{params.row?.model}
					</MKTypography>
				);
			},
		},
		{
			field: "product",
			headerName: "Product",
			width: 250,
			renderCell: (params) => {
				return (
					<MKTypography variant='caption' color='dark'>
						{params.row?.product}
					</MKTypography>
				);
			},
		},
		{
			field: "color",
			headerName: "Color",
			width: 110,
			renderCell: (params) => {
				return (
					<Stack
						direction={"row"}
						sx={{
							alignItems: "center",
							justifyContent: "center",
							display: "flex",
						}}
						gap={1}
					>
						<Box
							sx={{
								width: 14,
								height: 14,
								backgroundColor: params.row?.color.hex,
							}}
						></Box>
						<MKTypography variant='caption' color='dark'>
							{params.row?.color.name}
						</MKTypography>
					</Stack>
				);
			},
		},
		{
			field: "qualityVariant",
			headerName: "Variant",
			width: 110,
			renderCell: (params) => {
				return (
					<MKTypography variant='caption' color='dark'>
						{params.row?.qualityVariant}
					</MKTypography>
				);
			},
		},
		{
			field: "quantity",
			headerName: "Qty.",
			width: 80,
			renderCell: (params) => {
				return (
					<MKTypography variant='caption' color='dark'>
						{params.row?.quantity}
					</MKTypography>
				);
			},
		},
		{
			field: "cashback",
			headerName: "Cash Back",
			width: 120,
			renderCell: (params) => {
				return (
					<MKTypography variant='caption' color='dark'>
						AED &nbsp;{params.row?.cashback}
					</MKTypography>
				);
			},
		},
		{
			field: "totalPrice",
			headerName: "Item Price",
			width: 120,
			renderCell: (params) => {
				return (
					<MKTypography variant='caption' color='dark'>
						{params.row?.totalPrice.currency} &nbsp;
						{params.row?.totalPrice.amount}
					</MKTypography>
				);
			},
		},
		{
			field: "action",
			headerName: "",
			width: 200,
			renderCell: (params) => {
				return (
					<>
						<MKButton
							variant='outlined'
							color='dark'
							size='small'
							aria-controls={open ? "basic-menu" : undefined}
							aria-haspopup='true'
							aria-expanded={open ? "true" : undefined}
							onClick={handleClick}
						>
							{statusMap[params.row.lineItemStatus]} &nbsp;
							<BiChevronDownCircle />
						</MKButton>
						{menus[params.row.lineItemStatus] && (
							<Menu
								id='basic-menu'
								anchorEl={anchorEl}
								open={open}
								onClose={handleClose}
								MenuListProps={{
									"aria-labelledby": "basic-button",
								}}
							>
								{_.map(menus[params.row.lineItemStatus], (status) => {
									return (
										<MenuItem
											key={params.row.id}
											onClick={() =>
												handleSelect(
													params.row.lineItemStatus,
													status,
													params.row,
													params.row.order
												)
											}
										>
											{status.label}
										</MenuItem>
									);
								})}
							</Menu>
						)}
					</>
				);
			},
		},
	];

	return (
		<Box sx={{ width: "100%" }}>
			{_.map(list, (item) => {
				return (
					<Accordion sx={{ bgcolor: "#fcfcfc" }} elevation={0}>
						<AccordionSummary expandIcon={<ArrowDropDown />}>
							<Paper sx={styles.root} elevation={0}>
								<Grid container spacing={1}>
									<Grid item xs={2}>
										<Stack direction={"column"} gap={1} sx={styles.col}>
											<MKTypography
												variant='caption'
												color='dark'
												fontWeight='bold'
											>
												Order Id
											</MKTypography>
											<MKTypography variant='caption' color='dark'>
												{item.id}
											</MKTypography>
										</Stack>
									</Grid>
									<Grid item xs={2}>
										<Stack direction={"column"} gap={1} sx={styles.col}>
											<MKTypography
												variant='caption'
												color='dark'
												fontWeight='bold'
											>
												Date
											</MKTypography>
											<MKTypography variant='caption' color='dark'>
												{new Date(item.dateCreated).toLocaleDateString(
													"en-US",
													{
														year: "numeric",
														month: "short",
														day: "numeric",
													}
												)}
											</MKTypography>
										</Stack>
									</Grid>

									<Grid item xs={2}>
										<Stack direction={"column"} gap={1} sx={styles.col}>
											<MKTypography
												variant='caption'
												color='dark'
												fontWeight='bold'
											>
												Payment Mode
											</MKTypography>
											<MKTypography variant='caption' color='dark'>
												{item.paymentModeTitle}
											</MKTypography>
										</Stack>
									</Grid>
									<Grid item xs={2}>
										<Stack direction={"column"} gap={1} sx={styles.col}>
											<MKTypography
												variant='caption'
												color='dark'
												fontWeight='bold'
											>
												Delivery Charge
											</MKTypography>
											<MKTypography variant='caption' color='dark'>
												AED {item.deliveryChargeTotal}
											</MKTypography>
										</Stack>
									</Grid>
									<Grid item xs={2}>
										<Stack direction={"column"} gap={1} sx={styles.col}>
											<MKTypography
												variant='caption'
												color='dark'
												fontWeight='bold'
											>
												Cashback
											</MKTypography>
											<MKTypography variant='caption' color='dark'>
												AED {item.cashbackTotal}
											</MKTypography>
										</Stack>
									</Grid>
									<Grid item xs={2}>
										<Stack direction={"column"} gap={1} sx={styles.col}>
											<MKTypography
												variant='caption'
												color='dark'
												fontWeight='bold'
											>
												Total Payable
											</MKTypography>
											<MKTypography variant='caption' color='dark'>
												AED{" "}
												{Number(
													item.total -
														item.cashbackTotal +
														item.deliveryChargeTotal
												).toFixed(2)}
											</MKTypography>
										</Stack>
									</Grid>
								</Grid>
							</Paper>
						</AccordionSummary>
						<AccordionDetails sx={{ backgroundColor: "#FFFAFA" }}>
							<div style={{ width: "100%" }}>
								<div width='100%'>
									<DataGrid
										rows={item.lineItems}
										columns={columns}
										pageSizeOptions={[5]}
										disableRowSelectionOnClick
									/>
								</div>
							</div>
						</AccordionDetails>
					</Accordion>
				);
			})}
		</Box>
	);
};
export default MyOrderList;

const styles = {
	root: {
		padding: 1,
		alignItems: "center",
		display: "flex",
		width: "100%",
		height: 80,
		backgroundColor: "#fafafa",
	},
	col: {
		alignItems: "center",
		justifyContent: "center",
		display: "flex",
		flexDirection: "column",
	},
};
