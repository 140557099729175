const { default: jambuda } = require("./core");

const stores = {
	createStore,
	updateStore,
	getStoreData,
	getStoreDataByUser,
};
export default stores;

function createStore(payload) {
	return new Promise((resolve, reject) => {
		jambuda
			.post("/api/store", payload)
			.then((store) => {
				return resolve(store.data);
			})
			.catch((err) => {
				return reject(err);
			});
	});
}
function updateStore(storeId, payload) {
	return new Promise((resolve, reject) => {
		jambuda
			.patch(`/api/store/${storeId}`, payload)
			.then((store) => {
				return resolve(store.data);
			})
			.catch((err) => {
				return reject(err);
			});
	});
}
function getStoreData(storeId) {
	return new Promise((resolve, reject) => {
		jambuda
			.get(`/api/store/${storeId}`)
			.then((store) => {
				return resolve(store.data);
			})
			.catch((err) => {
				return reject(err);
			});
	});
}
function getStoreDataByUser(userId) {
	return new Promise((resolve, reject) => {
		jambuda
			.get(`/api/store/by-user?admin=${userId}`)
			.then((store) => {
				return resolve(store.data);
			})
			.catch((err) => {
				return reject(err);
			});
	});
}
