const { default: jambuda } = require("./core");

const wallet = {
	fetchWalletForMerchant,
	fetchWalletForStore,
	fetchWalletForId,
};

function fetchWalletForMerchant(merchantId) {
	return new Promise((resolve, reject) => {
		jambuda
			.get(`/api/wallet?merchantId=${merchantId}`)
			.then((wallet) => {
				return resolve(wallet.data);
			})
			.catch((err) => {
				return reject(err);
			});
	});
}
function fetchWalletForStore(storeId) {
	return new Promise((resolve, reject) => {
		jambuda
			.get(`/api/wallet?storeId=${storeId}`)
			.then((wallet) => {
				return resolve(wallet.data);
			})
			.catch((err) => {
				return reject(err);
			});
	});
}
function fetchWalletForId(id) {
	return new Promise((resolve, reject) => {
		jambuda
			.get(`/api/wallet/${id}`)
			.then((wallet) => {
				return resolve(wallet.data);
			})
			.catch((err) => {
				return reject(err);
			});
	});
}
export default wallet;
