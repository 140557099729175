const { default: jambuda } = require("./core");
const productVariant = { getProductVariants, findOrCreateVariant };
function getProductVariants(brand, product) {
	return new Promise((resolve, reject) => {
		if (brand && product) {
			jambuda
				.get(`/api/product-variants?brand=${brand}&product=${product}`)
				.then((result) => {
					const variants = result.data;
					const count = result.data.length;
					return resolve({ variants: variants, count: count });
				});
		} else {
			jambuda.get(`/api/product-variants`).then((result) => {
				const variants = result.data;
				const count = result.data.length;
				return resolve({ variants: variants, count: count });
			});
		}
	});
}
function findOrCreateVariant(variantName, productId, modelId) {
	return new Promise((resolve, reject) => {
		jambuda
			.get(
				`/api/product-variants/findorcreate?modelId=${modelId}&productId=${productId}&variantName=${variantName}`
			)
			.then((result) => {
				return resolve(result.data);
			});
	});
}

export default productVariant;
