import { Container, Grid, Paper, Stack } from "@mui/material";
import wallet from "../../../api/wallet";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MKTypography from "components/MKTypography";
import logo from "../../../assets/images/jumbuda.png";
import "../../Merchant/credit-card.css";
import { updateWallet } from "features/walletSlice";
import MKButton from "components/MKButton";
import UserService from "../../../api/users";
import _ from "lodash";
import { FaCheckCircle } from "react-icons/fa";
const DeliveryPartnerWallet = (props) => {
	const user = useSelector((state) => state.user.user.user);
	const dispatch = useDispatch();
	const myWallet = useSelector((state) => state.wallet.wallet);
	const [selectedManager, setSelectedManager] = useState(null);
	const [managerList, setManagerList] = useState([]);
	const [loading, setLoading] = useState();
	const [myWalletData, setMyWalletData] = useState(null);
	const [userService, setUserService] = useState(new UserService());
	useEffect(() => {
		wallet.fetchWalletForId(myWallet.id).then((data) => {
			dispatch(updateWallet(data));
			setMyWalletData(data);
		});
		userService.findUsersByType("PURCHASE_MANAGER").then((result) => {
			setManagerList(result);
		});
	}, []);
	return (
		<Container
			sx={{ alignItems: "center", display: "flex", minHeight: "60vh" }}
		>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Paper className='creditCard' sx={{ width: "100%" }}>
						<Stack
							gap={1}
							sx={{
								alignItems: "center",
								justifyContent: "space-between",
								width: "100%",
							}}
							direction='row'
						>
							<MKTypography variant='body1' color='white' fontWeight='bold'>
								{user.firstName + " " + user.lastName}
							</MKTypography>
							<div style={{ width: 60, height: 60, objectFit: "cover" }}>
								<img src={logo} width='100%' />
							</div>
						</Stack>
						<Stack width='100%' key={myWallet?.id}>
							<MKTypography variant='h2' color='white' fontWeight='bold'>
								{myWallet?.currency}&nbsp;
								{myWallet?.balance}
							</MKTypography>
						</Stack>
					</Paper>
				</Grid>
				<Grid
					item
					xs={12}
					sm={12}
					sx={{
						alignItems: "center",
						justifyContent: "center",
						display: "flex",
					}}
				>
					<MKTypography variant='caption' color='dark'>
						Select Your Manager to Transfer Cash
					</MKTypography>
				</Grid>
				{_.map(managerList, (manager) => {
					return (
						<Grid item xs={12} sm={12}>
							<div
								onClick={() => setSelectedManager(manager.id)}
								style={{ cursor: "pointer" }}
							>
								<Grid
									container
									spacing={1}
									sx={{
										alignItems: "center",
										justifyContent: "space-between",
										display: "flex",
										flexDirection: "row",
										backgroundColor: "#FEF9F4",
									}}
								>
									<Grid item xs={2}>
										<FaCheckCircle
											size={18}
											color={manager.id === selectedManager ? "#7773F3" : ""}
										/>
									</Grid>
									<Grid item xs={6}>
										<MKTypography variant='caption' color='dark'>
											{manager.firstName + " " + manager.lastName}
										</MKTypography>
									</Grid>
									<Grid item xs={4}>
										<MKTypography variant='caption' color='dark'>
											{manager.phoneNumber.countryCode} -
											{manager.phoneNumber.number}
										</MKTypography>
									</Grid>
								</Grid>
							</div>
						</Grid>
					);
				})}

				<Grid item xs={12} sm={12}>
					<MKButton
						variant='contained'
						color='primary'
						fullWidth
						disabled={!selectedManager}
					>
						Handover Cash
					</MKButton>
				</Grid>
			</Grid>
		</Container>
	);
};
export default DeliveryPartnerWallet;
