const { default: jambuda } = require("./core");

const brands = { getAllBrands };

function getAllBrands() {
	return new Promise((resolve, reject) => {
		jambuda.get(`/api/brand?page=${1}&count=${100}`).then((result) => {
			const brands = result.data.brands;
			const count = result.data.totalCount;
			return resolve({ brands: brands, count: count });
		});
	});
}
export default brands;
