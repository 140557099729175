import React, { useEffect, useState } from "react";
import {
	Box,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	Grid,
	Paper,
	Stack,
} from "@mui/material";
import MKTypography from "components/MKTypography";
import { useSelector } from "react-redux";
import _ from "lodash";
import MKButton from "components/MKButton";
import { Warning } from "@phosphor-icons/react";

export const OrderRow = (props) => {
	const statusLabels = {
		request_refund: "Requested Refund",
		request_replacement: "Requested Replacement",
		returnRejected: "Rejected Returns",
	};
	const [orderProductId, setOrderProductId] = useState(props.orderProductId);
	const [product, setProduct] = useState(props.product);
	const [color, setColor] = useState(props.color);
	const [quantity, setQuantity] = useState(props.quantity);
	const [totalPayable, setTotalPayable] = useState(props.totalPayable);
	const [totalPaid, setTotalPaid] = useState(props.totalPaid);
	const [deliveryDate, setDeliveryDate] = useState(props.deliveredDate);
	const [lineItemStatus, setLineItemStatus] = useState(props.lineItemStatus);
	const transitions = useSelector((state) => state.transitions.transitions);
	const [availableTransitions, setAvailableTranstions] = useState([]);
	const [transitionStatus, setTransitionStatus] = useState(undefined);
	const [transitionLabel, setTransitionLabel] = useState(undefined);
	const [open, setOpen] = useState(false);
	const handleClose = () => {
		setOpen(false);
	};
	const handleChangeStatus = (status, label) => {
		setTransitionStatus(status);
		setTransitionLabel(label);
		setOpen(true);
	};
	const handleConfirm = () => {
		if (props.onStateChange) {
			setOpen(false);
			props.onStateChange(orderProductId, lineItemStatus, transitionStatus);
		}
	};
	useEffect(() => {
		console.log("lineItemStatus", lineItemStatus);
		const s = transitions[lineItemStatus];
		setAvailableTranstions(s);
	}, []);
	return (
		<>
			<Grid
				container
				spacing={1}
				display={{ xs: "none", sm: "flex" }}
				key={props.key}
			>
				<Grid item xs={6} sm={10}>
					<Stack gap={1}>
						{lineItemStatus == "completed" ? (
							<MKTypography variant='body2' color='dark'>
								Deliverd&nbsp;{" "}
								{deliveryDate
									? new Date(deliveryDate).toLocaleDateString("en-US", {
											year: "numeric",
											month: "short",
											day: "numeric",
									  })
									: "Unknown"}
							</MKTypography>
						) : (
							<MKTypography variant='body2' color='dark'>
								Order Status:&nbsp; {statusLabels[lineItemStatus]}
							</MKTypography>
						)}
						<MKTypography variant='caption' color='dark'>
							Jmp-1001-{orderProductId}
						</MKTypography>
						<Grid container spacing={1} direction={"row"}>
							<Grid item sm={2}>
								<Stack direction='column' gap={1}>
									<MKTypography variant='caption' color='dark'>
										Color
									</MKTypography>
									<Stack direction={"row"} gap={1}>
										<Box
											sx={{ width: 20, height: 20, backgroundColor: color.hex }}
										/>
										<MKTypography variant='caption' color='dark'>
											{color.name}
										</MKTypography>
									</Stack>
								</Stack>
							</Grid>
							<Grid item sm={8}>
								<Stack direction={"column"} gap={1}>
									<MKTypography variant='caption' color='dark'>
										{product}
									</MKTypography>
									<MKTypography
										variant='caption'
										color='dark'
										fontWeight='bold'
									>
										Quantity: {quantity}
									</MKTypography>
									<MKTypography
										variant='caption'
										color='dark'
										fontWeight='bold'
									>
										Price&nbsp; AED&nbsp; {Number(totalPayable).toFixed(2)}{" "}
										&nbsp;&nbsp; Paid&nbsp; AED&nbsp;
										{Number(totalPaid).toFixed(2)}
									</MKTypography>
								</Stack>
							</Grid>
						</Grid>
					</Stack>
				</Grid>
				<Grid item sm={2}>
					<Stack gap={1}>
						<Paper
							sx={{
								backgroundColor: "#FFF5FA",
								padding: 1,
								maxWidth: 100,
								alignItems: "center",
								justifyContent: "center",
								display: "flex",
							}}
							elevation={0}
						>
							{Number(totalPaid).toFixed(2) > 0 ? (
								Number(totalPaid).toFixed(2) <
								Number(totalPayable).toFixed(2) ? (
									<MKTypography variant='caption' color='dark'>
										Partially Paid
									</MKTypography>
								) : (
									<MKTypography variant='caption' color='dark'>
										Paid
									</MKTypography>
								)
							) : (
								<MKTypography variant='caption' color='dark'>
									Unpaid
								</MKTypography>
							)}
						</Paper>
						{_.map(availableTransitions, (t) => {
							return (
								<MKButton
									variant='contained'
									color='info'
									size='small'
									onClick={() => handleChangeStatus(t.state, t.label)}
								>
									<MKTypography
										variant='caption'
										color='light'
										fontWeight='bold'
									>
										{t.label}
									</MKTypography>
								</MKButton>
							);
						})}
					</Stack>
				</Grid>
				<Grid item xs={12} sm={12}>
					<Divider sx={{ width: "100%" }} />
				</Grid>
				<Dialog
					open={open}
					onClose={() => setOpen(false)}
					aria-labelledby='alert-dialog-title'
					aria-describedby='alert-dialog-description'
				>
					<DialogTitle id='alert-dialog-title'>
						<MKTypography variant='body2' color='dark'>
							<Warning /> Order Status Update
						</MKTypography>
					</DialogTitle>
					<DialogContent>
						<DialogContentText id='alert-dialog-description'>
							<MKTypography variant='caption' color='dark'>
								The Order Status shall be changed to{" "}
								<strong>{transitionLabel}</strong>. Proceed?
							</MKTypography>
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<MKButton
							onClick={() => setOpen(false)}
							variant='contained'
							color='error'
							autoFocus
						>
							NO
						</MKButton>
						<MKButton onClick={handleConfirm} variant='contained' color='info'>
							Yes
						</MKButton>
					</DialogActions>
				</Dialog>
			</Grid>

			<Grid
				container
				spacing={1}
				display={{ xs: "flex", sm: "none" }}
				key={props.key}
			>
				<Grid item xs={12}>
					<Grid container spacing={1}>
						<Grid item xs={6}>
							<Stack direction='column' gap={1}>
								<MKTypography variant='caption' color='dark'>
									Order Id
								</MKTypography>
								<MKTypography variant='caption' color='dark'>
									Jmp-1001-{orderProductId}
								</MKTypography>
							</Stack>
						</Grid>
						<Grid item xs={6}>
							<Stack direction='column' gap={1}>
								<MKTypography variant='caption' color='dark'>
									Color
								</MKTypography>
								<Stack direction={"row"} gap={1}>
									<Box
										sx={{ width: 12, height: 12, backgroundColor: color.hex }}
									/>
									<MKTypography variant='caption' color='dark'>
										{color.name}
									</MKTypography>
								</Stack>
							</Stack>
						</Grid>
						<Grid item xs={12}>
							<MKTypography variant='caption' color='dark'>
								{product}
							</MKTypography>
						</Grid>
						<Grid xs={6}>
							<Stack direction={"row"} gap={1}>
								<Stack gap={1} direction={"column"}>
									<MKTypography
										variant='caption'
										color='dark'
										fontWeight='bold'
									>
										Quantity
									</MKTypography>
									<MKTypography
										variant='caption'
										color='dark'
										fontWeight='bold'
									>
										{quantity}
									</MKTypography>
								</Stack>
							</Stack>
						</Grid>
						<Grid item xs={6}>
							<Grid container spacing={1}>
								<Grid item xs={6}>
									<Stack gap={1} direction='column'>
										<MKTypography
											variant='caption'
											color='dark'
											fontWeight='bold'
										>
											Price
										</MKTypography>
										<MKTypography
											variant='caption'
											color='dark'
											fontWeight='bold'
										>
											AED&nbsp; {Number(totalPayable).toFixed(2)}
										</MKTypography>
									</Stack>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<Stack gap={1}>
						{_.map(availableTransitions, (t) => {
							return (
								<MKButton
									variant='contained'
									color='info'
									size='small'
									onClick={() => handleChangeStatus(t.state, t.label)}
								>
									<MKTypography
										variant='caption'
										color='light'
										fontWeight='bold'
									>
										{t.label}
									</MKTypography>
								</MKButton>
							);
						})}
					</Stack>
				</Grid>
				<Grid item xs={12} sm={12}>
					<Divider sx={{ width: "100%" }} />
				</Grid>
				<Dialog
					open={open}
					onClose={() => setOpen(false)}
					aria-labelledby='alert-dialog-title'
					aria-describedby='alert-dialog-description'
				>
					<DialogTitle id='alert-dialog-title'>
						<MKTypography variant='body2' color='dark'>
							<Warning /> Order Status Update
						</MKTypography>
					</DialogTitle>
					<DialogContent>
						<DialogContentText id='alert-dialog-description'>
							<MKTypography variant='caption' color='dark'>
								The Order Status shall be changed to{" "}
								<strong>{transitionLabel}</strong>. Proceed?
							</MKTypography>
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<MKButton
							onClick={() => setOpen(false)}
							variant='contained'
							color='error'
							autoFocus
						>
							NO
						</MKButton>
						<MKButton onClick={handleConfirm} variant='contained' color='info'>
							Yes
						</MKButton>
					</DialogActions>
				</Dialog>
			</Grid>
		</>
	);
};
