const { default: jambuda } = require("./core");
const Media = {
	upload,
	remove,
};
export default Media;
function upload(file, filename, path) {
	return new Promise((resolve, reject) => {
		jambuda
			.post("api/integrations/upload", {
				file: file,
				filename: filename,
				path: path,
			})
			.then((result) => {
				return resolve(result.data);
			})
			.catch((err) => {
				return reject(err);
			});
	});
}
function remove(fileId) {
	return new Promise((resolve, reject) => {
		jambuda
			.delete(`api/integrations/${fileId}`)
			.then((result) => {
				return resolve(result.data);
			})
			.catch((err) => {
				return reject(err);
			});
	});
}
