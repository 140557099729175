import logo from "./logo.svg";
import "./App.css";
import { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";
import routes from "./routes";
import theme from "./assets/theme/index";
import MKShell from "components/MKShell";
import MKNotificationContainer from "components/MKNotifications";
function App() {
	const { pathname } = useLocation();

	// Setting page scroll to 0 when changing the route
	useEffect(() => {
		document.documentElement.scrollTop = 0;
		document.scrollingElement.scrollTop = 0;
	}, [pathname]);

	const getRoutes = (allRoutes) =>
		allRoutes.map((route) => {
			if (route.collapse) {
				return getRoutes(route.collapse);
			}

			if (route.route) {
				return (
					<Route
						exact
						path={route.route}
						element={route.component}
						key={route.key}
					/>
				);
			}

			return null;
		});
	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<MKShell />
			<MKNotificationContainer>
				<Routes>{getRoutes(routes)}</Routes>
			</MKNotificationContainer>
		</ThemeProvider>
	);
}

export default App;
