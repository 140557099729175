const { default: jumbuda } = require("./core");

export default class Partners {
	base_url = null;
	constructor() {
		this.base_url = "/api/delivery-partners";
	}
	async fetch_partner_data(userId) {
		return new Promise((resolve, reject) => {
			jumbuda
				.get(`${this.base_url}/${userId}`)
				.then((response) => {
					const wallet = response.data.Wallet[0];
					return resolve({ wallet, partner: response.data });
				})
				.catch((err) => {
					return reject(err);
				});
		});
	}
}
