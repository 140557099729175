import {
	Autocomplete,
	Avatar,
	Button,
	Chip,
	Container,
	Grid,
	IconButton,
	Paper,
	Stack,
	TextField,
} from "@mui/material";
import MKBreadCrumbs from "components/MKBreadCrumbs";
import MKTypography from "components/MKTypography";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./credit-card.css";
import logo from "../../assets/images/jumbuda.png";
import wallet from "../../api/wallet";
import MKButton from "components/MKButton";
import {
	Add,
	Edit,
	Menu,
	More,
	MoreHorizRounded,
	MoreRounded,
	Phone,
	PhoneAndroid,
} from "@mui/icons-material";
import MKInput from "components/MKInput";
import merchants from "../../api/merchants";
import { setMerchantProfile } from "features/merchantSlice";
import _ from "lodash";
import { setMerchantBrands } from "features/merchantSlice";

const breadcrumb = [
	{ id: 1, name: "Home", link: "/" },
	{ id: 2, name: "My Account", link: "" },
];

const MerchantAccount = (props) => {
	const merchant = useSelector((state) => state.merchants?.merchant);
	const merchantProfile = useSelector(
		(state) => state.merchants?.merchantProfile
	);
	const merchantBrands = useSelector((state) => state.merchants?.brands);
	const brandList = useSelector((state) => state.brands?.brands);
	const [myWallet, setMyWallet] = useState(null);
	const [primaryContactName, setPrimaryContactName] = useState(null);
	const [primaryContantPhone, setPrimaryContactPhone] = useState("");
	const [secondaryContactName, setSecondaryContactName] = useState("");
	const [secondaryContactPhone, setSecondaryContactPhone] = useState("");
	const [landlineNumber, setLandlineNumber] = useState("");
	const [brandIcon, setBrandIcon] = useState("");
	const dispatch = useDispatch();
	const [error, setError] = useState(false);
	const [selectedBrands, setSelectedBrands] = useState(null);
	const [editPrimary, setEditPrimary] = useState(false);
	useEffect(() => {
		wallet
			.fetchWalletForMerchant(merchant.id)
			.then((wallet) => setMyWallet(wallet[0]))
			.catch((err) => {
				console.log(err);
			});
		if (merchantProfile) {
			setPrimaryContactName(merchantProfile.primaryContactName);
			setPrimaryContactPhone(merchantProfile.primaryContact?.phoneNumber);
			setSecondaryContactName(merchantProfile.secondaryContactName);
			setSecondaryContactPhone(merchantProfile.secondaryContact?.phoneNumber);
			setLandlineNumber(merchantProfile.landlineNumber?.phoneNumber);
			setBrandIcon(merchantProfile.brandIcon);

			const sBrands = _.map(merchantBrands, function (b) {
				return _.find(brandList, function (l) {
					return l.id == b.brandId;
				});
			});
			setSelectedBrands(sBrands);
		}
	}, []);
	const submit = () => {
		if (
			!primaryContactName ||
			!primaryContantPhone ||
			!secondaryContactName ||
			!setSecondaryContactPhone
		) {
			setError(true);
			return;
		} else {
			setError(false);

			const payload = {
				merchantId: merchant.id,
				primaryContactName: primaryContactName,
				primaryContact: {
					countryCode: "+971",
					phoneNumber: primaryContantPhone,
				},
				secondaryContactName: secondaryContactName,
				secondaryContact: {
					countryCode: "+971",
					phoneNumber: secondaryContactPhone,
				},
				landlineNumber: {
					countryCode: "+971",
					phoneNumber: landlineNumber,
				},
				brandIcon: brandIcon,
			};
			if (!editPrimary) {
				merchants
					.addMerchantProfile(payload)
					.then((profile) => {
						dispatch(setMerchantProfile(profile));
					})
					.catch((err) => console.log(err));
			} else {
				const id = merchantProfile.id;

				merchants
					.updateMerchantProfile(id, payload)
					.then((profile) => {
						dispatch(setMerchantProfile(profile));
						const merchantBrands = _.map(selectedBrands, (b) => {
							return { merchantId: merchant.id, brandId: b.id };
						});
						merchants
							.addMerchantBrands(merchantBrands)
							.then((mbrands) => {
								dispatch(setMerchantBrands(mbrands));
							})
							.catch((err) => {
								setError(err);
							});
						setEditPrimary(false);
					})
					.catch((err) => {
						console.log(err);
					});
			}
		}
	};
	return (
		<Container style={styles.container}>
			<MKBreadCrumbs crumbs={breadcrumb} />
			<Paper elevation={0}>
				<Stack sx={styles.paper}>
					<Grid
						container
						spacing={3}
						sx={{
							alignItems: "center",
							justifyContent: "center",
							display: "flex",
						}}
					>
						<Grid
							xs={12}
							sm={12}
							sx={{ justifyContent: "flex-end", display: "flex" }}
						>
							<MKButton
								color='primary'
								variant='outlined'
								onClick={() => setEditPrimary(!editPrimary)}
								size='small'
							>
								<Edit />
								&nbsp;Edit
							</MKButton>
						</Grid>
						<Grid item xs={12} sm={3}>
							<Stack gap={1}>
								<Stack
									gap={1}
									direction={"row"}
									sx={{
										alignItems: "center",
										justifyContent: "flex-start",
										display: "flex",
									}}
								>
									<Avatar
										sx={{ width: 56, height: 56 }}
										src={
											merchantProfile?.brandIcon
												? merchantProfile.brandIcon
												: logo
										}
									/>
									<MKTypography variant='h2' color='primary'>
										{merchant?.name}
									</MKTypography>
								</Stack>

								<MKTypography variant='body1' color='dark'>
									{merchant?.address1}, &nbsp; {merchant?.state},&nbsp;{" "}
									{merchant?.country}
								</MKTypography>
								{merchantProfile?.primaryContact?.phoneNumber && (
									<Stack direction={"row"} gap={2}>
										<MKTypography
											variant='body2'
											color='dark'
											style={{
												alignItems: "center",
												justifyContent: "center",
												display: "flex",
											}}
										>
											<PhoneAndroid
												sx={{ color: "#3E506E", fontSize: 16 }}
												fontSize='14px'
											/>
											{merchantProfile?.primaryContact?.phoneNumber}
										</MKTypography>
									</Stack>
								)}

								{merchantProfile?.secondaryContact?.phoneNumber && (
									<Stack direction={"row"} gap={2}>
										<MKTypography
											variant='body2'
											color='dark'
											style={{
												alignItems: "center",
												justifyContent: "center",
												display: "flex",
											}}
										>
											<Phone
												sx={{ color: "#3E506E", fontSize: 16 }}
												fontSize='14px'
											/>
											{merchantProfile?.secondaryContact?.phoneNumber}
										</MKTypography>
									</Stack>
								)}
							</Stack>
							<Stack
								direction='row'
								gap={1}
								sx={{
									alignItems: "center",
									justifyContent: "flex-start",
									display: "flex",
								}}
							>
								{_.map(selectedBrands?.slice(0, 3), function (b) {
									return (
										<Chip
											label={
												<MKTypography variant='caption' color='white'>
													{b.name}
												</MKTypography>
											}
											size='small'
											sx={{
												backgroundColor: "#000000",
												color: "#ffffff",
											}}
										/>
									);
								})}
								<IconButton onClick={() => setEditPrimary(!editPrimary)}>
									<MoreHorizRounded sx={{ color: "#7740E5" }} />
								</IconButton>
							</Stack>
						</Grid>
						<Grid xs={12} sm={3}></Grid>
						<Grid
							item
							xs={12}
							sm={6}
							sx={{
								alignItems: "center",
								justifyContent: { xs: "center", sm: "flex-end" },
								display: "flex",
							}}
						>
							<Paper className='creditCard' sx={{ width: 350 }}>
								<Stack
									gap={1}
									sx={{
										alignItems: "center",
										justifyContent: "space-between",
										width: "100%",
									}}
									direction='row'
								>
									<MKTypography variant='body1' color='white' fontWeight='bold'>
										{merchant.name}
									</MKTypography>
									<div style={{ width: 60, height: 60, objectFit: "cover" }}>
										<img src={logo} width='100%' />
									</div>
								</Stack>
								<Stack width='100%' key={myWallet?.id}>
									<MKTypography variant='h2' color='white' fontWeight='bold'>
										{myWallet?.currency}&nbsp;
										{myWallet?.balance}
									</MKTypography>
								</Stack>
							</Paper>
						</Grid>
					</Grid>
				</Stack>
			</Paper>
			{(!merchantProfile || editPrimary) && (
				<Container fixed maxWidth='xs' sx={{ mt: 5, p: 2 }}>
					<Paper sx={styles.paper}>
						<Stack gap={1}>
							<MKTypography variant='h4' color='dark'>
								Enter Your Details
							</MKTypography>
							<Grid container spacing={2}>
								<Grid item xs={12} sm={6}>
									<MKInput
										fullWidth
										label='Primary Contact Name'
										placeholder='ex.John Doe'
										success={primaryContactName}
										value={primaryContactName}
										onChange={(e) => setPrimaryContactName(e.target.value)}
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<MKInput
										fullWidth
										label='Primary Contact Number'
										placeholder='XXXXXXXXXX'
										success={primaryContantPhone}
										value={primaryContantPhone}
										onChange={(e) => setPrimaryContactPhone(e.target.value)}
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<MKInput
										fullWidth
										label='Secondary Name'
										placeholder='ex.John Doe'
										success={secondaryContactName}
										value={secondaryContactName}
										onChange={(e) => setSecondaryContactName(e.target.value)}
									/>
								</Grid>

								<Grid item xs={12} sm={6}>
									<MKInput
										fullWidth
										label='Secondary Number'
										placeholder='10 digit phone'
										success={secondaryContactPhone}
										value={secondaryContactPhone}
										type='number'
										onChange={(e) => setSecondaryContactPhone(e.target.value)}
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<MKInput
										fullWidth
										label='Landline Number'
										placeholder='ex.John Doe'
										success={landlineNumber}
										value={landlineNumber}
										onChange={(e) => setLandlineNumber(e.target.value)}
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<MKInput
										fullWidth
										label='Logo'
										placeholder='Enter Url'
										success={brandIcon}
										value={brandIcon}
										onChange={(e) => setBrandIcon(e.target.value)}
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<Autocomplete
										options={brandList}
										multiple
										value={selectedBrands}
										getOptionLabel={(option) => option?.name}
										sx={{ width: "100%" }}
										onChange={(e, newValue) => {
											setSelectedBrands(newValue);
										}}
										renderInput={(params) => (
											<TextField
												size='small'
												error={error && !selectedBrands}
												{...params}
												label='Select Brands'
												inputProps={{
													...params.inputProps,
													autoComplete: "new-password", // disable autocomplete and autofill
												}}
											/>
										)}
									/>
								</Grid>
								<Grid
									item
									xs={12}
									sm={12}
									sx={{
										alignItems: "center",
										justifyContent: "center",
										display: "flex",
									}}
								>
									<MKButton
										color='primary'
										variant='contained'
										sx={{ width: 300 }}
										onClick={submit}
									>
										<Add />
										&nbsp;Submit
									</MKButton>
								</Grid>
							</Grid>
						</Stack>
					</Paper>
				</Container>
			)}
		</Container>
	);
};
export default MerchantAccount;
const styles = {
	container: {
		minHeight: "100vh",
		padding: 1,
	},
	paper: {
		padding: 5,
		alignItems: "center",
		justifyContent: "center",
		display: "flex",
		boxShadow: `0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)`,
	},
	wallet: {
		width: 350,
		height: 250,
		backgroundColor: "#8EC5FC",
		backgroundImage: `linear-gradient( 91.2deg,  rgba(136,80,226,1) 4%, rgba(16,13,91,1) 96.5% )`,
	},
	filter: {
		minHeight: "5vh",
		width: "100%",
		padding: 2,
		backgroundColor: "#FCFCFC",
		alignItems: "center",
		justifyContent: "space-between",
		display: "flex",
		flexDirection: "row",
	},
	queryRow: {
		width: "100%",
		padding: 2,
		alignItems: "center",
		justifyContent: "space-between",
		display: "flex",
		flexDirection: "row",
		marginTop: 1,
		backgroundColor: "#FEFCFC",
	},
};
