import { createSlice } from "@reduxjs/toolkit";

export const cartSlice = createSlice({
	name: "cart",
	initialState: {
		products: [],
	},
	reducers: {
		setCart: (state, action) => {
			state.products = action.payload;
		},
		clearCart: (state, action) => {
			state.products = [];
		},
	},
});

// Action creators are generated for each case reducer function
export const { setCart, clearCart } = cartSlice.actions;
export default cartSlice.reducer;
