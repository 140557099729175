import {
	Autocomplete,
	Checkbox,
	Container,
	FormControl,
	FormControlLabel,
	FormLabel,
	Paper,
	Radio,
	RadioGroup,
	Stack,
	TextField,
} from "@mui/material";
import stores from "../../api/stores";

import MKButton from "components/MKButton";
import MKInput from "components/MKInput";
import MKTypography from "components/MKTypography";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setStore } from "features/storeSlice";
import MKBreadCrumbs from "components/MKBreadCrumbs";
import breadcrumbs from "assets/theme/components/breadcrumbs";
import _ from "lodash";
import merchants from "../../api/merchants";
import { setMerchant } from "features/merchantSlice";
import { setMerchantBrands } from "features/merchantSlice";
const breadcrumb = [
	{ id: 1, name: "Home", link: "/" },
	{ id: 2, name: "Merchant Profile", link: "" },
];

const MerchantProfile = (props) => {
	const [locations, setLocations] = useState([]);
	const [zones, setZones] = useState([]);
	const [countries, setCountries] = useState([]);
	const localities = useSelector((state) => state.locations);
	const brands = useSelector((state) => state.brands.brands);
	const [brandsList, setBrands] = useState([]);
	const [selectedZone, setSelectedZone] = useState(null);
	const [selectedLocation, setSelectedLocation] = useState(null);
	const [selectedBrands, setSelectedBrands] = useState([]);
	const [name, setName] = useState("");
	const [address1, setAddress1] = useState("");
	const user = useSelector((state) => state.user);
	const [error, setError] = useState(null);
	const [lat, setLat] = useState(0);
	const [lng, setLng] = useState(0);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [taxRegistrationId, setTaxRegistrationId] = useState("");
	const merchant = useSelector((state) => state.merchants.merchant);
	const merchantBrands = useSelector((state) => state.merchants.brands);
	const [queryTypes, setQueryTypes] = useState([]);
	const addQueryType = (e, type) => {
		let types = [...queryTypes];
		if (e.target.checked) {
			types.push(type);
			setQueryTypes(types);
		} else {
			const rest = _.filter(types, (o) => {
				return o != type;
			});
			setQueryTypes(rest);
		}
	};
	useEffect(() => {
		if (localities) {
			setZones(localities.zones);
			setLocations(localities.locations);
			setCountries(localities.countries);
		}
		if (brands?.length) {
			console.log("Brands", brands);
			setBrands(brands);
		}

		if (merchant) {
			setName(merchant.name);
			const sBrands = _.map(merchantBrands, function (b) {
				return _.find(brands, function (l) {
					return l.id == b.brandId;
				});
			});
			setSelectedBrands(sBrands);
			setAddress1(merchant.address1);
			setLat(merchant.location?.lat);
			setLng(merchant.location?.lng);

			const z = _.find(localities.zones, (zone) => {
				return zone.id == merchant.zoneId;
			});
			setSelectedZone(z);

			const l = _.find(localities.locations, (location) => {
				return location.id == merchant.locationId;
			});
			setSelectedLocation(l);
			setTaxRegistrationId(merchant.taxId);
		}
	}, []);

	const handleSubmit = () => {
		if (
			!name ||
			!address1 ||
			!selectedZone ||
			!selectedLocation ||
			!lat ||
			!lng
		) {
			setError("This field is required");
		}
		let adminId = null;
		if (user.user?.id) {
			adminId = user.user?.id;
		} else {
			adminId = user.user?.user?.id;
		}
		console.log("ADMIN ID", adminId);
		const payload = {
			name: name,
			adminId: adminId,
			location: { lat: lat, lng: lng },
			address1: address1,
			street: "",
			state: selectedZone.name,
			country: "UAE",
			taxId: "ACV12442",
			locationId: selectedLocation.id,
			zoneId: selectedZone.id,
			currency: "AED",
		};

		if (merchant?.id) {
			merchants.updateMerchant(merchant.id, payload).then((result) => {
				dispatch(setMerchant(result));
				const merchantBrands = _.map(selectedBrands, (b) => {
					return {
						merchantId: result.id,
						brandId: b.id,
						queryType: queryTypes,
					};
				});
				merchants
					.addMerchantBrands(merchantBrands)
					.then((mbrands) => {
						dispatch(setMerchantBrands(mbrands));
						navigate("/merchant-account");
					})
					.catch((err) => {
						setError(err);
					});
			});
		} else {
			merchants
				.createMerchant(payload)
				.then((result) => {
					dispatch(setMerchant(result.merchant));
					const merchantBrands = _.map(selectedBrands, (b) => {
						return { merchantId: result.merchant.id, brandId: b.id };
					});
					merchants
						.addMerchantBrands(merchantBrands)
						.then((mbrands) => {
							dispatch(setMerchantBrands(mbrands));
							navigate("/merchant-account");
						})
						.catch((err) => {
							setError(err);
						});
				})
				.catch((err) => {
					setError(err);
				});
		}
	};
	return (
		<Container
			style={{
				alignItems: "center",
				justifyContent: "center",
				display: "flex",
				flexDirection: "column",
				minHeight: "60vh",
			}}
		>
			<MKBreadCrumbs crumbs={breadcrumb} />
			<Paper sx={styles.paper} elevation={0}>
				<Stack
					gap={2}
					sx={{
						width: "100%",
						alignItems: "center",
						justifyContent: "center",
						display: "flex",
						flexDirection: "column",
					}}
				>
					<MKTypography variant='body1' color='dark'>
						Add Your Business Details
					</MKTypography>
					<FormControl sx={{ flexDirection: "row" }}>
						<FormControlLabel
							value='SPARES'
							onChange={(e) => addQueryType(e, "SPARES")}
							control={<Checkbox />}
							label='Spare Parts'
						/>
						<FormControlLabel
							value='ACCESSORIES'
							control={
								<Checkbox onChange={(e) => addQueryType(e, "ACCESSORIES")} />
							}
							label='Accessories'
						/>
					</FormControl>
					<MKInput
						placeholder='Business Name'
						fullWidth
						value={name}
						onChange={(e) => setName(e.target.value)}
						error={error && !name}
					/>
					<Autocomplete
						options={brandsList}
						multiple
						value={selectedBrands}
						getOptionLabel={(option) => option?.name}
						sx={{ width: "100%" }}
						onChange={(e, newValue) => {
							setSelectedBrands(newValue);
						}}
						renderInput={(params) => (
							<TextField
								size='small'
								error={error && !selectedBrands}
								{...params}
								label='Select Brands'
								inputProps={{
									...params.inputProps,
									autoComplete: "new-password", // disable autocomplete and autofill
								}}
							/>
						)}
					/>

					<MKInput
						required
						placeholder='Building Number'
						fullWidth
						onChange={(e) => setAddress1(e.target.value)}
						error={error && !address1}
						value={address1}
					/>
					<Stack direction={"row"} gap={1} sx={{ width: "100%" }}>
						<MKInput
							fullWidth
							required
							placeholder='Lat'
							onChange={(e) => setLat(e.target.value)}
							error={error && !lat}
							value={lat}
						/>
						<MKInput
							fullWidth
							required
							placeholder='Lng'
							onChange={(e) => setLng(e.target.value)}
							error={error && !lng}
							value={lng}
						/>
					</Stack>
					<Autocomplete
						options={zones}
						value={selectedZone}
						getOptionLabel={(option) => option.name}
						sx={{ width: "100%" }}
						onChange={(e, newValue) => {
							if (newValue) {
								setSelectedZone(newValue);
							}
						}}
						renderInput={(params) => (
							<TextField
								required
								error={error && !selectedZone}
								size='small'
								{...params}
								label='Choose a Zone'
								inputProps={{
									...params.inputProps,
									autoComplete: "new-password", // disable autocomplete and autofill
								}}
							/>
						)}
					/>
					<Autocomplete
						options={locations}
						value={selectedLocation}
						getOptionLabel={(option) => option.name}
						sx={{ width: "100%" }}
						onChange={(e, newValue) => {
							if (newValue) {
								setSelectedLocation(newValue);
							}
						}}
						renderInput={(params) => (
							<TextField
								required
								size='small'
								error={error && !selectedLocation}
								{...params}
								label='Choose a Locality'
								inputProps={{
									...params.inputProps,
									autoComplete: "new-password", // disable autocomplete and autofill
								}}
							/>
						)}
					/>
					<MKInput
						placeholder='Tax Registration Number'
						fullWidth
						onChange={(e) => setTaxRegistrationId(e.target.value)}
						value={taxRegistrationId}
					/>

					<MKButton
						fullWidth
						variant='contained'
						color='primary'
						size='large'
						onClick={handleSubmit}
					>
						Submit
					</MKButton>
				</Stack>
			</Paper>
		</Container>
	);
};
export default MerchantProfile;
const styles = {
	paper: {
		padding: 3,
		minWidth: "400px",

		alignItems: "center",
		justifyContent: "center",
		display: "flex",
		backgroundColor: "#FBFCFA",
	},
};
