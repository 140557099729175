import * as React from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import MKTypography from "components/MKTypography";
import { Slide } from "@mui/material";

export default function MKSwipable(props) {
	const [open, setOpen] = React.useState(props.open);
	const handleClose = () => {
		setOpen(false);
		if (props.onClose) {
			props.onClose();
		}
	};
	return (
		<Slide direction='up' in={true} mountOnEnter unmountOnExit timeout={1000}>
			<Box sx={{ maxWidth: 900, paddingBottom: 10 }}>
				<SwipeableDrawer
					anchor={"bottom"}
					open={open}
					onClose={handleClose}
					onOpen={() => setOpen(true)}
					PaperProps={{
						sx: {
							maxWidth: 900, // Set the width of the drawer
							// height: "50vh", // Set the height of the drawer
							borderTopLeftRadius: 16, // Set the curve for the top-left edge
							borderTopRightRadius: 16, // Set the curve for the top-right edge
							margin: "auto", // Center the drawer horizontally
						},
					}}
				>
					<Box
						sx={{
							width: "100%",
							height: "100%",
							padding: 2,
							boxSizing: "border-box",
							// Ensure padding doesn't affect the width
							alignItems: "center",
							justifyContent: "center",
							display: "flex",
						}}
					>
						{props.children}
					</Box>
				</SwipeableDrawer>
			</Box>
		</Slide>
	);
}
