import { createSlice } from "@reduxjs/toolkit";

export const queryGroupCartSlice = createSlice({
	name: "queryGroupCart",
	initialState: {
		count: 0,
		queries: [],
		cartQueries: [],
	},
	reducers: {
		addQuery: (state, action) => {
			state.queries.push(action.payload);
		},
		removeQuery: (state, action) => {
			state.queries = state.variants.filter((v) => {
				return v.id != action.payload;
			});
		},
		setQueryCount: (state, action) => {
			state.count = action.payload;
		},
		setQuery: (state, action) => {
			state.queries = action.payload;
		},

		setCartQuery: (state, action) => {
			console.log("Pushing To Cart Queries", state.cartQueries, action.payload);
			state.cartQueries.push(action.payload);
		},
		updateCartQuery: (state, action) => {
			state.cartQueries = action.payload;
		},

		resetQuery: (state, action) => {
			console.log("RESET");
			state.count = 0;
			state.queries = [];
			state.cartQueries = [];
		},
	},
});

// Action creators are generated for each case reducer function
export const {
	addQuery,
	removeQuery,
	setQueryCount,
	setQuery,
	resetQuery,
	setCartQuery,
	updateCartQuery,
} = queryGroupCartSlice.actions;
export default queryGroupCartSlice.reducer;
