import { createSlice } from "@reduxjs/toolkit";

export const walletSlice = createSlice({
	name: "wallet",
	initialState: {
		wallet: null,
	},
	reducers: {
		updateWallet: (state, action) => {
			state.wallet = action.payload;
		},
		clearWallet: (state, action) => {
			state.wallet = null;
		},
	},
});

// Action creators are generated for each case reducer function
export const { updateWallet, clearWallet } = walletSlice.actions;

export default walletSlice.reducer;
