import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import dayjs from "dayjs";
import {
	AppBar,
	Box,
	Container,
	Grid,
	Pagination,
	Paper,
	Stack,
	Tab,
	Tabs,
} from "@mui/material";

import _ from "lodash";
import { useSelector } from "react-redux";
import orderService from "../../api/orders";
import MKTypography from "components/MKTypography";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { OrderHeader } from "./sections/OrderHeader";
import { OrderRow } from "./sections/OrderRow";

const OrderHistory = (props) => {
	const [storeId, setStoreId] = useState();
	const [page, setPage] = useState(1);
	const [count, setCount] = useState(5);
	const [orders, setOrders] = useState([]);
	const [totalCount, setTotalCount] = useState([]);
	const [refreshKey, setRefreshKey] = useState(undefined);
	const store = useSelector((state) => state.stores.store);
	const [status, setStatus] = useState("completed");
	const [activeTab, setActiveTab] = useState(0);
	const [fromDate, setFromDate] = useState(
		dayjs(new Date()).subtract(2, "days")
	);
	const [toDate, setToDate] = useState(dayjs(new Date()));

	const location = useLocation();
	const handleChange = (e, newval) => {
		setPage(newval);
	};
	useEffect(() => {
		if (location.state?.storeId) {
			setStoreId(location.state.storeId);
		}
	}, []);

	useEffect(() => {
		if (storeId) {
			orderService
				.get_order_history(storeId, page, count, fromDate, toDate, status)
				.then((response) => {
					const { totalCount, orders } = response;
					setOrders(orders);
					setTotalCount(totalCount);
				})
				.catch((err) => {
					console.log(err);
				});
		} else {
			orderService
				.get_order_history(store.id, page, count, fromDate, toDate, status)
				.then((response) => {
					const { totalCount, orders } = response;
					setOrders(orders);
					setTotalCount(totalCount);
				})
				.catch((err) => {
					console.log(err);
				});
		}
	}, [page, fromDate, toDate, refreshKey]);
	const handleStateChange = (
		orderProductId,
		lineItemStatus,
		transitionStatus
	) => {
		orderService
			.transition_order_status(orderProductId, {
				from: lineItemStatus,
				to: transitionStatus,
			})
			.then((result) => {
				setRefreshKey(Math.random() * 10000 + 1);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	return (
		<Container>
			<Grid container spacing={2}>
				<Grid item xs={12} sx={styles.pagination}>
					<Box
						sx={{
							alignItems: "center",
							justifyContent: "flex-start",
							flexGrow: 1,
							display: "flex",
						}}
					>
						<MKTypography variant='h4' color='dark'>
							Delivered Items
						</MKTypography>
					</Box>

					<Stack gap={2} direction='row' sx={styles.stack}>
						<Stack gap={1} sx={styles.pagination}>
							<MKTypography variant='caption' color='dark'>
								<strong>{totalCount}</strong>&nbsp; Orders Delivered
							</MKTypography>
							<Pagination
								count={Math.ceil(totalCount / count)}
								page={page}
								onChange={handleChange}
								variant='outlined'
								shape='rounded'
							/>
						</Stack>

						<Stack direction='column'>
							<MKTypography variant='caption' color='dark'>
								From Date
							</MKTypography>
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<DemoContainer components={["DatePicker"]}>
									<DatePicker
										value={fromDate}
										onChange={(newValue) => {
											setPage(1);
											setFromDate(newValue);
										}}
									/>
								</DemoContainer>
							</LocalizationProvider>
						</Stack>
						<Stack direction='column'>
							<MKTypography variant='caption' color='dark'>
								To Date
							</MKTypography>
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<DemoContainer components={["DatePicker"]}>
									<DatePicker
										value={toDate}
										onChange={(newValue) => {
											setPage(1);
											setToDate(newValue);
										}}
									/>
								</DemoContainer>
							</LocalizationProvider>
						</Stack>
					</Stack>
				</Grid>
			</Grid>

			{_.map(orders, (order) => {
				return (
					<Grid
						container
						spacing={1}
						sx={{ mb: 2, padding: 2, border: "1px solid #f5f5f5" }}
					>
						<Grid item xs={12} sm={12}>
							<OrderHeader
								key={page + activeTab}
								orderDate={order.dateCreated}
								orderTotal={Number(order.total).toFixed(2)}
								cashback={Number(order.cashbackTotal).toFixed(2)}
								deliveryCharge={order.deliveryChargeTotal}
								orderId={order.id}
							/>
						</Grid>
						<Grid item xs={12} sm={12}>
							<Paper
								sx={{ padding: 5, backgroundColor: "#ffffff" }}
								elevation={0}
							>
								{_.map(order.orderLineItems, (item) => {
									return (
										<OrderRow
											key={page + activeTab}
											orderProductId={item.id}
											lineItemStatus={item.lineItemStatus}
											product={`${item.ProductQuery.Brand.name} ${
												item.ProductQuery.Models.name
											} ${item.Product.name} ${
												item.ProductQuery.qualityVariant == "ORIGINAL"
													? "Original"
													: item.ProductQuery?.Variant?.name
											}`}
											quantity={item.ProductQuery.quantity}
											color={item.ProductQuery.color}
											totalPayable={
												item.Settlements.length
													? Number(
															item.Settlements[0].totalStoreReceivable
													  ).toFixed(2)
													: "Unknown"
											}
											totalPaid={
												item.Settlements.length
													? _.sumBy(
															item.Settlements[0].StorePayments,
															(settlement) => {
																return Number(settlement.amount);
															}
													  )
													: 0
											}
											deliveredDate={item.deliveredDate}
											onStateChange={handleStateChange}
										/>
									);
								})}
							</Paper>
						</Grid>
					</Grid>
				);
			})}
		</Container>
	);
};

const styles = {
	paper: {
		backgroundColor: "#F8FBFF",
		padding: 2,
		minHeight: "5vh",
	},
	pagination: {
		alignItems: "center",
		justifyContent: "center",
		display: "flex",
	},
	stack: {
		alignItems: "flex-end",
		justifyContent: "center",
		display: "flex",
		flexDirection: "row",
		padding: 2,
	},
};

export default OrderHistory;
