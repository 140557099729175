import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Autocomplete,
	Box,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Grid,
	Menu,
	MenuItem,
	Pagination,
	Snackbar,
	Stack,
	TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import MKTypography from "components/MKTypography";
import orderService from "../../api/orders";
import { useSelector } from "react-redux";
import { PiHourglassHighThin } from "react-icons/pi";
import _ from "lodash";
import MKNodata from "components/MKNodata";
import MKButton from "components/MKButton";
import { BiChevronDownCircle } from "react-icons/bi";
import { ArrowDropDown, CheckCircle } from "@mui/icons-material";
import { Warning } from "@phosphor-icons/react";
import { SiHappycow } from "react-icons/si";
const paymentStatus = {
	UNPAID: {
		label: "unpaid",
		bgcolor: "#FFFAF4",
		icon: (
			<PiHourglassHighThin color='darkorchid' weight='duotone'>
				<animate
					attributeName='opacity'
					values='0;1;0'
					dur='4s'
					repeatCount='indefinite'
				></animate>
				<animateTransform
					attributeName='transform'
					attributeType='XML'
					type='rotate'
					dur='5s'
					from='0 0 0'
					to='360 0 0'
					repeatCount='indefinite'
				></animateTransform>
			</PiHourglassHighThin>
		),
	},
};
// enum OrderStatus {
//   pending
//   processing
//   packed
//   onHold
//   completed
//   cancelled
//   refunded
//   failed
//   trash
//   returned
//   pickedFromStore
//   returnAccepted
//   returnRejected
//   oombi
// }

const menus = {
	pending: [{ id: 1, label: "Ready for Pickup", value: "ready_for_pickup" }],
	ready_for_pickup: [{ id: 1, label: "Pending", value: "pending" }],
	returned_for_refund: [
		{
			id: 1,
			label: "Accept Return & Refund",
			value: "returnAccepted_for_refund",
		},
		{
			id: 2,
			label: "Reject Return & Refund",
			value: "returnRejected",
		},
	],
	returned_for_replacement: [
		{
			id: 1,
			label: "Accept Return & Replacement",
			value: "returnAccepted_for_replacement",
		},
		{
			id: 2,
			label: "Reject Return",
			value: "returnRejected",
		},
	],
};
const lineItemStatus = {
	returnAccepted_for_refund: "Refund Accepted",
	returnAccepted_for_replacement: "Replacement Pending",
	pending: "New Order",
	ready_for_pickup: "Pending Pickup",
	returnRejected: "Return Rejected",
	returned_for_replacement: "Returned For Replacement",
	returned_for_refund: "Returned For Refund",
};

const MyReturnedProducts = (props) => {
	const [orderList, setOrders] = useState([]);
	const [selectedStatus, setSelectedStatus] = useState(null);
	const [prevStatus, setPrevStatus] = useState(null);
	const merchant = useSelector((state) => state.merchants.merchant);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [dialogOpen, setDialogOpen] = useState(false);
	const [selectedProduct, setSelectedProduct] = useState(null);
	const open = Boolean(anchorEl);
	const [message, setMessage] = useState("");
	const [snackOpen, setSnackOpen] = useState(false);
	const [page, setPage] = useState(1);
	const [count, setCount] = useState(8);
	const [totalCount, setTotalCount] = useState(0);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleSelect = (current, selected, product) => {
		console.log(selected);
		setSelectedStatus(selected);
		setPrevStatus(current);
		setSelectedProduct(product);
		setDialogOpen(true);
		handleClose();
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleSubmit = () => {
		if (
			selectedStatus.value == "returnAccepted_for_refund" ||
			selectedStatus.value == "returnAccepted_for_replacement" ||
			selectedStatus.value == "returnRejected"
		) {
			acceptRejectReturns();
		} else {
			updateOrderStatus();
		}
	};

	const updateOrderStatus = () => {
		setDialogOpen(false);
		orderService
			.updateOrderProductStatus(selectedProduct.id, selectedStatus.value)
			.then((result) => {
				refresh();
			})
			.catch((err) => {
				setMessage("Error Updating Order Status");
				setSnackOpen(true);
			});
	};
	const acceptRejectReturns = () => {
		const item = selectedProduct.Order;
		const li = selectedProduct;
		const status = selectedStatus.value;
		const orderReturns = _.find(selectedProduct.OrderReturns, (o) => {
			return o.orderProductId == li.id;
		});
		const orderReturnsId = orderReturns.id;
		const payload = {
			orderProductId: li.id,
			orderReturnsId: orderReturnsId,
			variantId: li.variantId,
			storeId: item.storeId,
			merchantId: li.merchantId,
			orderStatus: status,
			returnStatus: status,
			returnDescription: "SPEC_MISMATCH",
			returnAmount: 0,
			settlementChannel: "WALLET",
			orderId: item.id,
			orderReturnReason: "SPEC_MISMATCH",
			productQueryId: li.ProductQuery.id,
		};
		console.log(li, status, orderReturnsId, payload);
		setDialogOpen(false);
		orderService.orderReturnFlow(orderReturnsId, payload).then(() => {
			refresh();
		});
	};

	const columns = [
		// {
		// 	field: "id",
		// 	headerName: "Order",
		// 	width: 80,
		// 	renderCell: (params) => {
		// 		return (
		// 			<MKTypography variant='caption' color='dark'>
		// 				{params.row?.id}
		// 			</MKTypography>
		// 		);
		// 	},
		// },
		// {
		// 	field: "date",
		// 	headerName: "Date",
		// 	width: 100,
		// 	renderCell: (params) => {
		// 		return (
		// 			<MKTypography variant='caption' color='dark'>
		// 				{new Date(params.row?.createdAt).toLocaleDateString("en-US", {
		// 					year: "numeric",
		// 					month: "short",
		// 					day: "numeric",
		// 				})}
		// 			</MKTypography>
		// 		);
		// 	},
		// },
		// {
		// 	field: "brand",
		// 	headerName: "Brand",
		// 	width: 80,
		// 	renderCell: (params) => {
		// 		return (
		// 			<MKTypography variant='caption' color='dark'>
		// 				{params.row?.ProductQuery?.Brand.name}
		// 			</MKTypography>
		// 		);
		// 	},
		// },
		// {
		// 	field: "model",
		// 	headerName: "Model",
		// 	width: 115,
		// 	renderCell: (params) => {
		// 		return (
		// 			<MKTypography variant='caption' color='dark'>
		// 				{params.row?.ProductQuery?.Models.name}
		// 			</MKTypography>
		// 		);
		// 	},
		// },
		{
			field: "color",
			headerName: "Color",
			width: 100,
			renderCell: (params) => {
				return (
					<Stack
						direction={"row"}
						sx={{
							alignItems: "center",
							justifyContent: "center",
							display: "flex",
						}}
						gap={1}
					>
						<Box
							sx={{
								width: 14,
								height: 14,
								backgroundColor: params.row?.ProductQuery?.color.hex,
							}}
						></Box>
						<MKTypography variant='caption' color='dark'>
							{params.row?.ProductQuery?.color.name}
						</MKTypography>
					</Stack>
				);
			},
		},
		// {
		// 	field: "product",
		// 	headerName: "Product",
		// 	width: 150,
		// 	renderCell: (params) => {
		// 		return (
		// 			<MKTypography variant='caption' color='dark'>
		// 				{params.row.Product.name}
		// 			</MKTypography>
		// 		);
		// 	},
		// },
		{
			field: "quality",
			headerName: "Variant",
			width: 115,
			renderCell: (params) => {
				return (
					<MKTypography variant='caption' color='dark'>
						{params.row?.Variant ? params.row.Variant?.name : "Original"}
					</MKTypography>
				);
			},
		},
		{
			field: "qty",
			headerName: "Quantity",
			width: 90,
			renderCell: (params) => {
				return (
					<MKTypography variant='caption' color='dark' fontWeight='bold'>
						{params.row?.quantity}
					</MKTypography>
				);
			},
		},
		{
			field: "status",
			headerName: "Status",
			width: 115,
			renderCell: (params) => {
				return (
					<Box
						sx={{
							backgroundColor:
								paymentStatus[params.row?.Order?.paymentStatus].bgcolor,
							padding: 1,
							height: 30,
							alignItems: "center",
							justifyContent: "center",
							display: "flex",
						}}
					>
						<Stack
							direction={"row"}
							gap={1}
							sx={{
								alignItems: "center",
								justifyContent: "center",
								display: "flex",
							}}
						>
							{paymentStatus[params.row.Order.paymentStatus].icon}
							<MKTypography variant='caption' color='dark'>
								{paymentStatus[params.row.Order.paymentStatus].label}
							</MKTypography>
						</Stack>
					</Box>
				);
			},
		},
		{
			field: "total",
			headerName: "Total",
			width: 90,
			renderCell: (params) => {
				return (
					<MKTypography variant='caption' color='dark' fontWeight='bold'>
						AED&nbsp;
						{params.row.totalPrice.amount - params.row.markup}
					</MKTypography>
				);
			},
		},
		{
			width: 220,
			field: "actions",
			headerName: "Actions",
			renderCell: (params) => {
				return (
					// <Autocomplete
					// 	size='small'
					// 	options={statuses}
					// 	value={selectedStatus}
					// 	getOptionLabel={(option) => option?.label}
					// 	sx={{ width: "100%" }}
					// 	onChange={(e, newValue) => {
					// 		if (newValue) setSelectedStatus(newValue);
					// 	}}
					// 	renderInput={(params) => (
					// 		<TextField
					// 			size='small'
					// 			{...params}
					// 			inputProps={{
					// 				...params.inputProps,
					// 				autoComplete: "new-password", // disable autocomplete and autofill
					// 			}}
					// 		/>
					// 	)}
					// />
					<>
						<MKButton
							variant='contained'
							color='dark'
							size='small'
							aria-controls={open ? "basic-menu" : undefined}
							aria-haspopup='true'
							aria-expanded={open ? "true" : undefined}
							onClick={handleClick}
						>
							{lineItemStatus[params.row.lineItemStatus]} &nbsp;
							<BiChevronDownCircle />
						</MKButton>
						<Menu
							id='basic-menu'
							anchorEl={anchorEl}
							open={open}
							onClose={handleClose}
							MenuListProps={{
								"aria-labelledby": "basic-button",
							}}
						>
							{_.map(menus[params.row.lineItemStatus], (status) => {
								return (
									<MenuItem
										onClick={() =>
											handleSelect(
												params.row.lineItemStatus,
												status,
												params.row
											)
										}
									>
										{status.label}
									</MenuItem>
								);
							})}
						</Menu>
					</>
				);
			},
		},
	];

	useEffect(() => {
		refresh();
	}, [page]);
	const refresh = () => {
		orderService
			.getOrders(merchant?.id, "returns", page, count)
			.then((result) => {
				const { totalCount, returns } = result;
				setOrders(returns);
				setTotalCount(totalCount);
			});
	};
	return (
		<Box>
			<Snackbar
				open={snackOpen}
				autoHideDuration={4000}
				onClose={() => setSnackOpen(false)}
				message={
					<MKTypography variant='caption' color='white'>
						{message}
					</MKTypography>
				}
				action={
					<MKButton
						iconOnly
						color='white'
						circular
						size='small'
						onClick={() => {
							setSnackOpen(false);
						}}
					>
						<CheckCircle />
					</MKButton>
				}
			/>
			<Dialog
				open={dialogOpen}
				onClose={() => setDialogOpen(false)}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<DialogTitle id='alert-dialog-title'>
					<MKTypography variant='body2' color='dark'>
						<Warning /> Order Status Update
					</MKTypography>
				</DialogTitle>
				<DialogContent>
					<DialogContentText id='alert-dialog-description'>
						<MKTypography variant='caption' color='dark'>
							You are updating the order for {selectedProduct?.Product?.name}
							&nbsp; status from <strong>{prevStatus}</strong> &nbsp;to&nbsp;
							<strong>{selectedStatus?.label}</strong>. <br />
							Proceed?
						</MKTypography>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<MKButton
						onClick={() => setDialogOpen(false)}
						variant='contained'
						color='error'
						autoFocus
					>
						NO
					</MKButton>
					<MKButton onClick={handleSubmit} variant='contained' color='black'>
						Yes
					</MKButton>
				</DialogActions>
			</Dialog>

			{orderList.length ? (
				<>
					<Box
						sx={{
							height: "10vh",
							width: "100%",
							alignItems: "center",
							justifyContent: "center",
							display: "flex",
						}}
					>
						<Pagination
							color='primary'
							count={Math.ceil(totalCount / count)}
							page={page}
							onChange={(e, val) => setPage(val)}
						/>
					</Box>

					{_.map(orderList, (order) => {
						return (
							<Accordion
								defaultExpanded={false}
								sx={{ bgcolor: "#fcfcfc" }}
								elevation={0}
							>
								<AccordionSummary expandIcon={<ArrowDropDown />}>
									<Stack
										gap={2}
										direction={"row"}
										sx={{
											alignItems: "center",
											justifyContent: "space-between",
											display: "flex",
											width: "100%",
										}}
									>
										<Grid container spacing={2}>
											<Grid item xs={2} sm={2}>
												<Stack direction='column'>
													<MKTypography
														variant='caption'
														color='dark'
														fontWeight='bold'
													>
														Order Date
													</MKTypography>
													<MKTypography
														variant='caption'
														color='dark'
														sx={{ minWidth: 100 }}
													>
														{new Date(order.createdAt).toLocaleDateString(
															"en-US",
															{
																year: "numeric",
																month: "short",
																day: "numeric",
															}
														)}
													</MKTypography>
												</Stack>
											</Grid>
											<Grid item xs={2} sm={2}>
												<Stack direction='column'>
													<MKTypography
														variant='caption'
														color='dark'
														fontWeight='bold'
													>
														Order Id
													</MKTypography>
													<MKTypography
														variant='caption'
														color='dark'
														sx={{ minWidth: 100 }}
													>
														{order.id}
													</MKTypography>
												</Stack>
											</Grid>
											<Grid item xs={2} sm={2}>
												<Stack direction='column' sx={{ minWidth: 100 }}>
													<MKTypography
														variant='caption'
														color='dark'
														fontWeight='caption'
													>
														Brand
													</MKTypography>
													<MKTypography variant='caption' color='dark'>
														{order.ProductQuery?.Brand.name}
													</MKTypography>
												</Stack>
											</Grid>
											<Grid item xs={2} sm={2}>
												<Stack direction='column' sx={{ minWidth: 100 }}>
													<MKTypography
														variant='caption'
														color='dark'
														fontWeight='bold'
													>
														Model
													</MKTypography>
													<MKTypography variant='caption' color='dark'>
														{order.ProductQuery?.Models.name}
													</MKTypography>
												</Stack>
											</Grid>
											<Grid item xs={2} sm={2}>
												<Stack direction='column' sx={{ minWidth: 100 }}>
													<MKTypography
														variant='caption'
														color='dark'
														fontWeight='bold'
													>
														Product
													</MKTypography>
													<MKTypography variant='caption' color='dark'>
														{order.Product.name}
													</MKTypography>
												</Stack>
											</Grid>
											<Grid item xs={2} sm={2}>
												<Stack
													direction={"row"}
													sx={{
														alignItems: "center",
														justifyContent: "center",
														display: "flex",
													}}
													gap={1}
												>
													<Box
														sx={{
															width: 14,
															height: 14,
															backgroundColor: order?.ProductQuery?.color.hex,
														}}
													></Box>
													<MKTypography variant='caption' color='dark'>
														{order?.ProductQuery?.color.name}
													</MKTypography>
												</Stack>
											</Grid>
										</Grid>
									</Stack>
								</AccordionSummary>
								<AccordionDetails>
									<div style={{ width: "100%" }}>
										<div style={{ width: "100%" }}>
											<DataGrid
												rows={[order]}
												columns={columns}
												key={[order]}
												pageSizeOptions={[5]}
												disableRowSelectionOnClick
											/>
										</div>
									</div>
								</AccordionDetails>
							</Accordion>
						);
					})}
					{/* <DataGrid
						rows={orderList}
						columns={columns}
						key={orderList}
						pageSizeOptions={[5]}
						disableRowSelectionOnClick
					/> */}
					<Box
						sx={{
							height: "10vh",
							width: "100%",
							alignItems: "center",
							justifyContent: "center",
							display: "flex",
						}}
					>
						<Pagination
							color='primary'
							count={Math.ceil(totalCount / count)}
							page={page}
							onChange={(e, val) => setPage(val)}
						/>
					</Box>
				</>
			) : (
				<Stack
					direction={"row"}
					gap={2}
					sx={{
						alignItems: "center",
						justifyContent: "center",
						display: "flex",
						height: "30vh",
					}}
				>
					<MKNodata size={56} />
					<MKTypography variant='h2' color='dark'>
						No Products Returned Yet &nbsp; <SiHappycow />
					</MKTypography>
				</Stack>
			)}
		</Box>
	);
};
export default MyReturnedProducts;
const styles = {
	box: {
		padding: 5,
		alignItems: "center",
		justifyContent: "flex-start",
		display: "flex",
		backgroundColor: "#F8FAFF",
	},
};
