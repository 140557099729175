import { createSlice } from "@reduxjs/toolkit";

export const merchantSlice = createSlice({
	name: "merchants",
	initialState: {
		merchant: {},
		queries: [],
		brands: [],
		count: 0,
		merchantProfile: {},
	},
	reducers: {
		setMerchant: (state, action) => {
			state.merchant = action.payload;
		},
		resetMerchant: (state, action) => {
			state.merchant = {};
		},
		setMerchantQueries: (state, action) => {
			state.queries = action.payload;
		},
		setMerchantBrands: (state, action) => {
			state.brands = action.payload;
		},
		setMerchantProfile: (state, action) => {
			state.merchantProfile = action.payload;
		},
		clearMerchant: (state, action) => {
			state.merchant = {};
			state.queries = [];
			state.brands = [];
			state.count = 0;
			state.merchantProfile = {};
		},
	},
});

// Action creators are generated for each case reducer function
export const {
	setMerchant,
	resetMerchant,
	setMerchantQueries,
	setMerchantBrands,
	setMerchantProfile,
	clearMerchant,
} = merchantSlice.actions;
export default merchantSlice.reducer;
