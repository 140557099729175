import { createSlice } from "@reduxjs/toolkit";

export const locationSlice = createSlice({
	name: "locations",
	initialState: {
		zones: [],
		locations: [],
		countries: ["UAE", "INDIA"],
	},
	reducers: {
		setZones: (state, action) => {
			state.zones = action.payload;
		},
		setLocations: (state, action) => {
			state.locations = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const { setZones, setLocations } = locationSlice.actions;
export default locationSlice.reducer;
