import {
	Box,
	Chip,
	Container,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	Grid,
	Paper,
	Stack,
} from "@mui/material";
import MKTypography from "components/MKTypography";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { TbBrandLivewire } from "react-icons/tb";
import _ from "lodash";
import { useSelector } from "react-redux";
import orderService from "../../api/orders";
import { Warning } from "@mui/icons-material";
import MKButton from "components/MKButton";
import { CiCreditCard2 } from "react-icons/ci";
import { BsPatchQuestion } from "react-icons/bs";
const lineItemStatus = {
	returnAccepted_for_refund: "Refund Accepted",
	returnAccepted_for_replacement: "Replacement Pending",
	pending: "New Order",
	ready_for_pickup: "Pending Pickup",
	returnRejected: "Return Rejected",
};
const menus = {
	pending: [{ id: 1, label: "Ready for Pickup", value: "ready_for_pickup" }],
	ready_for_pickup: [{ id: 1, label: "New Order", value: "pending" }],
	returned_for_refund: [
		{
			id: 1,
			label: "Accept Return & Refund",
			value: "returnAccepted_for_refund",
		},
		{
			id: 2,
			label: "Reject Return & Refund",
			value: "returnRejected",
		},
	],
	returned_for_replacement: [
		{
			id: 1,
			label: "Accept Return & Replacement",
			value: "returnAccepted_for_replacement",
		},
		{
			id: 2,
			label: "Reject Return",
			value: "returnRejected",
		},
	],
	returned_for_replacement: [
		{ id: 1, label: "Ready for Pickup", value: "ready_for_pickup" },
	],
};
const OrderDetails = (props) => {
	const [group, setOrderGroup] = useState(null);
	const location = useLocation();
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const [orderList, setOrders] = useState([]);
	const [selectedStatus, setSelectedStatus] = useState(null);
	const [prevStatus, setPrevStatus] = useState(null);
	const merchant = useSelector((state) => state.merchants.merchant);
	const [dialogOpen, setDialogOpen] = useState(false);
	const [selectedProduct, setSelectedProduct] = useState(null);
	const [message, setMessage] = useState("");
	const [snackOpen, setSnackOpen] = useState(false);
	const [page, setPage] = useState(1);
	const [count, setCount] = useState(8);
	const [totalCount, setTotalCount] = useState(0);
	const [queryGroups, setQueryGroups] = useState([]);
	const [orderTotal, setOrderTotal] = useState(0);
	const navigate = useNavigate();
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleSelect = (current, selected, product) => {
		console.log(selected, product);
		setSelectedStatus(selected);
		setPrevStatus(current);
		setSelectedProduct(product);
		setDialogOpen(true);
		handleClose();
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	useEffect(() => {
		if (location.state?.order) {
			setOrderGroup(location.state.order);
			console.log(location.state.order);
			let amounts = 0;
			_.map(location.state.order, (o) => {
				amounts += Number(o.totalPrice.amount);
			});
			setOrderTotal(amounts);
		}
	}, []);
	const updateOrderStatus = () => {
		setDialogOpen(false);

		orderService
			.updateOrderProductStatus(selectedProduct.id, selectedStatus.value)
			.then((result) => {
				console.log("Updated ORder Status", result);
				let next = _.map(group, (g) => {
					if (g.id == result.id) {
						return { ...g, lineItemStatus: result.lineItemStatus };
					} else {
						return g;
					}
				});
				setOrderGroup(next);
			})
			.catch((err) => {
				setMessage("Error Updating Order Status");
				setSnackOpen(true);
			});
	};

	return group ? (
		<Box style={styles.container}>
			<Container>
				<Grid container spacing={2} direction={"row"}>
					<Grid item xs={6} sm={6}>
						<Stack gap={1}>
							<Stack direction='row' gap={1}>
								<MKTypography variant='caption' color='dark'>
									Date :
								</MKTypography>
								<MKTypography variant='caption' color='dark'>
									{new Date(
										group[0]?.ProductQuery.createdAt
									).toLocaleDateString("en-US", {
										year: "numeric",
										month: "short",
										day: "numeric",
									})}
								</MKTypography>
							</Stack>
							<Stack direction='row' gap={1}>
								<MKTypography variant='caption' color='dark'>
									Order #
								</MKTypography>
								<MKTypography variant='caption' color='dark'>
									JB-{group[0]?.merchantId}-100-{group[0]?.id}
								</MKTypography>
							</Stack>
						</Stack>
					</Grid>
					<Grid
						item
						xs={6}
						sm={6}
						sx={{
							alignItems: "center",
							justifyContent: "center",
							display: "flex",
						}}
					>
						<Chip
							variant='filled'
							sx={{ backgroundColor: "#000000" }}
							icon={<TbBrandLivewire color='#ffffff' />}
							label={
								<MKTypography variant='body2' color='white'>
									{group[0]?.Order?.status}
								</MKTypography>
							}
						/>
					</Grid>
				</Grid>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={9}>
						{_.map(group, (order) => {
							return (
								<Grid
									container
									spacing={2}
									sx={{
										padding: 1,
										backgroundColor: "#fafafa",
										mt: 1,
									}}
								>
									<Grid item xs={6} sm={1}>
										<Stack direction='column' gap={1}>
											<MKTypography
												variant='caption'
												color='dark'
												fontWeight='bold'
											>
												OrderId
											</MKTypography>
											<MKTypography
												variant='caption'
												color='dark'
												sx={{ minWidth: 100 }}
											>
												{order?.id}
											</MKTypography>
										</Stack>
									</Grid>

									<Grid item xs={6} sm={1}>
										<Stack direction='column' gap={1}>
											<MKTypography
												variant='caption'
												color='dark'
												fontWeight='bold'
											>
												Color
											</MKTypography>
											<Stack
												direction={"row"}
												sx={{
													alignItems: "center",
													justifyContent: "flex-start",
													display: "flex",
												}}
												gap={1}
											>
												<Box
													sx={{
														width: 14,
														height: 14,
														backgroundColor: order?.ProductQuery?.color.hex,
													}}
												></Box>
												<MKTypography variant='caption' color='dark'>
													{order?.ProductQuery?.color.name}
												</MKTypography>
											</Stack>
										</Stack>
									</Grid>
									<Grid item xs={12} sm={4}>
										<Stack direction='column' gap={1}>
											<MKTypography
												variant='caption'
												color='dark'
												fontWeight='bold'
											>
												Product Details
											</MKTypography>
											<MKTypography variant='caption' color='dark'>
												{order.ProductQuery?.Brand.name} &nbsp;
												{order.ProductQuery?.Models.name} &nbsp;
												{order.Product.name}&nbsp;
												<strong>
													({order?.Variant ? order.Variant?.name : "Original"})
													&nbsp;X&nbsp;{order.quantity}
												</strong>
											</MKTypography>
										</Stack>
									</Grid>

									<Grid item xs={6} sm={2}>
										<Stack direction='column' gap={1}>
											<MKTypography
												variant='caption'
												color='dark'
												fontWeight='bold'
											>
												Order Status
											</MKTypography>
											<MKTypography
												variant='caption'
												color='black'
												size='small'
												aria-controls={open ? "basic-menu" : undefined}
												aria-haspopup='true'
												aria-expanded={open ? "true" : undefined}
												onClick={handleClick}
											>
												{lineItemStatus[order.lineItemStatus]}
											</MKTypography>
										</Stack>
									</Grid>
									<Grid item xs={6} sm={2}>
										<Stack direction='column' gap={1}>
											<MKTypography
												variant='caption'
												color='dark'
												fontWeight='bold'
											>
												Total
											</MKTypography>
											<MKTypography
												variant='caption'
												color='dark'
												fontWeight='bold'
											>
												AED&nbsp;
												{order.totalPrice.amount - order.markup}
											</MKTypography>
										</Stack>
									</Grid>
									<Grid
										item
										xs={12}
										sm={2}
										sx={{
											alignItems: "center",
											justifyContent: "center",
											display: "flex",
										}}
									>
										<Stack direction={"column"}>
											<MKTypography
												variant='caption'
												color='dark'
												fontWeight='bold'
											>
												Change Status
											</MKTypography>
											{menus[order.lineItemStatus] ? (
												_.map(menus[order.lineItemStatus], (status) => {
													if (status.label) {
														return (
															<div
																onClick={() =>
																	handleSelect(
																		order.lineItemStatus,
																		status,
																		order
																	)
																}
																style={{ cursor: "pointer" }}
															>
																<MKTypography
																	variant='caption'
																	color='success'
																	fontWeight='bold'
																>
																	{status.label}
																</MKTypography>
															</div>
														);
													}
												})
											) : (
												<MKTypography
													variant='caption'
													color='dark'
													sx={{ mt: 1 }}
												>
													--:--
												</MKTypography>
											)}
										</Stack>
									</Grid>
									{order.ProductQuery.queryText && (
										<Grid item xs={12} sm={12}>
											<MKTypography variant='body2' color='error'>
												Remarks: {order.ProductQuery.queryText}
											</MKTypography>
										</Grid>
									)}

									<Grid item xs={12} sm={12}>
										<Divider sx={{ width: "100%" }} />
									</Grid>
								</Grid>
							);
						})}
					</Grid>
					<Grid item xs={12} sm={3}>
						<Paper sx={styles.paper} elevation={0}>
							<Grid container spacing={1}>
								<Grid item xs={6} sm={6}>
									<Stack gap={1}>
										<MKTypography
											variant='caption'
											color='dark'
											fontWeight='bold'
										>
											Client Id
										</MKTypography>
										<MKTypography variant='caption' color='dark'>
											J-1001-{group[0]?.ProductQuery?.storeId}
										</MKTypography>
									</Stack>
								</Grid>
								<Grid item xs={6} sm={6}>
									<Stack gap={1}>
										<MKTypography
											variant='caption'
											color='dark'
											fontWeight='bold'
										>
											Total Amount
										</MKTypography>

										<MKTypography
											variant='caption'
											color='dark'
											fontWeight='bold'
										>
											AED &nbsp;{orderTotal.toFixed(2)}
										</MKTypography>
									</Stack>
								</Grid>
							</Grid>
							<Grid container spacing={1} sx={{ mt: 1 }}>
								<Grid item xs={6} sm={6}>
									<Stack gap={1}>
										<MKTypography
											variant='caption'
											color='dark'
											fontWeight='bold'
										>
											<CiCreditCard2 />
											&nbsp; Mode
										</MKTypography>

										<MKTypography variant='caption' color='dark'>
											{group[0]?.Order.paymentModeTitle}
										</MKTypography>
									</Stack>
								</Grid>
								<Grid item xs={6} sm={6}>
									<Stack gap={1}>
										<MKTypography
											variant='caption'
											color='dark'
											fontWeight='bold'
										>
											<BsPatchQuestion />
											&nbsp; Status
										</MKTypography>

										<MKTypography variant='caption' color='dark'>
											{group[0]?.Order.paymentStatus}
										</MKTypography>
									</Stack>
								</Grid>
							</Grid>
						</Paper>
					</Grid>
				</Grid>
			</Container>
			<Dialog
				open={dialogOpen}
				onClose={() => setDialogOpen(false)}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<DialogTitle id='alert-dialog-title'>
					<MKTypography variant='body2' color='dark'>
						<Warning /> Order Status Update
					</MKTypography>
				</DialogTitle>
				<DialogContent>
					<DialogContentText id='alert-dialog-description'>
						<MKTypography variant='caption' color='dark'>
							You are updating the order for {selectedProduct?.Product?.name}
							&nbsp; status from <strong>{prevStatus}</strong> &nbsp;to&nbsp;
							<strong>{selectedStatus?.label}</strong>. <br />
							Proceed?
						</MKTypography>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<MKButton
						onClick={() => setDialogOpen(false)}
						variant='contained'
						color='error'
						autoFocus
					>
						NO
					</MKButton>
					<MKButton
						onClick={updateOrderStatus}
						variant='contained'
						color='black'
					>
						Yes
					</MKButton>
				</DialogActions>
			</Dialog>
		</Box>
	) : (
		<></>
	);
};

const styles = {
	container: {
		width: "100%",
		padding: 30,
		backgroundColor: "#fffff",
		alignItems: "center",
	},
	paper: {
		width: "100%",

		backgroundColor: "#fafafa",
		padding: 5,
		mt: 1,
	},
};
export default OrderDetails;
