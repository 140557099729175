import {
	Add,
	AddShoppingCart,
	ArrowDropDown,
	Delete,
	Expand,
	Filter,
	FilterList,
	InfoOutlined,
	Shop,
	Shop2,
} from "@mui/icons-material";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Autocomplete,
	Avatar,
	Box,
	Chip,
	Container,
	Dialog,
	DialogContent,
	Grid,
	IconButton,
	Pagination,
	Paper,
	Snackbar,
	Stack,
	Tooltip,
} from "@mui/material";
import productQuery from "../../api/product-query";
import MKBreadCrumbs from "components/MKBreadCrumbs";
import MKInput from "components/MKInput";
import MKTypography from "components/MKTypography";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FiBell, FiLoader, FiTarget } from "react-icons/fi";
import MKButton from "components/MKButton";
import { useNavigate } from "react-router-dom";
import { GiBandaged, GiCat } from "react-icons/gi";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";
import { addDays } from "date-fns";
import { BiSolidNotification } from "react-icons/bi";
import {
	Aperture,
	ArrowCounterClockwise,
	CheckCircle,
	Cube,
	Info,
} from "@phosphor-icons/react";
import MKLoader from "components/MKLoader";
import MKNodata from "components/MKNodata";
import { setCart } from "features/cartSlice";
import MKSwipable from "components/MKSwipable";
import cartService from "../../api/cart";
import orderService from "../../api/orders";
import MyOrderList from "./MyOrderList";
import { DataGrid } from "@mui/x-data-grid";
import { IoDocumentAttach } from "react-icons/io5";
const breadcrumb = [
	{ id: 1, name: "Home", link: "/" },
	{ id: 2, name: "My Queries", link: "" },
];
const statuses = {
	RESPONSE_AWAITING: {
		label: "Waiting Responses",
		description:
			"Active queries, that are waiting for responses. Some of your queries might have expired. Those are availbale under expired queries",
	},
	MERCHANT_RESPONDED_QUOTE: {
		label: "Quote Recieved",
		description: "Some suppliers have responded to your queries",
	},
	QUERY_EXPIRED: {
		label: "Expired Queries",
		description:
			"Your query has expired. Either no responses recieved or you haven't placed orders for recieved quotes",
	},
	Orders: {
		label: "Orders",
		description: "Your Orders",
	},
};
const queryStatus = {
	CART: "Cart",
	RESPONSE_AWAITING: "Awaiting",
	MERCHANT_RESPONDED_QUOTE: "Quote",
	RESPONSE_CONVERTED_TO_ORDER: "Ordered",
	DELETED: "Removed",
	QUERY_QUEUED: "Queued",
	QUERY_EXPIRED: "Expired",
};
const variant = {
	ORIGINAL: "Original",
	FIRST_COPY: "Other",
};
const StoreQueriesList = (props) => {
	const [queries, setQueries] = useState([]);
	const [expandAll, setExpandAll] = useState(true);
	const querySet = useSelector((state) => state.stores.queries);
	const store = useSelector((state) => state.stores.store);
	const [totalQueries, setTotalQueries] = useState(0);
	const [totalQuotes, setTotalQuotes] = useState(0);
	const [totalWaiting, setTotalWaiting] = useState(0);
	const [totalExpired, setExpired] = useState(0);
	const [totalOrders, setTotalOrders] = useState(0);
	const [page, setPage] = useState(1);
	const [count, setCount] = useState(5);
	const navigate = useNavigate();
	const [refreshToken, setRefreshToken] = useState(Math.random() * 10000);
	const [showCount, setShowCount] = useState(totalWaiting.length / 10);
	const [status, setStatus] = useState("MERCHANT_RESPONDED_QUOTE");
	const [loading, setLoading] = useState(false);
	const cartProducts = useSelector((state) => state.cart.products);
	const dispatch = useDispatch();
	const [swipe, setSwipe] = useState(false);
	const [filterSet, setFilter] = useState(false);
	const [open, setOpen] = useState(false);
	const [message, setMessage] = useState("");
	const [addedToCart, setAddedToCart] = useState(cartProducts);
	const [orderStatus, setOrderStatus] = useState("pending");
	const [storeOrders, setStoreOrders] = useState([]);
	const [expired, setQueryExpired] = useState({});
	const [imageToShow, setImageToShow] = useState("");
	const [showImageDialog, setShowImageDialog] = useState(false);
	const notifications = useSelector(
		(state) => state.notifications.notifications
	);
	const [dates, setDates] = useState([
		{
			startDate: new Date(),
			endDate: addDays(new Date(), 7),
			key: "selection",
		},
	]);
	const fetchMyOrders = () => {
		const startDate = new Date(dates[0].startDate).toISOString();
		const endDate = new Date(dates[0].endDate).toISOString();
		setLoading(true);
		orderService
			.fetchStoreOrders(store.id, page, count, startDate, endDate)
			.then((result) => {
				setLoading(false);
				const { totalCount, orders } = result;
				setShowCount(totalCount);
				const myOrders = _.map(orders, (order) => {
					return {
						id: order.id,
						storeId: order.storeId,

						status: order.status,
						dateCreated: order.dateCreated,
						cashbackTotal: Number(order.cashbackTotal).toFixed(2),
						deliveryChargeTotal: order.deliveryChargeTotal,
						total: order.total,
						paymentMode: order.paymentMode,
						paymentModeTitle: order.paymentModeTitle,
						paymentStatus: order.paymentStatus,
						lineItems: _.map(order.orderLineItems, (item) => {
							return {
								queryGroupName: item.ProductQuery.queryGroupName,
								totalPrice: item.totalPrice,
								quantity: item.ProductQuery.quantity,
								color: item.ProductQuery.color,
								qualityVariant: item.ProductQuery.qualityVariant,
								brand: item.ProductQuery.Brand.name,
								model: item.ProductQuery.Models.name,
								product: item.Product.name,
								id: item.id,
								storeId: item.storeId,
								merchantId: item.merchantId,
								status: item.lineItemStatus,
								cashback: Number(item.cashback).toFixed(2),
								productQueryId: item.ProductQuery.id,
								variantId: item.variantId,
								lineItemStatus: item.lineItemStatus,
								order: order,
							};
						}),
					};
				});

				setStoreOrders(myOrders);
				refresh();
			})
			.catch((err) => {
				setLoading(false);
				setStoreOrders([]);
			});
	};
	const refresh = () => {
		setRefreshToken(Math.random() * 10000);
	};

	const handlePageChange = (e, val) => {
		setPage(val);
	};
	const addToCart = (query) => {
		let currentCart = [...cartProducts];
		console.log("QUERY TO ADD", query);
		const payload = {
			instructions: "",

			ProductQuery: { connect: { id: query.id } },

			Store: { connect: { id: store.id } },
			BestQuote: { connect: { id: query.BestQuote[0].id } },
		};
		console.log(payload);
		cartService
			.addToCart(payload)
			.then((response) => {
				currentCart.push(response);
				dispatch(setCart(currentCart));
				setMessage("Query added to cart!");
				setOpen(true);
				setAddedToCart((prev) => [...prev, response]);
			})
			.catch((err) => {
				setMessage("Failed to add query to cart!");
				setOpen(true);
				console.log("Error", err);
			});
	};

	useEffect(() => {
		if (status == "Orders" && store) {
			fetchMyOrders();
		} else if (store) {
			if (filterSet) {
				searchBetweenDates();
			} else {
				setLoading(true);
				productQuery
					.getProductQueries(store.id, page, count, status)
					.then((result) => {
						const queries = result.queries;
						setShowCount(result.totalCount);
						const grpBy = _.groupBy(queries, (query) => {
							return query.queryGroupId;
						});
						setQueries(grpBy);
						console.log("GROUP", grpBy);
						setLoading(false);
					})
					.catch((err) => {
						console.log(err);
						setLoading(false);
					});
			}
		}
	}, [page, status, notifications]);

	const searchBetweenDates = () => {
		setLoading(true);
		setSwipe(false);
		setFilter(true);
		productQuery
			.searchBetweenDates(
				store.id,
				page,
				count,
				status,
				new Date(dates[0].startDate).toISOString(),
				new Date(dates[0].endDate).toISOString()
			)
			.then((result) => {
				const queries = result.queries;
				setShowCount(result.totalCount);
				const grpBy = _.groupBy(queries, (query) => {
					return query.queryGroupId;
				});
				setQueries(grpBy);

				setLoading(false);
			})
			.catch((err) => {
				console.log(err);
				setLoading(false);
			});
	};
	useEffect(() => {
		if (status == "Orders" && store) {
			fetchMyOrders();
		} else if (store)
			if (filterSet) {
				searchBetweenDates();
			} else {
				productQuery.storeQueryStats(store.id).then((stats) => {
					const { total, quotesRecieved, waiting, expired, orders } = stats;
					setTotalQueries(total);
					setTotalQuotes(quotesRecieved);
					setTotalWaiting(waiting);
					setExpired(expired);
					setTotalOrders(orders);
				});
			}
	}, [status, notifications]);

	const handleShowImage = (image) => {
		setImageToShow(image);
		setShowImageDialog(true);
	};

	const productQueryColumns = [
		{
			field: "image",
			headerName: "",
			width: 100,
			renderCell: (params) => {
				return params.row.image ? (
					<IconButton onClick={() => handleShowImage(params.row.image)}>
						<IoDocumentAttach />
					</IconButton>
				) : (
					<></>
				);
			},
		},
		{
			field: "brand",
			headerName: "Brand",
			width: 100,
			renderCell: (params) => {
				return (
					<MKTypography variant='body2' color='dark'>
						{params.row?.Brand?.name}
					</MKTypography>
				);
			},
		},
		{
			field: "model",
			headerName: "Model",
			width: 150,
			renderCell: (params) => {
				return (
					<MKTypography variant='body2' color='dark'>
						{params.row?.Models?.name}
					</MKTypography>
				);
			},
		},
		{
			field: "color",
			headerName: "Color",
			width: 150,
			renderCell: (params) => {
				return (
					<Stack
						direction={"row"}
						sx={{
							alignItems: "center",
							justifyContent: "center",
							display: "flex",
						}}
						gap={1}
					>
						<Box
							sx={{
								width: 14,
								height: 14,
								backgroundColor: params.row?.color.hex,
							}}
						></Box>
						<MKTypography variant='body2' color='dark'>
							{params.row?.color.name}
						</MKTypography>
					</Stack>
				);
			},
		},
		{
			field: "product",
			headerName: "Product",
			width: 220,
			renderCell: (params) => {
				return (
					<MKTypography variant='body2' color='dark'>
						{params.row?.Products?.name}
					</MKTypography>
				);
			},
		},
		{
			field: "variant",
			headerName: "Quality",
			width: 100,
			renderCell: (params) => {
				return (
					<MKTypography variant='body2' color='dark'>
						{params.row?.variantId
							? params.row?.Variant?.name
							: variant[params.row?.qualityVariant]}
					</MKTypography>
				);
			},
		},
		{
			field: "quantity",
			headerName: "Quantity",
			width: 150,
			renderCell: (params) => {
				return (
					<MKTypography variant='body2' color='dark'>
						{params.row?.quantity}
					</MKTypography>
				);
			},
		},
		{
			field: "status",
			headerName: "Status",
			width: 150,
			renderCell: (params) => {
				return (
					<MKTypography variant='body2' color='dark'>
						{queryStatus[params.row?.queryStatus]}
					</MKTypography>
				);
			},
		},
		{
			field: "bestQuote",
			headerName: "Quote",
			width: 100,
			renderCell: (params) => {
				const best = params.row?.BestQuote;
				let total = undefined;

				if (best && best[0]) {
					let bestQ = best[0];
					const pricePerUnit = Number(
						bestQ.Response?.quotedPricePerUnit.amount
					);
					const ourMarkup = Number(bestQ.Response?.markup.amount);
					total = pricePerUnit + ourMarkup;
				}

				return (
					<MKTypography variant='body2' color='dark' fontWeight='bold'>
						{total ? `AED  ${total.toFixed(2)}` : "-:-"}
					</MKTypography>
				);
			},
		},
		{
			field: "actions",
			headerName: "",
			width: 100,
			renderCell: (params) => {
				const cart = addedToCart.map((prod) => {
					return prod.productQueryId;
				});
				return (
					<Stack direction={"row"}>
						{cart.includes(params.row.id) ? (
							<MKTypography variant='caption' color='dark'>
								In Cart
							</MKTypography>
						) : params.row.queryStatus == "QUERY_EXPIRED" ? (
							<Tooltip title='This Quote is expired. You can resubmit the query.'>
								<IconButton size='small'>
									<ArrowCounterClockwise
										sx={{
											color: "#33B186",
											fontSize: 18,
										}}
										fontSize='18px'
									/>
								</IconButton>
							</Tooltip>
						) : params.row.queryStatus == "MERCHANT_RESPONDED_QUOTE" ? (
							<Tooltip title='Add to Cart'>
								<IconButton size='small' onClick={() => addToCart(params.row)}>
									<AddShoppingCart
										sx={{
											color: "green",
											fontSize: 18,
										}}
										fontSize='18px'
									/>
								</IconButton>
							</Tooltip>
						) : (
							<></>
						)}
					</Stack>
				);
			},
		},
	];
	return (
		<Container sx={styles.container}>
			<MKBreadCrumbs crumbs={breadcrumb} />
			<Snackbar
				open={open}
				autoHideDuration={4000}
				onClose={() => setOpen(false)}
				message={
					<MKTypography variant='caption' color='white'>
						{message}
					</MKTypography>
				}
				action={
					<MKButton
						iconOnly
						color='white'
						circular
						size='small'
						onClick={() => setOpen(false)}
					>
						<CheckCircle />
					</MKButton>
				}
			/>
			<Dialog open={showImageDialog} onClose={() => setShowImageDialog(false)}>
				<DialogContent>
					<div
						style={{ width: "500px", height: "500px", objectFit: "contain" }}
					>
						<img src={imageToShow} width='100%' height='100%' />
					</div>
				</DialogContent>
			</Dialog>
			<Grid container spacing={2} key={querySet.length}>
				<Container
					sx={{
						alignItems: "center",
						justifyContent: "center",
						display: "flex",
					}}
				>
					<Grid item xs={12} sm={12}>
						<Grid container spacing={1}>
							<Grid item xs={3} sm={3}>
								<div
									onClick={() => {
										setPage(1);
										setStatus("MERCHANT_RESPONDED_QUOTE");
									}}
									style={{ cursor: "pointer" }}
								>
									<Paper
										sx={{
											backgroundColor: "#FAFAEE",
											p: 2,
											alignItems: "center",
											justifyContent: "center",
											display: "flex",
											flexDirection: "row",
											borderLeft:
												status == "MERCHANT_RESPONDED_QUOTE"
													? "4px solid  #4BAF51"
													: "none",
										}}
										elevation={0}
									>
										<FiBell style={{ color: "#DDDD3A", fontSize: 40 }} />
										<Stack
											direction='column'
											sx={{
												width: "100%",
												alignItems: "flex-end",
												justifyContent: "space-between",
												display: "flex",
											}}
										>
											<MKTypography color='dark' variant='h3'>
												{totalQuotes}
											</MKTypography>
											<MKTypography color='dark' variant='h4'>
												Quotes Recieved
											</MKTypography>
										</Stack>
									</Paper>
								</div>
							</Grid>
							<Grid item xs={3} sm={3}>
								<div
									onClick={() => {
										setPage(1);
										setStatus("RESPONSE_AWAITING");
									}}
									style={{ cursor: "pointer" }}
								>
									<Paper
										sx={{
											backgroundColor: "#F8F5FF",
											p: 2,
											alignItems: "center",
											justifyContent: "center",
											display: "flex",
											flexDirection: "row",
											borderLeft:
												status == "RESPONSE_AWAITING"
													? "4px solid  #4BAF51"
													: "none",
										}}
										elevation={0}
									>
										<FiLoader style={{ color: "#7740E5", fontSize: 40 }} />
										<Stack
											direction='column'
											sx={{
												width: "100%",
												alignItems: "flex-end",
												justifyContent: "space-between",
												display: "flex",
											}}
										>
											<MKTypography color='dark' variant='h3'>
												{totalWaiting}
											</MKTypography>
											<MKTypography color='dark' variant='h4'>
												Waiting for Quotes
											</MKTypography>
										</Stack>
									</Paper>
								</div>
							</Grid>
							<Grid item xs={3} sm={3}>
								<div
									onClick={() => {
										setPage(1);
										setStatus("QUERY_EXPIRED");
									}}
									style={{ cursor: "pointer" }}
								>
									<Paper
										sx={{
											backgroundColor: "#FFF1F6",
											p: 2,
											alignItems: "center",
											justifyContent: "center",
											display: "flex",
											flexDirection: "row",
											borderLeft:
												status == "QUERY_EXPIRED"
													? "4px solid  #4BAF51"
													: "none",
										}}
										elevation={0}
									>
										<GiBandaged style={{ color: "#F2727E", fontSize: 40 }} />
										<Stack
											direction='column'
											sx={{
												width: "100%",
												alignItems: "flex-end",
												justifyContent: "space-between",
												display: "flex",
											}}
										>
											<MKTypography color='dark' variant='h3'>
												{totalExpired}
											</MKTypography>
											<MKTypography color='dark' variant='h4'>
												Expired Queries
											</MKTypography>
										</Stack>
									</Paper>
								</div>
							</Grid>
							<Grid item xs={3} sm={3}>
								<div
									onClick={() => {
										setPage(1);
										setStatus("Orders");
									}}
									style={{ cursor: "pointer" }}
								>
									<Paper
										sx={{
											backgroundColor: "#F3F8FF",
											p: 2,
											alignItems: "center",
											justifyContent: "center",
											display: "flex",
											flexDirection: "row",
											borderLeft:
												status == "Orders" ? "4px solid  #4BAF51" : "none",
										}}
										elevation={0}
									>
										<BiSolidNotification
											style={{ color: "#94B5E6", fontSize: 40 }}
										/>
										<Stack
											direction='column'
											sx={{
												width: "100%",
												alignItems: "flex-end",
												justifyContent: "space-between",
												display: "flex",
											}}
										>
											<MKTypography color='dark' variant='h3'>
												{totalOrders}
											</MKTypography>
											<MKTypography color='dark' variant='h4'>
												Orders
											</MKTypography>
										</Stack>
									</Paper>
								</div>
							</Grid>
						</Grid>
					</Grid>
				</Container>
				<Grid
					item
					xs={12}
					sm={12}
					sx={{
						alignItems: "center",
						justifyContent: "center",
						display: "flex",
					}}
				>
					{Object.keys(queries).length && status != "Orders" ? (
						<Pagination
							count={Math.ceil(showCount / count)}
							page={page}
							onChange={handlePageChange}
						/>
					) : Object.keys(storeOrders).length && status == "Orders" ? (
						<Pagination
							count={Math.ceil(showCount / count)}
							page={page}
							onChange={handlePageChange}
						/>
					) : (
						<></>
					)}
				</Grid>
				<Grid item xs={12} sm={12}>
					<Stack gap={2}>
						<Paper sx={styles.filter} elevation={0}>
							<MKTypography
								variant='body1'
								color='dark'
								fontWeight='bold'
								style={{
									alignItems: "center",
									justifyContent: "center",
									display: "flex",
								}}
							>
								{statuses[status].label}
								<Tooltip title={statuses[status].description}>
									<InfoOutlined sx={{ ml: 1 }} />
								</Tooltip>
							</MKTypography>
							<div
								style={{
									width: 50,
									display: "flex",
									justifyContent: "flex-start",
								}}
							>
								{loading && <MKLoader size={32} />}
							</div>
							<MKTypography variant='caption' color='dark' fontWeight='bold'>
								Total:&nbsp;&nbsp;
								<Chip
									label={
										<MKTypography
											variant='caption'
											color='primary'
											fontWeight='bold'
										>
											{showCount}
										</MKTypography>
									}
									size='small'
								/>
							</MKTypography>
							<Stack
								gap={5}
								direction={"row"}
								sx={{ alignItems: "center", justifyContent: "flex-end" }}
							>
								<MKInput
									size='xs'
									placeholder='search'
									label='Search...'
									sx={{ width: 350 }}
								/>

								<MKTypography variant='caption' color='dark'>
									<IconButton onClick={() => setSwipe(!swipe)}>
										<FilterList />
									</IconButton>
									Filter
								</MKTypography>
								<MKTypography variant='caption' color='dark'>
									<IconButton onClick={refresh}>
										<ArrowCounterClockwise />
									</IconButton>
									Refresh
								</MKTypography>

								<MKButton
									variant='contained'
									color='dark'
									onClick={() => navigate("/query")}
								>
									<Add /> Add a Query
								</MKButton>
							</Stack>
						</Paper>
						{Object.keys(queries).length == 0 && status != "Orders" ? (
							<Paper
								sx={{
									p: 3,
									width: "100%",
									height: "40vh",
									alignItems: "center",
									justifyContent: "center",
									display: "flex",
								}}
								elevation={0}
							>
								<MKNodata size={100} />
								<MKTypography variant='h2' color='dark'>
									Nothing to Show
								</MKTypography>
							</Paper>
						) : (
							<></>
						)}
						{storeOrders.length && status == "Orders" && !loading ? (
							<MyOrderList list={storeOrders} key={refreshToken} />
						) : loading ? (
							<Container
								sx={{
									alignItems: "center",
									justifyContent: "center",
									display: "flex",
									minHeight: "20vh",
								}}
							>
								<MKLoader size={100} />
							</Container>
						) : (
							<></>
						)}
						{status != "Orders" &&
							_.map(queries, (queryGroup, key) => {
								return (
									<Stack sx={{ backgroundColor: "#fafafa", p: 1 }}>
										<Accordion
											defaultExpanded={false}
											sx={{ bgcolor: "#fcfcfc" }}
											elevation={0}
										>
											<AccordionSummary expandIcon={<ArrowDropDown />}>
												<Stack
													gap={2}
													direction={"row"}
													sx={{
														alignItems: "center",
														justifyContent: "flex-start",
														display: "flex",
														width: "100%",
													}}
												>
													<MKTypography
														variant='caption'
														color='dark'
														style={{ marginRight: 10 }}
														fontWeight='bold'
													>
														{new Date(
															queryGroup[0].createdAt
														).toLocaleDateString("en-US", {
															year: "numeric",
															month: "short",
															day: "numeric",
														})}
													</MKTypography>
													<MKTypography
														variant='body2'
														color='dark'
														fontWeight='bold'
													>
														{queryGroup[0]?.queryGroupName}
													</MKTypography>
													<MKTypography
														variant='caption'
														color='primary'
														style={{ marginRight: 10 }}
													>
														({queryGroup.length} &nbsp; Products)
													</MKTypography>
												</Stack>
											</AccordionSummary>
											<AccordionDetails>
												<div style={{ width: "100%" }}>
													<div style={{ width: "100%" }}>
														<DataGrid
															columns={productQueryColumns}
															rows={queryGroup}
															pageSizeOptions={[5]}
															disableRowSelectionOnClick
														/>
													</div>
												</div>

												{/* <Stack>
													{_.map(queryGroup, (query) => {
														return (
															<ProductQuery
																query={query}
																addToCart={addToCart}
																cart={addedToCart.map((prod) => {
																	return prod.productQueryId;
																})}
															/>
														);
													})}
												</Stack> */}
											</AccordionDetails>
										</Accordion>
									</Stack>
								);
							})}
					</Stack>
				</Grid>
				<Grid
					item
					xs={12}
					sm={12}
					sx={{
						alignItems: "center",
						justifyContent: "center",
						display: "flex",
					}}
				>
					{Object.keys(queries).length && status != "Orders" ? (
						<Pagination
							count={Math.ceil(showCount / count)}
							page={page}
							onChange={handlePageChange}
						/>
					) : Object.keys(storeOrders).length && status == "Orders" ? (
						<Pagination
							count={Math.ceil(showCount / count)}
							page={page}
							onChange={handlePageChange}
						/>
					) : (
						<></>
					)}
				</Grid>
			</Grid>
			<MKSwipable open={swipe} key={swipe} onClose={() => setSwipe(false)}>
				<Stack sx={{ width: "100%" }} gap={2}>
					<DateRangePicker
						onChange={(item) => setDates([item.selection])}
						showSelectionPreview={true}
						moveRangeOnFirstSelection={false}
						months={2}
						ranges={dates}
						direction='horizontal'
					/>
					<MKButton variant='text' color='dark' onClick={searchBetweenDates}>
						Search
					</MKButton>
					<MKButton
						variant='text'
						color='error'
						onClick={() => {
							setFilter(false);
							setPage(1);
							setSwipe(false);
						}}
					>
						Clear Date Filter
					</MKButton>
				</Stack>
			</MKSwipable>
		</Container>
	);
};
export default StoreQueriesList;
// const ProductQuery = (props) => {
// 	const [query, setQuery] = useState(props.query);
// 	const [currency, setCurrency] = useState("AED");
// 	const [amount, setAmount] = useState(0);
// 	const [expired, setExpired] = useState(false);
// 	useEffect(() => {
// 		console.log("CART", props.cart, query);
// 		if (query.queryStatus == "MERCHANT_RESPONDED_QUOTE") {
// 			let pricePerUnit = 0;
// 			let ourMarkup = 0;

// 			const best = props.query?.QueryResponse[0]?.BestQuote;
// 			if (best?.length) {
// 				const bestQ = best[0];
// 				pricePerUnit = bestQ.Response?.quotedPricePerUnit.amount;
// 				ourMarkup = bestQ.Response?.markup.amount;
// 				const now = new Date().getTime();
// 				const createdAt = new Date(bestQ.Response?.createdAt).getTime();
// 				const timeDifference = now - createdAt;
// 				const hoursDifference = timeDifference / (1000 * 60 * 60);
// 				if (hoursDifference > 24) {
// 					console.log("Setting Expired");
// 					setExpired(true);
// 				} else {
// 					setExpired(false);
// 				}
// 			}
// 			const total = Number(pricePerUnit) + Number(ourMarkup);
// 			console.log("SUM TOTAL", total);
// 			setAmount(total);
// 		}
// 	}, []);
// 	const variatnt = {
// 		ORIGINAL: "Original",
// 		FIRST_COPY: "Other",
// 	};
// 	const status = {
// 		CART: "Cart",
// 		RESPONSE_AWAITING: "Awaiting",
// 		MERCHANT_RESPONDED_QUOTE: "Quote",
// 		RESPONSE_CONVERTED_TO_ORDER: "Ordered",
// 		DELETED: "Removed",
// 		QUERY_QUEUED: "Queued",
// 	};

// 	return (
// 		<Paper sx={styles.queryRow} elevation={0}>
// 			<Box style={{ flexGrow: 1 }}>
// 				<Stack direction='row' gap={8} sx={{ mt: 2 }}>
// 					<Stack direction='column' sx={{ minWidth: 50 }}>
// 						<MKTypography variant='caption' color='dark'>
// 							Brand
// 						</MKTypography>
// 						<MKTypography variant='caption' color='dark'>
// 							{query?.Brand?.name}
// 						</MKTypography>
// 					</Stack>
// 					<Stack direction='column' sx={{ minWidth: 100 }}>
// 						<MKTypography variant='caption' color='dark'>
// 							Model
// 						</MKTypography>
// 						<MKTypography variant='caption' color='dark'>
// 							{query?.Models?.name}
// 						</MKTypography>
// 					</Stack>

// 					<Stack direction='column' sx={{ minWidth: 110 }}>
// 						<MKTypography variant='caption' color='dark'>
// 							Colour
// 						</MKTypography>
// 						<Stack direction={"row"} gap={1}>
// 							<MKTypography variant='caption' color='dark'>
// 								{query?.color?.name}
// 							</MKTypography>
// 							<Box
// 								sx={{
// 									width: 14,
// 									height: 14,
// 									backgroundColor: query?.color?.hex,
// 								}}
// 							/>
// 						</Stack>
// 					</Stack>
// 					<Stack direction='column' sx={{ minWidth: 150 }}>
// 						<MKTypography variant='caption' color='dark'>
// 							Product
// 						</MKTypography>
// 						<MKTypography variant='caption' color='dark'>
// 							{query?.Products?.name}
// 						</MKTypography>
// 					</Stack>
// 					<Stack direction='column' sx={{ minWidth: 50 }}>
// 						<MKTypography variant='caption' color='dark'>
// 							Quality
// 						</MKTypography>
// 						<MKTypography variant='caption' color='dark'>
// 							{query?.variantId
// 								? query?.Variant?.name
// 								: variatnt[query?.qualityVariant]}
// 						</MKTypography>
// 					</Stack>
// 					<Stack
// 						direction='column'
// 						sx={{
// 							minWidth: 30,
// 							alignItems: "center",
// 							justifyContent: "center",
// 							display: "flex",
// 						}}
// 					>
// 						<MKTypography variant='caption' color='dark'>
// 							Quantity
// 						</MKTypography>
// 						<MKTypography variant='caption' color='dark'>
// 							{query?.quantity}
// 						</MKTypography>
// 					</Stack>
// 					<Stack direction='column' sx={{ minWidth: 50 }}>
// 						<MKTypography variant='caption' color='dark'>
// 							Status
// 						</MKTypography>
// 						<MKTypography variant='caption' color='dark'>
// 							{status[query?.queryStatus]}
// 						</MKTypography>
// 					</Stack>

// 					<Stack direction='column' sx={{ minWidth: 100 }}>
// 						<MKTypography variant='caption' color='dark' fontWeight='bold'>
// 							Best Quote
// 						</MKTypography>
// 						<MKTypography variant='caption' color='dark' fontWeight='bold'>
// 							{query.queryStatus == "MERCHANT_RESPONDED_QUOTE" && amount
// 								? currency + " " + amount
// 								: "--"}
// 						</MKTypography>
// 					</Stack>
// 				</Stack>
// 			</Box>
// 			<Stack
// 				direction='column'
// 				sx={{ alignItems: "center", display: "flex", flexGrow: 0, mt: 2 }}
// 			>
// 				<MKTypography variant='caption' color='dark'>
// 					Actions
// 				</MKTypography>
// 				<Stack direction={"row"}>
// 					{props.cart.includes(query.id) ? (
// 						<MKTypography variant='caption' color='dark'>
// 							In Cart
// 						</MKTypography>
// 					) : expired || !amount ? (
// 						<Tooltip title='This Quote is expired as it passed 24 hrs since you recieved the quote. You can resubmit the query.'>
// 							<IconButton size='small'>
// 								<ArrowCounterClockwise
// 									sx={{
// 										color: "#33B186",
// 										fontSize: 18,
// 									}}
// 									fontSize='18px'
// 								/>
// 							</IconButton>
// 						</Tooltip>
// 					) : (
// 						<Tooltip title='Add to Cart'>
// 							<IconButton size='small' onClick={() => props.addToCart(query)}>
// 								<AddShoppingCart
// 									sx={{
// 										color: "green",
// 										fontSize: 18,
// 									}}
// 									fontSize='18px'
// 								/>
// 							</IconButton>
// 						</Tooltip>
// 					)}
// 				</Stack>
// 			</Stack>
// 		</Paper>
// 	);
// };
const styles = {
	container: {
		minHeight: "100vh",
		width: "100%",
		padding: 2,
	},
	sideBar: {
		minHeight: "100vh",
		width: "100%",
		padding: 2,
		backgroundColor: "#FBFCFA",
	},
	filter: {
		minHeight: "5vh",
		width: "100%",
		padding: 2,
		backgroundColor: "#FCFCFC",
		alignItems: "center",
		justifyContent: "space-between",
		display: "flex",
		flexDirection: "row",
	},
	queryRow: {
		width: "100%",
		padding: 2,
		alignItems: "center",
		justifyContent: "space-between",
		display: "flex",
		flexDirection: "row",
		marginTop: 1,
		backgroundColor: "#FEFCFC",
	},
};
