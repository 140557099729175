import React, { useState } from "react";
import { Grid, Paper, Stack } from "@mui/material";
import MKTypography from "components/MKTypography";

export const OrderHeader = (props) => {
	const [orderDate, setOrderDate] = useState(props.orderDate);
	const [orderTotal, setOrderTotal] = useState(props.orderTotal);
	const [orderId, setOrderId] = useState(props.orderId);
	const [cashback, setCashback] = useState(props.cashback);
	const [deliveryCharge, setDeliveryCharge] = useState(props.deliveryCharge);

	return (
		<Paper
			sx={{
				p: 1,
				backgroundColor: "#fafafa",
				minHeight: "10vh",
				width: "100%",
			}}
			elevation={0}
		>
			<Grid container spacing={1}>
				<Grid item xs={6} sm={2}>
					<Stack gap={1}>
						<MKTypography variant='caption' color='dark'>
							Order Date
						</MKTypography>
						<MKTypography variant='caption' color='dark'>
							{new Date(orderDate).toLocaleDateString("en-US", {
								year: "numeric",
								month: "short",
								day: "numeric",
							})}
						</MKTypography>
					</Stack>
				</Grid>
				<Grid item xs={6} sm={6} sx={{ flexGrow: 1 }}>
					<Stack gap={1}>
						<MKTypography variant='caption' color='dark'>
							Order Total
						</MKTypography>
						{cashback ? (
							<Stack>
								<Stack direction={"row"} gap={1}>
									<MKTypography variant='caption' color='dark'>
										AED
									</MKTypography>
									<MKTypography
										variant='caption'
										color='error'
										sx={{
											textDecoration: "line-through",
											marginBottom: "8px",
										}}
									>
										{orderTotal}
									</MKTypography>
									<MKTypography variant='caption' color='dark'>
										{Number(orderTotal - cashback).toFixed(2)}
									</MKTypography>
								</Stack>
								<MKTypography variant='caption' color='dark'>
									( Saved&nbsp;
									<strong>AED&nbsp;{cashback}</strong>
									&nbsp;as cashback)
								</MKTypography>
							</Stack>
						) : (
							<MKTypography variant='caption' color='dark'>
								{orderTotal}
							</MKTypography>
						)}
					</Stack>
				</Grid>
				<Grid item xs={6} sm={2}>
					<Stack gap={1}>
						<MKTypography variant='caption' color='dark'>
							Delivery Charge
						</MKTypography>
						<MKTypography variant='caption' color='dark'>
							{deliveryCharge}
						</MKTypography>
					</Stack>
				</Grid>
				<Grid item xs={6} sm={2}>
					<Stack gap={1}>
						<MKTypography variant='caption' color='dark'>
							Order Id
						</MKTypography>
						<MKTypography variant='caption' color='dark'>
							{orderId}
						</MKTypography>
					</Stack>
				</Grid>
			</Grid>
		</Paper>
	);
};
