const { default: jambuda } = require("./core");
class UserService {
	baseUrl;
	constructor() {
		this.baseUrl = "/api/users";
	}
	async findUsersByType(type) {
		return new Promise((resolve, reject) => {
			jambuda
				.get(`${this.baseUrl}?type=${type}`)
				.then((result) => {
					return resolve(result.data);
				})
				.catch((err) => {
					return reject(err);
				});
		});
	}
}
export default UserService;
