const { default: jambuda } = require("./core");
const auth = {
	register,
	login,
	verify,
};

function register(payload) {
	return new Promise((resolve, reject) => {
		jambuda
			.post("/api/users", payload)
			.then((result) => {
				return resolve(result.data);
			})
			.catch((err) => {
				return reject(err);
			});
	});
}

function login(payload) {
	return new Promise((resolve, reject) => {
		jambuda
			.post("/api/auth", payload)
			.then((result) => {
				const { access_token, user, membership } = result.data;
				return resolve({ access_token, user, membership });
			})
			.catch((err) => {
				return reject(err);
			});
	});
}
function verify(payload) {
	return new Promise((resolve, reject) => {
		jambuda
			.post("/api/auth/verify", payload)
			.then((result) => {
				const user = result.data;
				return resolve(user);
			})
			.catch((err) => {
				return reject(err);
			});
	});
}
export default auth;
