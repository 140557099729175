import React from "react";
import { AppBar, Toolbar, Typography, Button, Box } from "@mui/material";
import { fontFamily, fontWeight, styled } from "@mui/system";

const StyledAppBar = styled(AppBar)`
	background-color: transparent;
	box-shadow: none;
	position: fixed;
`;

const NavButton = styled(Button)`
	color: #fff;
	margin-left: 16px;
`;

const Header = () => {
	return (
		<StyledAppBar>
			<Toolbar>
				<Typography variant='h6' component='div' sx={{ flexGrow: 1 }}>
					Jumbuda
				</Typography>
				<Box>
					<Button sx={styles.text}>Features</Button>
					<Button sx={styles.text}>Pricing</Button>
					<Button sx={styles.text}>Testimonials</Button>
					<Button sx={styles.text}>Contact</Button>
				</Box>
			</Toolbar>
		</StyledAppBar>
	);
};

export default Header;
const styles = {
	text: {
		fontFamily: "Lato",
		fontWeight: "bold",
		fontColor: 14,
		color: "#121212",
		marginLeft: 16,
	},
};
