export function validateEmail(email) {
	// Regular expression for validating email addresses
	const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
	return emailRegex.test(email);
}
export function validatePhoneNumber(phoneNumber) {
	// Regular expression for validating phone numbers
	const phoneRegex = /^\d{10}$/; // Assuming a 10-digit format

	// Remove any non-digit characters from the phone number
	const sanitizedPhoneNumber = phoneNumber.replace(/\D/g, "");

	// Check if the sanitized phone number matches the regex pattern
	return phoneRegex.test(sanitizedPhoneNumber);
}
export function getBase64(file) {
	const reader = new FileReader();
	reader.readAsDataURL(file);
	reader.onload = function () {
		console.log("READER", reader.result);
		return reader.result;
	};
	reader.onerror = function (error) {
		console.log("ERROR ", error);
		return null;
	};
}
export const toBase64 = (file) =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = reject;
	});
