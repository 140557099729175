import {
	Add,
	ArrowCircleRight,
	ArrowRight,
	ArrowRightRounded,
	DeleteForeverRounded,
	Info,
	RampRight,
} from "@mui/icons-material";
import {
	Autocomplete,
	Box,
	Card,
	Checkbox,
	Chip,
	Container,
	Divider,
	FormControl,
	FormControlLabel,
	FormGroup,
	FormLabel,
	Grid,
	IconButton,
	Paper,
	Radio,
	RadioGroup,
	Slide,
	Stack,
	TextField,
	createFilterOptions,
	CardContent,
	CardHeader,
} from "@mui/material";
import productQuery from "../../../api/product-query";
import select from "assets/theme/components/form/select";

import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKInput from "components/MKInput";
import MKTypography from "components/MKTypography";
import { setQuery } from "features/queryGroupCartSlice";
import { addQuery } from "features/queryGroupCartSlice";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { resetQuery } from "features/queryGroupCartSlice";
import { addStoreCount } from "features/storeSlice";
import { setStoreQueries } from "features/storeSlice";
import { setCartQuery } from "features/queryGroupCartSlice";
import { updateCartQuery } from "features/queryGroupCartSlice";
import MKQuantity from "components/MKQuantity";
import useSound from "use-sound";
import notifier from "../../../assets/sounds/notifications.mp3";
import MKFileupload from "components/MKFileupload";
const filter = createFilterOptions();
const vars = {
	ORIGINAL: "Original",
	FIRST_COPY: "Other",
};

const Model = (props) => {
	const [model, setModel] = useState(props.model);
	const [selectedProduct, setProduct] = useState(null);
	const [products, setProducts] = useState(props.productList);
	const [variants, setVariants] = useState(props.variants);
	const [availableColours, setAvailableColours] = useState([]);
	const [selectedColor, setSelectedColor] = useState(null);
	const [availableVariants, setAvailableVariants] = useState([]);
	const [queryFormItems, setQueryFormItems] = useState([]);
	const queryGroupCart = useSelector((state) => state.queryGroupCart.queries);
	const [groupItems, setGroupItems] = useState(queryGroupCart);
	const [queryGroupName, setQueryGroupName] = useState("");
	const [queryGroupNameError, setQueryGroupNameError] = useState(false);
	const store = useSelector((state) => state.stores.store);
	const [error, setError] = useState("");
	const [success, setSuccess] = useState(false);
	const [play] = useSound(notifier);

	const querySubmitForm = useSelector(
		(state) => state.queryGroupCart.cartQueries
	);
	const dispatch = useDispatch();
	useEffect(() => {
		console.log(queryGroupCart);
	}, []);
	const uniqueColors = (colors) => {
		const seen = new Set();
		return colors.filter((color) => {
			const duplicate = seen.has(color.name);
			seen.add(color.name);
			return !duplicate;
		});
	};

	const handleAddToGroup = (groupId, query) => {
		const groupItem = { groupId: groupId, item: query };
		setGroupItems((prev) => [...prev, groupItem]);
	};

	const setSelectedProduct = (product) => {
		setSelectedProduct(product);
		props.setSelectedProduct(product);
	};
	useEffect(() => {
		const real = uniqueColors(colours);
		setAvailableColours(real);
		console.log(products);
	}, []);
	const handleAddColor = (newColor) => {
		// Check if color already exists
		const existingColor = availableColours.find(
			(color) => color.name.toLowerCase() === newColor.toLowerCase()
		);
		if (!existingColor) {
			setAvailableColours([
				...availableColours,
				{ id: availableColours.length + 1, name: newColor, hex: null },
			]);
		}
	};

	const buildQueryForm = () => {
		if (!queryGroupName) {
			return setQueryGroupNameError(true);
		} else {
			setQueryGroupNameError(false);
		}

		const queryGroupPayload = {
			name: queryGroupName,
			storeId: store.id,
		};
		productQuery
			.addQueryGroup(queryGroupPayload)
			.then((qg) => {
				let queryForm = [];
				console.log("QUERY FORM ITEMS", querySubmitForm, groupItems);
				_.map(querySubmitForm, (item) => {
					const payload = {
						image: item.image,
						imageId: item.fileId,
						queryType: props.queryType,
						queryText: item.queryText,
						userId: item.userId,
						brandId: item.brandId,
						productId: item.productId,
						quantity: Number(item.quantity),
						modelsId: item.modelsId,
						queryGroupId: qg.id,
						qualityVariants: item.qualityVariants,
						color: item.color,
						brandVariants: item.brandVariants,
						storeId: store.id,
						queryGroupName: queryGroupName,
					};
					queryForm.push(payload);
				});
				setError("");
				setSuccess(false);
				console.log("QUERY FORMS", queryForm);
				let count = 0;
				_.map(queryForm, (item) => {
					productQuery
						.addProductQuery(item)
						.then((result) => {
							count++;
							if (count == queryForm.length) {
								dispatch(resetQuery());
								productQuery
									.getProductQueries(store.id)
									.then((res) => {
										dispatch(setStoreQueries(res));
									})
									.catch((err) => {});
								setQueryFormItems([]);
								setGroupItems([]);
								dispatch(setQuery([]));
								setSuccess(true);
								setModel(null);
							}
						})
						.catch((error) => {
							console.log(error);
							setError("Error Submitting the Query");
						});
				});
			})
			.catch((err) => {
				console.log(err);
			});

		// productQuery
		// 	.addProductQuery(queryForm)
		// 	.then((result) => {
		// 		dispatch(resetQuery());
		// 		productQuery
		// 			.getProductQueries(store.id)
		// 			.then((res) => {
		// 				dispatch(setStoreQueries(res));
		// 			})
		// 			.catch((err) => {});
		// 		setQueryFormItems([]);
		// 		setGroupItems([]);
		// 		dispatch(setQuery([]));
		// 		setSuccess(true);
		// 		setModel(null);
		// 	})
		// 	.catch((error) => {
		// 		console.log(error);
		// 		setError("Error Submitting the Query");
		// 	});
	};

	const canAdd = (newPayload) => {
		const exists = _.findIndex(groupItems, (item) => {
			return (
				item.userId === newPayload.userId &&
				item.storeId === newPayload.storeId &&
				item.brandId === newPayload.brandId &&
				item.productId === newPayload.productId &&
				item.modelsId === newPayload.modelsId &&
				item.color.name === newPayload.color.name &&
				_.find(newPayload.variants, (v) => {
					return item.variants.includes(v);
				}) &&
				item.queryText === newPayload.queryText
			);
		});
		if (exists == -1) {
			return true;
		} else {
			return false;
		}
	};

	const makeQueryForm = (item) => {
		const payload = {
			fileId: item.fileId,
			image: item.image,
			queryText: item.queryText,
			userId: item.userId,
			brandId: item.brandId,
			productId: item.productId,
			quantity: item.quantity,
			modelsId: item.modelsId,
			queryGroupId: null,
			qualityVariants: item.qualityVariants,
			color: item.color,
			brandVariants: item.brandVariants,
		};
		return payload;
	};

	const canAddToGroupCartRedux = (newPayload) => {
		const exists = _.findIndex(querySubmitForm, (item) => {
			console.log(item, newPayload);

			return (
				item.userId === newPayload.userId &&
				item.brandId === newPayload.brandId &&
				item.productId === newPayload.productId &&
				item.modelsId === newPayload.modelsId &&
				item.color.name === newPayload.color.name &&
				item.queryText === newPayload.queryText
			);
		});
		if (exists == -1) {
			return true;
		} else {
			return false;
		}
	};

	const handleSubmit = (payload) => {
		console.log("Submitting", payload);
		const form = makeQueryForm(payload);
		let currentItems = [...queryFormItems];
		console.log("Current Form Items", currentItems, groupItems);
		setQueryFormItems((prev) => [...prev, form]);
		const id = Math.random() * 10000 + 1;

		dispatch(setCartQuery({ ...form, id: id }));

		if (payload.availableVariants?.length && !payload.variants?.length) {
			console.log("Invalid Submission");
			return;
		}

		if (payload.qualityVariants?.includes("ORIGINAL")) {
			const first_row = {
				...payload,
				qualityVariants: "ORIGINAL",
				variants: "Original",
				id: id,
			};
			if (canAdd(first_row)) {
				console.log("ADDing a row", first_row);
				setGroupItems((prev) => [...prev, first_row]);
				dispatch(addQuery(first_row));
			}
		}

		if (payload.qualityVariants?.includes("FIRST_COPY")) {
			console.log(payload.availableVariants, "VARIANTS AVAILABLE");

			const brandVariants = payload.variants;
			_.map(brandVariants, (v) => {
				let t = {
					...payload,
					qualityVariants: v,
					variants: v,
					id: id,
				};
				if (canAdd(t)) {
					setGroupItems((prev) => [...prev, t]);
					dispatch(addQuery(t));
				}
			});
			if (!payload.variants?.length) {
				const copy = {
					...payload,
					qualityVariants: "Other",
					variants: [],
					id: id,
				};
				if (canAdd(copy)) {
					setGroupItems((prev) => [...prev, copy]);
					dispatch(addQuery(copy));
				}
			}
		}
	};

	const removeItem = (id) => {
		const remaining = _.filter(groupItems, function (d) {
			return d.id != id;
		});
		const queryRemaining = _.filter(querySubmitForm, function (d) {
			return d.id != id;
		});
		dispatch(updateCartQuery(queryRemaining));
		setGroupItems(remaining);
		dispatch(setQuery(remaining));
	};

	return (
		<div>
			<Grid container spacing={1} direction={"column"}>
				<Grid item xs={12} sm={12}>
					<Paper
						elevation={0}
						sx={{
							width: "100%",
							backgroundColor: "#EAF5FF",
							padding: 2,
							mb: 2,
						}}
					>
						<Stack gap={1}>
							<MKTypography variant='caption' color='info'>
								<Info />
								&nbsp;A Query group is a set of product price queries. We
								recommend you to name each query group with customer name or
								device name so that you can identify the query responses for
								each device or customer.
							</MKTypography>
						</Stack>
					</Paper>
				</Grid>

				{model && (
					<Grid item xs={12} sm={12} style={styles.paper}>
						<Stack direction={"column"} width={"100%"} gap={3}>
							<BasicInfo model={model} />

							<DetailEntry
								model={model}
								handleAddColor={handleAddColor}
								availableColours={availableColours}
								selectedColor={selectedColor}
								setSelectedColor={setSelectedColor}
								products={products}
								selectedProduct={selectedProduct}
								setSelectedProduct={setSelectedProduct}
								key={availableColours.length}
								variants={variants}
								handleAddToGroup={handleAddToGroup}
								onSubmit={handleSubmit}
							/>
						</Stack>
					</Grid>
				)}
				<Grid item xs={12} sm={12}>
					<Grid container spacing={1} direction='column'>
						<Grid item xs={12} sm={12}>
							<Box
								sx={{
									width: "100%",
									alignItems: "center",
									justifyContent: "space-between",
									display: groupItems.length ? "flex" : "none",
									flexDirection: "row",
								}}
							>
								{error && (
									<MKTypography variant='caption' color='error'>
										{error}
									</MKTypography>
								)}
								<Stack
									gap={2}
									direction={"row"}
									sx={{
										alignItems: "center",
										justifyContent: "center",
										display: "flex",
										width: "100%",
									}}
								>
									<Stack direction={"column"} sx={{ width: "50%" }}>
										<MKTypography variant='caption' color='info'>
											Total Queries: &nbsp;{groupItems.length}
										</MKTypography>
										<MKTypography variant='caption' color='info'>
											Total Quantity: &nbsp;
											{_.sumBy(groupItems, (item) => {
												return Number(item.quantity);
											})}
										</MKTypography>
									</Stack>

									<TextField
										fullWidth
										placeholder="ex. Mr.Jazzar's phone"
										label='Query Name'
										onChange={(e) => setQueryGroupName(e.target.value)}
										error={queryGroupNameError}
										required
										helperText={queryGroupNameError && "Query Name Needed"}
									/>

									<MKButton
										variant='outlined'
										color='success'
										onClick={buildQueryForm}
										fullWidth
									>
										<Add />
										&nbsp;Submit Query
									</MKButton>
								</Stack>
							</Box>
						</Grid>
						<Grid item xs={12} sm={12}>
							<Stack
								style={{
									maxHeight: "65vh",
									overflowY: "scroll",
								}}
								gap={1}
							>
								{_.map(groupItems, (item, index) => {
									return (
										<Card key={index} sx={{ mt: 1 }}>
											<CardHeader
												sx={{
													backgroundColor: "#fafafa",

													height: 50,
												}}
												title={
													<MKTypography
														variant='body2'
														color='dark'
														fontWeight='bold'
													>
														{item.brandName}
													</MKTypography>
												}
												action={
													<IconButton
														onClick={() => removeItem(item.id)}
														size='small'
													>
														<DeleteForeverRounded
															color='#E12F6C'
															sx={{ color: "#E12F6C" }}
														/>
													</IconButton>
												}
											/>
											<CardContent>
												<Grid container spacing={2}>
													<Grid item xs={6} sm={4}>
														<Stack
															gap={1}
															directon='column'
															sx={{
																alignItems: "flex-start",
																justifyContent: "center",
																display: "flex",
															}}
														>
															<MKTypography
																variant='caption'
																color='dark'
																fontWeight='bold'
															>
																Model
															</MKTypography>
															<MKTypography variant='caption' color='dark'>
																{item.model}
															</MKTypography>
														</Stack>
													</Grid>
													<Grid item xs={6} sm={2}>
														<Stack
															direction={"column"}
															gap={1}
															sx={{
																alignItems: "flex-start",
																justifyContent: "center",
																display: "flex",
															}}
														>
															<MKTypography
																color='dark'
																variant='caption'
																fontWeight='bold'
															>
																Colour
															</MKTypography>
															<Stack direction={"row"} gap={1}>
																<MKTypography color='dark' variant='caption'>
																	{item.color.name}
																</MKTypography>
																<Box
																	sx={{
																		width: 14,
																		height: 14,
																		backgroundColor: item.color.hex,
																	}}
																/>
															</Stack>
														</Stack>
													</Grid>
													<Grid item xs={6} sm={2}>
														<Stack
															gap={1}
															direction={"column"}
															sx={{
																alignItems: "flex-start",
																justifyContent: "center",
																display: "flex",
															}}
														>
															<MKTypography
																color='dark'
																variant='caption'
																fontWeight='bold'
															>
																Product
															</MKTypography>
															<MKTypography color='dark' variant='caption'>
																{item.product}
															</MKTypography>
														</Stack>
													</Grid>
													<Grid item xs={6} sm={2}>
														<Stack
															gap={1}
															direction={"column"}
															sx={{
																alignItems: "center",
																justifyContent: "center",
																display: "flex",
															}}
														>
															<MKTypography
																color='dark'
																variant='caption'
																fontWeight='bold'
															>
																Brands
															</MKTypography>
															<MKTypography color='dark' variant='caption'>
																{item.variants?.length
																	? _.map(item.variants, (v) => {
																			return (
																				<MKTypography
																					color='dark'
																					variant='caption'
																				>
																					{v}
																				</MKTypography>
																			);
																	  })
																	: "NA"}
															</MKTypography>
														</Stack>
													</Grid>
													<Grid item xs={6} sm={2}>
														<Stack
															gap={1}
															direction={"column"}
															sx={{
																alignItems: "center",
																justifyContent: "center",
																display: "flex",
															}}
														>
															<MKTypography
																color='dark'
																variant='caption'
																fontWeight='bold'
															>
																Quantity
															</MKTypography>
															<MKTypography
																color='dark'
																variant='caption'
																fontWeight='bold'
															>
																{item.quantity}
															</MKTypography>
														</Stack>
													</Grid>
													<Grid item xs={12} sm={12}>
														<MKTypography
															color='info'
															variant='caption'
															style={{
																width:
																	"500px" /* Set a fixed width for the div */,

																/* Optional: add some padding */
																wordWrap:
																	"break-word" /* Ensure long words break */,
																overflowWrap:
																	"break-word" /* Alternative property to break long words */,
																whiteSpace:
																	"normal" /* Allow text to wrap and break lines */,
																overflow: "hidden",
															}}
														>
															{item.queryText}
														</MKTypography>
													</Grid>
												</Grid>
											</CardContent>
										</Card>
									);
								})}
							</Stack>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</div>
	);
};
export default Model;
const BasicInfo = (props) => {
	const [model, setModel] = useState(props.model);
	const columns = [];

	return (
		<div style={{ marginLeft: 10 }}>
			<Grid container spacing={1}>
				<Grid item xs={12}>
					<Stack gap={1}>
						<MKTypography varaint='body1' color='dark'>
							Build Your Query
						</MKTypography>
					</Stack>
				</Grid>
				<Grid item xs={12} sm={6}>
					<Stack gap={2} direction={"row"}>
						<MKTypography variant='body2' color='dark' fontWeight='bold'>
							Brand
						</MKTypography>
						<MKTypography variant='body2' color='primary' fontWeight='bold'>
							{model.brand}
						</MKTypography>
					</Stack>
				</Grid>
				<Grid item xs={12} sm={6}>
					<Stack gap={2} direction={"row"}>
						<MKTypography variant='body2' color='dark' fontWeight='bold'>
							Model
						</MKTypography>
						<MKTypography variant='body2' color='primary' fontWeight='bold'>
							{model.modelName}
						</MKTypography>
					</Stack>
				</Grid>
			</Grid>
		</div>
	);
};
const DetailEntry = (props) => {
	const [model, setModel] = useState(props.model);
	const [selectedProduct, setSelectedProduct] = useState(props.selectedProduct);
	const [variants, setVariants] = useState(props.variants);
	const [products, setProducts] = useState(props.products);
	const [availableColours, setAvailableColours] = useState(
		props.availableColours
	);
	const [selectedColor, setColor] = useState(props.selectedColor);
	const [availableVariants, setAvailableVariants] = useState(null);
	const [selectedVariants, setSelectedVariants] = useState([]);
	const [firstCopyChecked, setFirstCopyChecked] = useState(false);
	const [variantError, setVariantError] = useState(null);
	const [qty, setQty] = useState(1);
	const [fileUrl, setFileUrl] = useState("");
	const [fileId, setFileId] = useState("");
	const setSelectedColor = (newColor) => {
		setColor(newColor);
		props.setSelectedColor(newColor);
	};
	const [original, setOriginal] = useState(true);
	const handleOnChangeOriginal = (e) => {
		setOriginal(e.target.checked);
	};
	const [remarks, setRemarks] = useState("");
	const handleOnChange = (e) => {
		if (e.target.checked) {
			setSelectedVariants([]);
			showHideVariant();
			setFirstCopyChecked(true);
		} else {
			setFirstCopyChecked(false);
			setAvailableVariants(null);
		}
	};
	const showHideVariant = () => {
		console.log("Variants", variants);
		setAvailableVariants(() => []);
		let current = _.filter(variants, (variant) => {
			return (
				variant.productId == selectedProduct?.id &&
				variant.modelId == model.modelId
			);
		});
		console.log("Selected Current", current, model);
		const mapped = _.map(current, (variant) => {
			return { id: variant.id, label: variant.name };
		});
		setAvailableVariants(mapped);
	};

	const handleSetVariant = (event, newValue) => {
		setFirstCopyChecked(false);
		if (typeof newValue === "string") {
			// Create a new value from the user input
			setSelectedProduct({
				id: products?.length + 1,
				label: newValue,
				// Default color or prompt the user for a hex value
			});

			setProducts([
				...products,
				{
					label: newValue,

					id: products?.length + 1,
				},
			]);
		} else if (newValue && newValue.inputValue) {
			// Create a new value from the "Add xxx" option
			setSelectedProduct({
				id: products?.length + 1,
				label: newValue.inputValue,
				// Default color or prompt the user for a hex value
			});

			setProducts([
				...products,
				{
					id: products?.length + 1,
					label: newValue.inputValue,
				},
			]);
		} else {
			console.log("Existing product", newValue);
			setSelectedProduct(newValue);
		}
	};
	const handleChangeText = (e) => {
		setRemarks(e.target.value);
	};
	const handleSubmit = () => {
		let quality = [];
		if (original) {
			quality.push("ORIGINAL");
		}
		if (firstCopyChecked) {
			quality.push("FIRST_COPY");
		}
		if (
			firstCopyChecked &&
			!selectedVariants.length &&
			availableVariants.length
		) {
			setVariantError("select atleast one variant");
		} else {
			setVariantError(null);
		}
		console.log(selectedVariants, "variants");
		const v = _.map(selectedVariants, (va) => {
			return va.id;
		});
		const va = _.map(selectedVariants, (v) => {
			return v.label;
		});
		const payload = {
			queryText: remarks,
			image: fileUrl,
			fileId: fileId,
			userId: 1,
			storeId: 1,
			brandId: model.brandId,
			productId: selectedProduct.id,
			quantity: qty,
			modelsId: model.modelId,
			queryGroupId: null,
			qualityVariants: quality,
			color: { hex: selectedColor.hex, name: selectedColor.name },
			brandName: model.brand,
			model: model.modelName,
			product: selectedProduct.label,
			brandVariants: v,
			variants: va,
			availableVariants: availableVariants,
		};
		console.log("PAYLOAD", payload);
		props.onSubmit(payload);
	};

	return (
		<MKBox
			style={{
				marginLeft: 0,
				width: "100%",
				padding: 10,
				border: "1px dashed #000000",
			}}
		>
			<div>
				<Grid container spacing={3}>
					<Grid item xs={12} sm={4}>
						<MKTypography color='dark' variant='body2'>
							Choose Device Color
						</MKTypography>
						<Stack
							gap={2}
							direction={"row"}
							width={"100%"}
							sx={{
								mt: 2,
								alignItems: "center",
								justifyContent: "center",
								display: "flex",
								flexDirection: "row",
							}}
						>
							<Autocomplete
								freeSolo
								sx={{
									width: "100%",
								}}
								options={availableColours}
								getOptionLabel={(option) => {
									// Value selected with enter, right from the input
									if (typeof option === "string") {
										return option;
									}
									// Add "xxx" option created dynamically
									if (option.inputValue) {
										return option.inputValue;
									}
									// Regular option
									return option.name;
								}}
								filterOptions={(options, params) => {
									const filtered = filter(options, params);

									// Suggest the creation of a new value
									if (params.inputValue !== "") {
										filtered.push({
											inputValue: params.inputValue,
											name: `Add "${params.inputValue}"`,
											hex: "", // Optionally, you can let the user input a hex value for the new color
										});
									}

									return filtered;
								}}
								selectOnFocus
								clearOnBlur
								handleHomeEndKeys
								value={selectedColor}
								onChange={(event, newValue) => {
									if (typeof newValue === "string") {
										// Create a new value from the user input
										setSelectedColor({
											id: availableColours?.length + 1,
											name: newValue,
											hex: null, // Default color or prompt the user for a hex value
										});

										setAvailableColours([
											...availableColours,
											{
												name: newValue,
												hex: null,
												id: availableColours?.length + 1,
											},
										]);
									} else if (newValue && newValue.inputValue) {
										// Create a new value from the "Add xxx" option
										setSelectedColor({
											id: availableColours?.length + 1,
											name: newValue.inputValue,
											hex: null, // Default color or prompt the user for a hex value
										});
										setAvailableColours([
											...availableColours,
											{
												id: availableColours?.length + 1,
												name: newValue.inputValue,
												hex: null,
											},
										]);
									} else {
										setSelectedColor(newValue);
									}
								}}
								renderOption={(props, option) => (
									<Box component='li' {...props}>
										{option.hex ? (
											<>
												<Box
													sx={{
														width: 20,
														height: 20,
														backgroundColor: option.hex,
														marginRight: 2,
													}}
												/>
												<MKTypography color='dark' variant='caption'>
													{option.name}
												</MKTypography>
											</>
										) : (
											<>
												<Box
													sx={{
														width: 20,
														height: 20,
														backgroundColor: "#ffffff",
														marginRight: 2,
													}}
												>
													<MKTypography variant='caption' color='primary'>
														?
													</MKTypography>
												</Box>
												<MKTypography color='dark' variant='caption'>
													{option.name}
												</MKTypography>
											</>
										)}
									</Box>
								)}
								renderInput={(params) => (
									<TextField
										{...params}
										label='Select a color'
										variant='outlined'
									/>
								)}
							/>
							<Box
								sx={{
									width: 20,
									height: 20,
									backgroundColor: selectedColor?.hex,
									marginRight: 2,
								}}
							></Box>
						</Stack>
					</Grid>

					<Grid item xs={12} sm={8}>
						<Stack
							gap={2}
							direction={"column"}
							sx={{
								alignItems: "flex-start",
								justifyContent: "center",
								display: "flex",
								flexDirection: "column",
							}}
						>
							<MKTypography color='dark' variant='body2'>
								Select Product
							</MKTypography>
							<Stack
								direction='row'
								sx={{
									alignItems: "center",
									justifyContent: "center",
									display: "flex",
									flexDirection: "row",
									width: "100%",
								}}
								gap={2}
							>
								<Autocomplete
									freeSolo
									sx={{
										width: "100%",
									}}
									options={products}
									getOptionLabel={(option) => {
										// Value selected with enter, right from the input
										if (typeof option === "string") {
											return option;
										}
										// Add "xxx" option created dynamically
										if (option.inputValue) {
											return option.inputValue;
										}
										// Regular option
										return option.label;
									}}
									filterOptions={(options, params) => {
										const filtered = filter(options, params);

										// Suggest the creation of a new value
										if (params.inputValue !== "") {
											filtered.push({
												inputValue: params.inputValue,
												label: `Add "${params.inputValue}"`,
												// Optionally, you can let the user input a hex value for the new color
											});
										}

										return filtered;
									}}
									selectOnFocus
									clearOnBlur
									handleHomeEndKeys
									value={selectedProduct}
									onChange={(event, newValue) => {
										handleSetVariant(event, newValue);
										setAvailableVariants([]);
									}}
									renderOption={(props, option) => (
										<li {...props}>
											<MKTypography color='dark' variant='caption'>
												{option.label}
											</MKTypography>
										</li>
									)}
									renderInput={(params) => (
										<TextField
											{...params}
											label='Select a Product'
											variant='outlined'
										/>
									)}
								/>
								{selectedProduct && (
									<Slide in={true} direction='up'>
										<Stack
											gap={3}
											direction={"row"}
											sx={{
												alignItems: "center",
												justifyContent: "flex-start",
												display: "flex",
												flexDirection: "row",
											}}
										>
											<FormControlLabel
												control={
													<Checkbox
														size='small'
														defaultChecked
														onChange={handleOnChangeOriginal}
														value={original}
													/>
												}
												label={
													<MKTypography variant='caption' color='priamry'>
														Original
													</MKTypography>
												}
												sx={{ fontSize: 10 }}
											/>
											<FormControlLabel
												control={
													<Checkbox
														size='small'
														onChange={handleOnChange}
														checked={firstCopyChecked}
													/>
												}
												label={
													<MKTypography variant='caption' color='priamry'>
														Other
													</MKTypography>
												}
												sx={{ fontSize: 10 }}
											/>
										</Stack>
									</Slide>
								)}
								{selectedProduct && availableVariants?.length ? (
									<Slide in={true} direction='up'>
										<Stack
											gap={2}
											direction={"row"}
											sx={{
												alignItems: "center",
												justifyContent: "flex-start",
												display: "flex",
												flexDirection: "row",
											}}
										>
											<MKTypography variant='caption' color='dark'>
												Variants
											</MKTypography>
											<Autocomplete
												multiple
												size='small'
												id='tags-outlined'
												sx={{ width: "300px" }}
												options={availableVariants}
												getOptionLabel={(option) => option.label}
												value={selectedVariants}
												onChange={(event, newValue) => {
													setSelectedVariants(newValue);
												}}
												filterSelectedOptions
												renderInput={(params) => (
													<TextField
														size='small'
														{...params}
														variant='outlined'
														label='Select Options'
														placeholder='Favorites'
														error={variantError}
														helperText={variantError}
													/>
												)}
												renderTags={(value, getTagProps) =>
													value.map((option, index) => (
														<Chip
															key={option.id}
															label={option.label}
															{...getTagProps({ index })}
														/>
													))
												}
											/>
										</Stack>
									</Slide>
								) : (
									<></>
								)}
							</Stack>
						</Stack>
					</Grid>

					<Grid item xs={12} sm={4}>
						<Stack
							gap={1}
							direction='column'
							sx={{
								alignItems: "center",
								justifyContent: "center",
								display: "flex",
								width: "100%",
							}}
						>
							<MKTypography variant='body2' color='dark'>
								Qty
							</MKTypography>
							<MKQuantity min={1} step={1} quantity={qty} onChange={setQty} />
						</Stack>

						{/* <MKInput
							label='Quantity'
							size='large'
							placeholder='Quantity'
							type='number'
							value={qty}
							sx={{ width: "100%" }}
							onChange={(e) => setQty(e.target.value)}
						/> */}
					</Grid>

					<Grid item xs={12} sm={8}>
						<Slide in={true} direction='up'>
							<MKInput
								sx={{ width: "100%" }}
								multiline
								rows={4}
								placeholder='describe additional query about product(Ex.With Connector or without connector)'
								onChange={handleChangeText}
							/>
						</Slide>
					</Grid>
					<Grid item xs={12} sm={12}>
						<MKFileupload
							path={"query"}
							onMediaUpload={(url, result) => {
								setFileUrl(url);
								setFileId(result.fileId);
							}}
						/>
					</Grid>
					<Grid
						item
						xs={12}
						sm={12}
						sx={{
							alignItems: "center",
							justifyContent: "center",
							display: "flex",
						}}
					>
						<MKButton
							color='primary'
							variant='gradient'
							sx={{ width: 300, mt: 5 }}
							size='large'
							disabled={!selectedProduct}
							onClick={handleSubmit}
						>
							Add to List&nbsp;
							<ArrowCircleRight />
						</MKButton>
					</Grid>
				</Grid>
			</div>
		</MKBox>
	);
};

const styles = {
	paper: {
		padding: 15,
		borderRadius: 5,
		backgroundColor: "#fcfcfc",
		width: "100%",

		alignItems: "center",
		justifyContent: "flex-start",
		display: "flex",
	},
	card: {
		padding: 1,
		borderRadius: 1,
		backgroundColor: "#fafafc",

		marginBottom: 2,
		alignItems: "center",
		justifyContent: "flex-start",
		display: "flex",
		flexDirection: "row",
	},
	buttonContainer: {
		padding: 1,
		borderRadius: 1,

		width: "50%",
		marginBottom: 2,
		alignItems: "center",
		justifyContent: "flex-start",
		display: "flex",
		flexDirection: "row",
	},
};
const colours = [
	{ id: 1, hex: "#000000", name: "Black" },
	{ id: 2, hex: "#FFFFFF", name: "White" },
	{ id: 3, hex: "#808080", name: "Gray" },
	{ id: 4, hex: "#C0C0C0", name: "Silver" },
	{ id: 5, hex: "#FFD700", name: "Gold" },
	{ id: 6, hex: "#B76E79", name: "Rose Gold" },
	{ id: 7, hex: "#E5E4E2", name: "Platinum" },
	{ id: 8, hex: "#3D59AB", name: "Platinum Blue" },
	{ id: 9, hex: "#A7A7A7", name: "Platinum Gray" },
	{ id: 10, hex: "#0000FF", name: "Blue" },
	{ id: 11, hex: "#FF0000", name: "Red" },
	{ id: 12, hex: "#008000", name: "Green" },
	{ id: 13, hex: "#FFFF00", name: "Yellow" },
	{ id: 14, hex: "#FFA500", name: "Orange" },
	{ id: 15, hex: "#FFC0CB", name: "Pink" },
	{ id: 16, hex: "#800080", name: "Purple" },
	{ id: 17, hex: "#A52A2A", name: "Brown" },
	{ id: 18, hex: "#F7E7CE", name: "Champagne" },
	{ id: 19, hex: "#FF7F50", name: "Coral" },
	{ id: 20, hex: "#98FF98", name: "Mint" },
	{ id: 21, hex: "#00FFFF", name: "Aqua" },
	{ id: 22, hex: "#40E0D0", name: "Turquoise" },
	{ id: 23, hex: "#000080", name: "Navy" },
	{ id: 24, hex: "#008080", name: "Teal" },
	{ id: 25, hex: "#E6E6FA", name: "Lavender" },
	{ id: 26, hex: "#800020", name: "Burgundy" },
	{ id: 27, hex: "#36454F", name: "Charcoal" },
	{ id: 28, hex: "#4B4B4B", name: "Graphite" },
	{ id: 29, hex: "#708090", name: "Space Gray" },
	{ id: 30, hex: "#0A0A0A", name: "Jet Black" },
	{ id: 31, hex: "#1C1C1C", name: "Matte Black" },
	{ id: 32, hex: "#FDEEF4", name: "Pearl White" },
	{ id: 33, hex: "#228B22", name: "Forest Green" },
	{ id: 34, hex: "#191970", name: "Midnight Blue" },
	{ id: 35, hex: "#0047AB", name: "Cobalt Blue" },
	{ id: 36, hex: "#00008B", name: "Deep Blue" },
	{ id: 37, hex: "#C8A2C8", name: "Lilac" },
	{ id: 38, hex: "#FF007F", name: "Rose" },
	{ id: 39, hex: "#CD7F32", name: "Bronze" },
	{ id: 40, hex: "#C2B280", name: "Sand" },
	{ id: 41, hex: "#800000", name: "Maroon" },
	{ id: 42, hex: "#DC143C", name: "Crimson" },
	{ id: 43, hex: "#50C878", name: "Emerald" },
	{ id: 44, hex: "#0F52BA", name: "Sapphire" },
	{ id: 45, hex: "#E0115F", name: "Ruby" },
	{ id: 46, hex: "#F5FFFA", name: "Frosted White" },
	{ id: 47, hex: "#1C1C1C", name: "Mystic Black" },
	{ id: 48, hex: "#AFEEEE", name: "Arctic Blue" },
	{ id: 49, hex: "#FFD300", name: "Sunshine Yellow" },
	{ id: 50, hex: "#1CA9C9", name: "Pacific Blue" },
	{ id: 51, hex: "#ACE5EE", name: "Cloud Blue" },
	{ id: 52, hex: "#353839", name: "Onyx" },
	{ id: 53, hex: "#A8A9AD", name: "Titan Gray" },
	{ id: 54, hex: "#A6E7FF", name: "Aurora" },
	{ id: 55, hex: "#F2F2F2", name: "Prism White" },
	{ id: 56, hex: "#4F42B5", name: "Ocean Blue" },
	{ id: 57, hex: "#FF0800", name: "Candy Red" },
	{ id: 58, hex: "#F0F8FF", name: "Ice White" },
	{ id: 59, hex: "#FFB6C1", name: "Blush Pink" },
	{ id: 60, hex: "#FFFDD0", name: "Cream" },
	{ id: 61, hex: "#FDB9C8", name: "Cloud Pink" },
	{ id: 62, hex: "#1B1B1B", name: "Phantom Black" },
	{ id: 63, hex: "#BFC1C2", name: "Phantom Silver" },
	{ id: 64, hex: "#6C6766", name: "Phantom Titanium" },
	{ id: 65, hex: "#2D383A", name: "Phantom Navy" },
	{ id: 66, hex: "#4B3621", name: "Phantom Brown" },
	{ id: 67, hex: "#EDEDED", name: "Aura Glow" },
	{ id: 68, hex: "#E9E7EF", name: "Aura White" },
	{ id: 69, hex: "#1C1C1C", name: "Aura Black" },
	{ id: 70, hex: "#B22222", name: "Aura Red" },
	{ id: 71, hex: "#1F75FE", name: "Aura Blue" },
	{ id: 72, hex: "#5A5A5A", name: "Cosmic Gray" },
	{ id: 73, hex: "#0B0B0B", name: "Cosmic Black" },
	{ id: 74, hex: "#F8F8FF", name: "Cloud White" },
	{ id: 75, hex: "#EEDD82", name: "Starlight" },
	{ id: 76, hex: "#4682B4", name: "Sierra Blue" },
	{ id: 77, hex: "#004953", name: "Midnight Green" },
	{ id: 78, hex: "#301934", name: "Deep Purple" },
	{ id: 79, hex: "#A8A8A8", name: "Frosted Silver" },
	{ id: 80, hex: "#FFF5EE", name: "Ceramic White" },
	{ id: 81, hex: "#FFD700", name: "Sunshine Gold" },
	{ id: 82, hex: "#A9A9A9", name: "Titanium Gray" },
	{ id: 83, hex: "#B22222", name: "Bold Red" },
	{ id: 84, hex: "#E9ADAD", name: "Champagne Pink" },
	{ id: 85, hex: "#FDEEF4", name: "Pearl White" },
	{ id: 86, hex: "#39FF14", name: "Neon Green" },
	{ id: 87, hex: "#FF69B4", name: "Dusk Pink" },
	{ id: 88, hex: "#EFDECD", name: "Almond" },
	{ id: 89, hex: "#0047AB", name: "Nebula Blue" },
	{ id: 90, hex: "#0F0F0F", name: "Onyx Black" },
	{ id: 91, hex: "#00FF7F", name: "Glacial Green" },
	{ id: 92, hex: "#8A2BE2", name: "Twilight" },
	{ id: 93, hex: "#50C878", name: "Emerald Green" },
	{ id: 94, hex: "#DAA520", name: "Pink Gold" },
	{ id: 95, hex: "#ADD8E6", name: "Light Blue" },
	{ id: 96, hex: "#D3D3D3", name: "Light Gray" },
	{ id: 97, hex: "#D2B48C", name: "Tan" },
	{ id: 98, hex: "#800080", name: "Violet" },
	{ id: 99, hex: "#FFA07A", name: "Light Salmon" },
	{ id: 100, hex: "#87CEEB", name: "Sky Blue" },
	{ id: 101, hex: "#5F9EA0", name: "Cadet Blue" },
	{ id: 102, hex: "#FA8072", name: "Salmon" },
	{ id: 103, hex: "#FFE4C4", name: "Bisque" },
	{ id: 104, hex: "#FF4500", name: "Orange Red" },
	{ id: 105, hex: "#FFE4B5", name: "Moccasin" },
	{ id: 106, hex: "#FFB6C1", name: "Light Pink" },
	{ id: 107, hex: "#32CD32", name: "Lime Green" },
	{ id: 108, hex: "#B0E0E6", name: "Powder Blue" },
	{ id: 109, hex: "#BC8F8F", name: "Rosy Brown" },
	{ id: 110, hex: "#D8BFD8", name: "Thistle" },
	{ id: 111, hex: "#FF6347", name: "Tomato" },
	{ id: 112, hex: "#EE82EE", name: "Violet Red" },
	{ id: 113, hex: "#9ACD32", name: "Yellow Green" },
	{ id: 114, hex: "#FAEBD7", name: "Antique White" },
	{ id: 115, hex: "#7FFF00", name: "Chartreuse" },
];
