import { Cube } from "@phosphor-icons/react";
import React from "react";

const MKLoader = (props) => {
	return (
		<Cube
			color='darkorchid'
			weight='duotone'
			style={{
				fontSize: props.size,
			}}
		>
			<animate
				attributeName='opacity'
				values='0;1;0'
				dur='4s'
				repeatCount='indefinite'
			></animate>
			<animateTransform
				attributeName='transform'
				attributeType='XML'
				type='rotate'
				dur='5s'
				from='0 0 0'
				to='360 0 0'
				repeatCount='indefinite'
			></animateTransform>
		</Cube>
	);
};
export default MKLoader;
