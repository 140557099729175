const { default: jambuda } = require("./core");

const merchants = {
	createMerchant,
	addMerchantBrands,
	getMerchantProfile,
	findMerchantByAdminId,
	addMerchantProfile,
	updateMerchantProfile,
	updateMerchant,
};
export default merchants;

function findMerchantByAdminId(adminId) {
	return new Promise((resolve, reject) => {
		jambuda
			.get(`api/merchant?adminId=${adminId}`)
			.then((res) => {
				return resolve(res.data);
			})
			.catch((err) => reject(err));
	});
}
function createMerchant(payload) {
	return new Promise((resolve, reject) => {
		jambuda
			.post("/api/merchant", payload)
			.then((result) => {
				return resolve(result.data);
			})
			.catch((err) => {
				return reject(err);
			});
	});
}

function updateMerchant(id, payload) {
	return new Promise((resolve, reject) => {
		jambuda
			.patch(`/api/merchant/${id}`, payload)
			.then((result) => {
				return resolve(result.data);
			})
			.catch((err) => {
				return reject(err);
			});
	});
}

function addMerchantBrands(payload) {
	return new Promise((resolve, reject) => {
		jambuda
			.post("/api/merchant-brands", payload)
			.then((result) => {
				return resolve(result.data);
			})
			.catch((err) => {
				return reject(err);
			});
	});
}
function getMerchantProfile(merchantId) {
	return new Promise((resolve, reject) => {
		jambuda
			.get(`api/merchant-profile?merchantId=${merchantId}`)
			.then((res) => {
				return resolve(res.data);
			})
			.catch((err) => reject(err));
	});
}
function addMerchantProfile(payload) {
	return new Promise((resolve, reject) => {
		jambuda
			.post(`api/merchant-profile`, payload)
			.then((res) => {
				return resolve(res.data);
			})
			.catch((err) => reject(err));
	});
}
function updateMerchantProfile(profileId, payload) {
	return new Promise((resolve, reject) => {
		jambuda
			.patch(`api/merchant-profile/${profileId}`, payload)
			.then((res) => {
				return resolve(res.data);
			})
			.catch((err) => reject(err));
	});
}
