import { useSelector } from "react-redux";
import queries from "../../api/queries";
import React, { useEffect, useState } from "react";
import {
	Avatar,
	Box,
	Card,
	CardContent,
	CardHeader,
	CardMedia,
	Checkbox,
	Container,
	Dialog,
	DialogContent,
	Divider,
	FormControl,
	FormControlLabel,
	IconButton,
	Paper,
	Snackbar,
	Stack,
	Tooltip,
	Typography,
} from "@mui/material";
import MKBreadCrumbs from "components/MKBreadCrumbs";
import { TbBrandAbstract, TbBrandRadixUi } from "react-icons/tb";
import _ from "lodash";
import MKTypography from "components/MKTypography";
import { AddShoppingCart, CheckCircle } from "@mui/icons-material";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import MKSwipable from "components/MKSwipable";
import { DataGrid } from "@mui/x-data-grid";
import { CiSaveUp1 } from "react-icons/ci";
import { VscSyncIgnored } from "react-icons/vsc";
import MKCountdown from "components/MKCountDown";
import { IoDocumentAttach } from "react-icons/io5";
import { GoGitMerge, GoPackage } from "react-icons/go";
import { IoMdAdd } from "react-icons/io";
import { MdArrowCircleRight } from "react-icons/md";
import { MdOutlinePostAdd } from "react-icons/md";
import { PiPackageThin } from "react-icons/pi";
import { CiSquareRemove } from "react-icons/ci";
import { IoCheckmarkDoneCircleOutline } from "react-icons/io5";

const breadcrumb = [
	{ id: 1, name: "Home", link: "/" },
	{ id: 2, name: "My Queries", link: "" },
];

const RowDetail = ({ queryText }) => {
	return (
		<div style={{ borderTop: "1px solid #ccc", width: "100%" }}>
			{queryText}
		</div>
	);
};

const MerchantQueries = (props) => {
	const [myQueries, setMyQueries] = useState([]);
	const merchant = useSelector((state) => state.merchants.merchant);
	const products = useSelector((state) => state.products.products);
	const brands = useSelector((state) => state.brands.brands);
	const models = useSelector((state) => state.models.models);
	const variants = useSelector((state) => state.variants.variants);
	const [expiredQueries, setExpiredQueries] = useState([]);
	const [activeTab, setTabValue] = useState(0);
	const [mechantQueryResponse, setMerchantQueryResponse] = useState([]);
	const [open, setOpen] = useState(false);
	const [message, setMessage] = useState("");
	const [unitPrice, setUnitPrice] = useState({});
	const [unavailableUnits, setUnavailableUnits] = useState({});
	const [imageToShow, setImageToShow] = useState("");
	const [showImageDialog, setShowImageDialog] = useState(false);
	const [prices, setPrices] = useState({});
	const [variantsAdded, setVariantsAdded] = useState([]);

	const addVariants = (merchantQueryId) => {
		let current = [...variantsAdded];
		const exists = _.find(current, (v) => {
			return v.merchantQueryId == merchantQueryId;
		});
		const id = Math.floor(Math.random() * 1000 + 1);
		if (exists) {
			setVariantsAdded(current);
			const variant = {
				id: id,
				name: "Variant" + variantsAdded.length,
				price: 0,
				merchantQueryId: merchantQueryId,
				unavailable: false,
				canRemove: true,
			};
			current.push(variant);
		} else {
			const variant = {
				id: id,
				name: "Original",
				price: 0,
				merchantQueryId: merchantQueryId,
				unavailable: false,
				canRemove: false,
			};
			current.push(variant);
			setVariantsAdded(current);
		}
	};
	const removeVariant = (variantId) => {
		const found = _.find(variantsAdded, (v) => {
			return v.id == variantId;
		});
		if (found) {
			if (found.canRemove) {
				const remaining = _.filter(variantsAdded, (v) => {
					return v.id != variantId;
				});
				setVariantsAdded(remaining);
			} else {
				let updated = _.map(variantsAdded, (v) => {
					if (v.id == variantId) {
						v.unavailable = !v.unavailable;
					}
					return v;
				});
				setVariantsAdded(updated);
			}
		}
	};

	const handleSubmitV2 = (vid, query) => {
		const varnt = _.find(variantsAdded, (v) => {
			return v.id == vid;
		});

		const brandId = query.brand.id;
		const modelId = query.model.id;
		const variantName = varnt.name;
		const amount = Number(varnt.price);
		const productId = query.product.id;
		const queryId = query.productQueryId;
		const merchantQueryId = query.merchantQueryId;
		const unavailable = varnt.unavailable;
		const merchantId = query.merchantId;
		const queryGroupId = query.queryGroupId;
		const payload = {
			variantName: variantName,
			brandId: brandId,
			modelId: modelId,
			productId: productId,
			productQueryId: queryId,
			price: amount,
			merchantQueryId: merchantQueryId,
			unavailable: unavailable,
			merchantId: merchantId,
			queryGroupId: queryGroupId,
		};

		queries
			.submitMerchantQueryResponseV2(payload)
			.then((result) => {
				const remainingVariants = _.filter(variantsAdded, (v) => {
					return vid != v.id;
				});
				setVariantsAdded(remainingVariants);
			})
			.catch((err) => {
				setOpen(true);
				setMessage("FAILED! to submit quote");
			});
	};

	const handleDoneSubmittingAllVariants = (query) => {
		if (!variantsAdded.length) {
			const remaining = _.filter(myQueries, (q) => {
				return q.merchantQueryId != query.merchantQueryId;
			});
			setMyQueries(remaining);
		} else {
			setOpen(true);
			setMessage("Response Pending for added  Variants");
		}
	};
	const handleVariantNameChange = (val, id) => {
		let current = [...variantsAdded];
		const next = _.map(current, (v) => {
			if (v.id == id) {
				const rec = { ...v, name: val };
				return rec;
			} else {
				return v;
			}
		});
		setVariantsAdded(next);
	};
	const handleVariantPriceChange = (val, id) => {
		let current = [...variantsAdded];
		const next = _.map(current, (v) => {
			if (v.id == id) {
				const rec = { ...v, price: val };
				return rec;
			} else {
				return v;
			}
		});
		setVariantsAdded(next);
	};

	const handleUnitPriceUpdate = (row, e) => {
		let currentUnitPrice = { ...unitPrice };
		currentUnitPrice[row.merchantQueryId] = e.target.value;
		setUnitPrice(currentUnitPrice);
	};
	const handleExpiry = (row) => {
		let current = [...expiredQueries];
		current.push(row.id);
		setExpiredQueries(current);
	};
	useEffect(() => {
		queries
			.getMerchantQueries(merchant.id, "RESPONSE_AWAITING", 1, 100)
			.then((response) => {
				const { totalCount, merchantQueries } = response;
				const filtered = _.filter(merchantQueries, (m) => {
					return m.merchantQueryStatus != "QUERY_EXPIRED";
				});
				const ret = _.map(filtered, (query) => {
					return buildQuery(query);
				});

				setMyQueries(ret);
			});
	}, []);

	const buildQuery = (query) => {
		const productQuery = query.ProductQuery;

		const brand = _.find(brands, (b) => {
			return b.id == productQuery.brandId;
		});
		const model = _.find(models, (m) => {
			return m.id == productQuery.modelsId;
		});
		const quantity = productQuery.quantity;
		const color = productQuery.color;
		const product = _.find(products, (p) => {
			return p.id == productQuery.productId;
		});
		const queryText = productQuery.queryText;

		let quality = "Original";
		if (productQuery.qualityVariant == "FIRST_COPY") {
			if (productQuery.variantId) {
				const variant = _.find(variants, (v) => {
					return v.id == productQuery.variantId;
				});
				quality = variant.name;
			} else {
				quality = "Other";
			}
		}

		const s = {
			id: query.id,
			image: productQuery.image,
			merchantQueryId: query.id,
			productQueryId: query.productQueryId,
			merchantId: query.merchantId,
			queryGroupId: query.queryGroupId,
			queryGroupName: query.queryGroupName,
			brand: brand,
			model: model,
			quality: quality,
			product: product,
			quantity: quantity,
			queryText: queryText,
			color: color,
			createdAt: productQuery.createdAt,
		};
		return s;
	};
	const handleUnavailable = (e, row) => {
		let current = { ...unavailableUnits };
		if (e.target.checked) {
			current[row.id] = true;
		} else {
			current[row.id] = false;
		}
		setUnavailableUnits(current);
		const remaining = _.filter(variantsAdded, (v) => {
			return v.merchantQueryId != row.merchantQueryId;
		});
		setVariantsAdded(remaining);
	};
	const handleShowImage = (image) => {
		setImageToShow(image);
		setShowImageDialog(true);
	};

	// const columns = [
	// 	{
	// 		field: "image",
	// 		headerName: "",
	// 		width: 100,
	// 		renderCell: (params) => {
	// 			return params.row.image ? (
	// 				<IconButton onClick={() => handleShowImage(params.row.image)}>
	// 					<IoDocumentAttach />
	// 				</IconButton>
	// 			) : (
	// 				<></>
	// 			);
	// 		},
	// 	},
	// 	{
	// 		field: "brand",
	// 		headerName: "Brand",
	// 		width: 100,
	// 		renderCell: (params) => {
	// 			return (
	// 				<MKTypography variant='caption' color='dark'>
	// 					{params.row?.brand?.name}
	// 				</MKTypography>
	// 			);
	// 		},
	// 	},
	// 	{
	// 		field: "model",
	// 		headerName: "Model",
	// 		width: 150,
	// 		renderCell: (params) => {
	// 			return (
	// 				<MKTypography variant='caption' color='dark'>
	// 					{params.row?.model?.name}
	// 				</MKTypography>
	// 			);
	// 		},
	// 	},
	// 	{
	// 		field: "color",
	// 		headerName: "Color",
	// 		width: 100,
	// 		renderCell: (params) => {
	// 			return (
	// 				<Stack
	// 					direction={"row"}
	// 					sx={{
	// 						alignItems: "center",
	// 						justifyContent: "center",
	// 						display: "flex",
	// 					}}
	// 					gap={1}
	// 				>
	// 					<Box
	// 						sx={{
	// 							width: 14,
	// 							height: 14,
	// 							backgroundColor: params.row?.color.hex,
	// 						}}
	// 					></Box>
	// 					<MKTypography variant='caption' color='dark'>
	// 						{params.row?.color.name}
	// 					</MKTypography>
	// 				</Stack>
	// 			);
	// 		},
	// 	},
	// 	{
	// 		field: "product",
	// 		headerName: "Product",
	// 		width: 120,
	// 		renderCell: (params) => {
	// 			return (
	// 				<MKTypography variant='caption' color='dark'>
	// 					{params.row?.product.name}
	// 				</MKTypography>
	// 			);
	// 		},
	// 	},
	// 	{
	// 		field: "quality",
	// 		headerName: "Quality",
	// 		width: 100,
	// 		renderCell: (params) => {
	// 			return (
	// 				<MKTypography variant='caption' color='dark'>
	// 					{params.row?.quality}
	// 				</MKTypography>
	// 			);
	// 		},
	// 	},
	// 	{
	// 		field: "quantity",
	// 		headerName: "Quantity",
	// 		width: 120,
	// 		renderCell: (params) => {
	// 			return (
	// 				<MKTypography variant='caption' color='dark'>
	// 					{params.row?.quantity}
	// 				</MKTypography>
	// 			);
	// 		},
	// 	},

	// 	{
	// 		field: "unavailable",
	// 		headerName: "Availability",
	// 		width: 150,
	// 		renderCell: (params) => {
	// 			return (
	// 				<FormControlLabel
	// 					control={
	// 						<Checkbox
	// 							onChange={(e) => handleUnavailable(e, params.row)}
	// 							sx={{
	// 								color: "#FB8C00",
	// 								"&.Mui-checked": {
	// 									color: "#FB8C00",
	// 								},
	// 							}}
	// 						/>
	// 					}
	// 					label='Unavailable'
	// 				/>
	// 			);
	// 		},
	// 	},
	// 	{
	// 		field: "unitPrice",
	// 		headerName: "Price",
	// 		width: 150,
	// 		renderCell: (params) => {
	// 			return (
	// 				<MKInput
	// 					disabled={
	// 						unavailableUnits[params.row.id] ||
	// 						expiredQueries.includes(params.row.id)
	// 					}
	// 					label='Unit price'
	// 					placeholder='Per Unit Price'
	// 					size='small'
	// 					onChange={(e) => handleUnitPriceUpdate(params.row, e)}
	// 				/>
	// 			);
	// 		},
	// 	},
	// 	{
	// 		field: "totalPrice",
	// 		headerName: "Total",
	// 		width: 150,
	// 		renderCell: (params) => {
	// 			return (
	// 				<Stack direction='column' gap={1}>
	// 					{unitPrice[params.row.id] && (
	// 						<MKTypography
	// 							color='dark'
	// 							variant='caption'
	// 							fontWeight='bold'
	// 							fontSize={14}
	// 						>
	// 							AED &nbsp;
	// 							{Number(unitPrice[params.row.id]) * Number(params.row.quantity)}
	// 						</MKTypography>
	// 					)}
	// 				</Stack>
	// 			);
	// 		},
	// 	},

	// 	{
	// 		field: "action",
	// 		headerName: "Action",
	// 		width: 150,
	// 		renderCell: (params) => {
	// 			return (
	// 				<Stack direction={"row"} gap={1}>
	// 					<Tooltip title='Submit'>
	// 						<MKButton
	// 							variant='contained'
	// 							color='warning'
	// 							size='small'
	// 							onClick={() => submitMerchantQueryResponse(params.row)}
	// 							disabled={expiredQueries.includes(params.row.id)}
	// 						>
	// 							<CiSaveUp1 /> &nbsp;Submit
	// 						</MKButton>
	// 					</Tooltip>
	// 				</Stack>
	// 			);
	// 		},
	// 	},
	// 	{
	// 		field: "countDown",
	// 		headerName: "Expiring In",
	// 		width: 150,
	// 		renderCell: (params) => {
	// 			return (
	// 				<MKTypography color='error' variant='body2'>
	// 					<MKCountdown
	// 						createdAt={params.row.createdAt}
	// 						onExpiry={() => handleExpiry(params.row)}
	// 					/>
	// 				</MKTypography>
	// 			);
	// 		},
	// 	},
	// 	{
	// 		field: "remarks",
	// 		headerName: "Remarks",
	// 		width: 150,
	// 		renderCell: (params) => {
	// 			return (
	// 				<MKTypography color='error' variant='caption'>
	// 					{params.row.queryText}
	// 				</MKTypography>
	// 			);
	// 		},
	// 	},
	// ];

	return (
		<div>
			<MKBreadCrumbs crumbs={breadcrumb} />
			<Snackbar
				open={open}
				autoHideDuration={4000}
				onClose={() => setOpen(false)}
				message={
					<MKTypography variant='caption' color='white'>
						{message}
					</MKTypography>
				}
				action={
					<MKButton
						iconOnly
						color='white'
						circular
						size='small'
						onClick={() => setOpen(false)}
					>
						<CheckCircle />
					</MKButton>
				}
			/>
			<Dialog open={showImageDialog} onClose={() => setShowImageDialog(false)}>
				<DialogContent>
					<div
						style={{ width: "500px", height: "500px", objectFit: "contain" }}
					>
						<img src={imageToShow} width='100%' height='100%' />
					</div>
				</DialogContent>
			</Dialog>
			<Paper
				sx={{
					bgcolor: "#ffffff",
					alignItems: "center",
					justifyContent: "center",
					padding: 10,
					display: "flex",
					flexDirection: "column",
				}}
				elevation={0}
			>
				{/* // 	{myQueries.length ? (
			// 		<div style={{ width: "100%" }}>
			// 			<div style={{ width: "100%" }}>
			// 				<DataGrid
			// 					columns={columns}
			// 					rows={myQueries}
			// 					pageSizeOptions={[5]}
			// 					disableRowSelectionOnClick
			// 					autoHeight
			// 				/>
			// 			</div>
			// 		</div>
			// 	) : (
			// 		<MKTypography variant='h3' color='dark'>
			// 			No Active Enquiry
			// 		</MKTypography>
			// 	)} */}
				<Stack gap={3}>
					{_.map(myQueries, (query) => {
						return (
							<Card sx={styles.card} elevation={0}>
								{query.image && (
									<CardMedia
										component='img'
										height='194'
										src={query.image}
										alt='Product image'
										onClick={() => handleShowImage(query.image)}
									></CardMedia>
								)}
								<CardHeader
									title={
										<Stack direction={"row"} gap={3}>
											<Paper
												sx={{
													width: 64,
													height: 64,
													borderRadius: 100,
													alignItems: "center",
													justifyContent: "center",
													display: "flex",
													borderColor: "#451752",
													backgroundColor: "#121212",
												}}
												elevation={0}
											>
												<PiPackageThin size={34} color='#ffffff' />
											</Paper>
											<Stack>
												<MKTypography variant='body1'>
													{query.brand.name} {query.model.name}
												</MKTypography>
												<MKTypography variant='body2'>
													{query.product.name}
												</MKTypography>
											</Stack>
										</Stack>
									}
									subheader={
										<Stack
											gap={3}
											direction={"row"}
											sx={{
												alignItems: "center",
												justifyContent: "flex-end",
											}}
										></Stack>
									}
									action={
										<Stack gap={1}>
											<Box
												style={{
													alignItems: "center",
													justifyContent: "flex-start",
													flexDirection: "row",
													display: "flex",
												}}
											>
												<Box
													style={{
														width: 15,
														height: 15,
														backgroundColor: query.color.hex,
														marginRight: 10,
													}}
												></Box>
												<MKTypography variant='caption'>
													{query.color?.name}
												</MKTypography>
											</Box>
											<MKTypography variant='caption' fontWeight='bold'>
												Qty: {query.quantity}
											</MKTypography>
											<Box
												sx={{
													p: 1,
													backgroundColor: "#fbe9e7",
													width: 75,
													alignItems: "center",
													justifyContent: "center",
													display: "flex",
												}}
											>
												<MKTypography color='error' variant='caption'>
													<MKCountdown
														createdAt={query.createdAt}
														onExpiry={() => handleExpiry(query)}
													/>
												</MKTypography>
											</Box>
										</Stack>
									}
								/>
								<CardContent
									sx={{
										p: 2,
										borderColor: "#121212",
										borderWidth: 1,
										borderRadius: 5,
										backgroundColor: "#f5f5f5",
									}}
								>
									<Box
										sx={{
											width: "100%",
											alignItems: "center",
											justifyContent: "space-between",
											display: "flex",
											cursor: "pointer",
											flexDirection: "row",
										}}
									>
										<Stack
											direction='row'
											gap={1}
											sx={{
												alignItems: "center",
												justifyContent: "center",
												display: "flex",
											}}
										>
											<MKTypography
												variant='caption'
												fontSize='18'
												onClick={() => addVariants(query.merchantQueryId)}
											>
												Add Available Variants & Prices
											</MKTypography>
											<IconButton
												onClick={() => addVariants(query.merchantQueryId)}
											>
												<MdOutlinePostAdd />
											</IconButton>
										</Stack>
										<Stack direction='row'>
											<IconButton
												onClick={() => handleDoneSubmittingAllVariants(query)}
											>
												<IoCheckmarkDoneCircleOutline />
											</IconButton>
										</Stack>
									</Box>
									{!unavailableUnits[query.id] ? (
										<Stack gap={1}>
											{_.map(
												_.filter(variantsAdded, (va) => {
													return va.merchantQueryId == query.merchantQueryId;
												}),
												(v) => {
													return (
														<Stack direction='row' gap={2}>
															<MKInput
																placeholder='Name'
																value={v.name}
																label='Name'
																disabled={v.name == "Original"}
																onChange={(e) =>
																	handleVariantNameChange(e.target.value, v.id)
																}
															/>

															<MKInput
																placeholder='Price'
																value={v.unavailable ? "unavailable" : v.price}
																label='Price'
																variant={
																	v.unavailable ? "standard" : "outlined"
																}
																onChange={(e) =>
																	handleVariantPriceChange(e.target.value, v.id)
																}
																disabled={v.unavaiable}
																hintText='Unavailable'
															/>
															<MKButton
																variant='contained'
																color='black'
																onClick={() => handleSubmitV2(v.id, query)}
															>
																Submit&nbsp;
															</MKButton>
															<IconButton onClick={() => removeVariant(v.id)}>
																{v.unavailable ? (
																	<CiSquareRemove />
																) : (
																	<CiSquareRemove />
																)}
															</IconButton>
															<IconButton
																onClick={() =>
																	addVariants(query.merchantQueryId)
																}
															>
																<MdOutlinePostAdd />
															</IconButton>
														</Stack>
													);
												}
											)}
										</Stack>
									) : (
										<MKButton
											variant='contained'
											color='black'
											fullWidth
											onClick={() => handleSubmitV2(null, query)}
										>
											Submit&nbsp;
											<MdArrowCircleRight size={24} />
										</MKButton>
									)}
								</CardContent>
							</Card>
						);
					})}
				</Stack>
			</Paper>
		</div>
	);
};

export default MerchantQueries;

const styles = {
	card: {
		width: 600,
		p: 3,
		backgroundColor: "#fcfcfc",
		elevation: 0,
	},
	container: {
		minHeight: "100vh",
		width: "100%",
		padding: 2,
	},
	sideBar: {
		minHeight: "100vh",
		width: "100%",
		padding: 2,
		backgroundColor: "#FBFCFA",
	},
	filter: {
		minHeight: "5vh",
		width: "100%",
		padding: 2,
		backgroundColor: "#FCFCFC",
		alignItems: "center",
		justifyContent: "space-between",
		display: "flex",
		flexDirection: "row",
	},
	queryRow: {
		width: "100%",
		padding: 2,
		alignItems: "center",
		justifyContent: "space-between",
		display: "flex",
		flexDirection: "row",
		marginTop: 1,
		backgroundColor: "#FEFCFC",
	},
};
