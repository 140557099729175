import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Autocomplete,
	Box,
	Chip,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	FormControl,
	Grid,
	IconButton,
	InputLabel,
	Menu,
	MenuItem,
	Pagination,
	Select,
	Snackbar,
	Stack,
	TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import MKTypography from "components/MKTypography";
import orderService from "../../api/orders";
import { useSelector } from "react-redux";
import { PiHourglassHighThin } from "react-icons/pi";
import _ from "lodash";
import MKNodata from "components/MKNodata";
import MKButton from "components/MKButton";
import { BiChevronDownCircle } from "react-icons/bi";
import { ArrowDropDown, CheckCircle } from "@mui/icons-material";
import { Warning } from "@phosphor-icons/react";
import { FaCircleUser, FaStreetView, FaUserTie } from "react-icons/fa6";
import { CiCalendarDate, CiMoneyBill, CiUser } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import { IoDocumentAttach } from "react-icons/io5";
const paymentStatus = {
	UNPAID: {
		label: "unpaid",
		bgcolor: "#FFFAF4",
		icon: (
			<PiHourglassHighThin color='darkorchid' weight='duotone'>
				<animate
					attributeName='opacity'
					values='0;1;0'
					dur='4s'
					repeatCount='indefinite'
				></animate>
				<animateTransform
					attributeName='transform'
					attributeType='XML'
					type='rotate'
					dur='5s'
					from='0 0 0'
					to='360 0 0'
					repeatCount='indefinite'
				></animateTransform>
			</PiHourglassHighThin>
		),
	},
};
// enum OrderStatus {
//   pending
//   processing
//   packed
//   onHold
//   completed
//   cancelled
//   refunded
//   failed
//   trash
//   returned
//   pickedFromStore
//   returnAccepted
//   returnRejected
//   oombi
// }

const statuses = [
	{ id: 1, label: "New", description: "New Order", value: "pending" },
	{
		id: 2,
		label: "Processing",
		description: "Start Processing",
		value: "processing",
	},
	{
		id: 2,
		label: "Processing",
		description: "Start Processing",
		value: "processing",
	},
	{
		id: 3,
		label: "Packed",
		description: "Packed & Waiting for Pickup",
		value: "packed",
	},
	{
		id: 4,
		label: "On Hold",
		description: "On Hold. Cant Fulfill at this moment",
		value: "onHold",
	},
	{
		id: 5,
		label: "Completed",
		description: "Order is Completed & Delivered. Payment Received",
		value: "completed",
	},
	{
		id: 6,
		label: "Cancelled",
		description: "Order Cancelled by Customer",
		value: "cancelled",
	},
	{
		id: 7,
		label: "Refunded",
		description: "Order was refunded",
		value: "refunded",
	},
	{
		id: 8,
		label: "Failed",
		description: "Order fulfillement Failed",
		value: "failed",
	},
	{
		id: 9,
		label: "Trash",
		description: "Trash the Order",
		value: "trash",
	},
	{
		id: 10,
		label: "Returned",
		description: "Order was returned due to damage/unfulfilment",
		value: "returned",
	},

	{
		id: 11,
		label: "Picked From Store",
		description: "Order was picked by delivery boy",
		value: "pickedFromStore",
	},
	{
		id: 12,
		label: "Accept Return",
		description: "Returned product is accepted",
		value: "returnAccepted",
	},
	{
		id: 13,
		label: "Reject Return",
		description: "Returned product is rejected",
		value: "returnRejected",
	},
];

const menus = {
	pending: [{ id: 1, label: "Ready for Pickup", value: "ready_for_pickup" }],
	ready_for_pickup: [{ id: 1, label: "New Order", value: "pending" }],
	returned_for_refund: [
		{
			id: 1,
			label: "Accept Return & Refund",
			value: "returnAccepted_for_refund",
		},
		{
			id: 2,
			label: "Reject Return & Refund",
			value: "returnRejected",
		},
	],
	returned_for_replacement: [
		{
			id: 1,
			label: "Accept Return & Replacement",
			value: "returnAccepted_for_replacement",
		},
		{
			id: 2,
			label: "Reject Return",
			value: "returnRejected",
		},
	],
	returned_for_replacement: [
		{ id: 1, label: "Ready for Pickup", value: "ready_for_pickup" },
	],
};
const lineItemStatus = {
	returnAccepted_for_refund: "Refund Accepted",
	returnAccepted_for_replacement: "Replacement Pending",
	pending: "New Order",
	ready_for_pickup: "Pending Pickup",
	returnRejected: "Return Rejected",
};

const MerchantOrders = (props) => {
	const [orderList, setOrders] = useState([]);
	const [selectedStatus, setSelectedStatus] = useState(null);
	const [prevStatus, setPrevStatus] = useState(null);
	const merchant = useSelector((state) => state.merchants.merchant);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [dialogOpen, setDialogOpen] = useState(false);
	const [selectedProduct, setSelectedProduct] = useState(null);
	const open = Boolean(anchorEl);
	const [message, setMessage] = useState("");
	const [snackOpen, setSnackOpen] = useState(false);
	const [page, setPage] = useState(1);
	const [count, setCount] = useState(20);
	const [totalCount, setTotalCount] = useState(0);
	const [queryGroupsByDate, setQueryGroupsByDate] = useState([]);
	const [queryGroups, setQueryGroups] = useState([]);
	const navigate = useNavigate();
	const [imageToShow, setImageToShow] = useState("");
	const [showImageDialog, setShowImageDialog] = useState(false);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleSelect = (e) => {
		const { current, selected, product } = e.target.value;
		console.log(selected, product);
		setSelectedStatus(selected);
		setPrevStatus(current);
		setSelectedProduct(product);
		setDialogOpen(true);
		handleClose();
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const updateOrderStatus = () => {
		setDialogOpen(false);
		console.log(selectedProduct, selectedStatus);
		orderService
			.updateOrderProductStatus(selectedProduct.id, selectedStatus.value)
			.then((result) => {
				refresh();
			})
			.catch((err) => {
				setMessage("Error Updating Order Status");
				setSnackOpen(true);
			});
	};

	const columns = [
		{
			field: "id",
			headerName: "Order",
			width: 80,
			renderCell: (params) => {
				return (
					<MKTypography variant='caption' color='dark'>
						{params.row?.id}
					</MKTypography>
				);
			},
		},
		// {
		// 	field: "date",
		// 	headerName: "Date",
		// 	width: 100,
		// 	renderCell: (params) => {
		// 		return (
		// 			<MKTypography variant='caption' color='dark'>
		// 				{new Date(params.row?.createdAt).toLocaleDateString("en-US", {
		// 					year: "numeric",
		// 					month: "short",
		// 					day: "numeric",
		// 				})}
		// 			</MKTypography>
		// 		);
		// 	},
		// },
		// {
		// 	field: "brand",
		// 	headerName: "Brand",
		// 	width: 80,
		// 	renderCell: (params) => {
		// 		return (
		// 			<MKTypography variant='caption' color='dark'>
		// 				{params.row?.ProductQuery?.Brand.name}
		// 			</MKTypography>
		// 		);
		// 	},
		// },
		// {
		// 	field: "model",
		// 	headerName: "Model",
		// 	width: 115,
		// 	renderCell: (params) => {
		// 		return (
		// 			<MKTypography variant='caption' color='dark'>
		// 				{params.row?.ProductQuery?.Models.name}
		// 			</MKTypography>
		// 		);
		// 	},
		// },
		{
			field: "color",
			headerName: "Color",
			width: 100,
			renderCell: (params) => {
				return (
					<Stack
						direction={"row"}
						sx={{
							alignItems: "center",
							justifyContent: "center",
							display: "flex",
						}}
						gap={1}
					>
						<Box
							sx={{
								width: 14,
								height: 14,
								backgroundColor: params.row?.ProductQuery?.color.hex,
							}}
						></Box>
						<MKTypography variant='caption' color='dark'>
							{params.row?.ProductQuery?.color.name}
						</MKTypography>
					</Stack>
				);
			},
		},
		// {
		// 	field: "product",
		// 	headerName: "Product",
		// 	width: 150,
		// 	renderCell: (params) => {
		// 		return (
		// 			<MKTypography variant='caption' color='dark'>
		// 				{params.row.Product.name}
		// 			</MKTypography>
		// 		);
		// 	},
		// },
		{
			field: "quality",
			headerName: "Variant",
			width: 115,
			renderCell: (params) => {
				return (
					<MKTypography variant='caption' color='dark'>
						{params.row?.Variant ? params.row.Variant?.name : "Original"}
					</MKTypography>
				);
			},
		},
		{
			field: "qty",
			headerName: "Quantity",
			width: 90,
			renderCell: (params) => {
				return (
					<MKTypography variant='caption' color='dark' fontWeight='bold'>
						{params.row?.quantity}
					</MKTypography>
				);
			},
		},
		{
			field: "status",
			headerName: "Status",
			width: 115,
			renderCell: (params) => {
				return (
					<Box
						sx={{
							backgroundColor:
								paymentStatus[params.row?.Order?.paymentStatus].bgcolor,
							padding: 1,
							height: 30,
							alignItems: "center",
							justifyContent: "center",
							display: "flex",
						}}
					>
						<Stack
							direction={"row"}
							gap={1}
							sx={{
								alignItems: "center",
								justifyContent: "center",
								display: "flex",
							}}
						>
							{paymentStatus[params.row.Order.paymentStatus].icon}
							<MKTypography variant='caption' color='dark'>
								{paymentStatus[params.row.Order.paymentStatus].label}
							</MKTypography>
						</Stack>
					</Box>
				);
			},
		},
		{
			field: "total",
			headerName: "Total",
			width: 90,
			renderCell: (params) => {
				return (
					<MKTypography variant='caption' color='dark' fontWeight='bold'>
						AED&nbsp;
						{params.row.totalPrice.amount - params.row.markup}
					</MKTypography>
				);
			},
		},
		{
			field: "actions",
			headerName: "Actions",
			width: 300,
			renderCell: (params) => {
				return (
					<Stack direction={"row"} gap={1}>
						{menus[params.row.lineItemStatus] &&
							_.map(menus[params.row.lineItemStatus], (status) => {
								if (status.label) {
									return (
										<MKButton
											size='small'
											variant='outlined'
											color='black'
											onClick={() =>
												handleSelect(
													params.row.lineItemStatus,
													status,
													params.row
												)
											}
										>
											{status.label}
										</MKButton>
									);
								}
							})}
					</Stack>
				);
			},
		},
	];

	useEffect(() => {
		refresh();
	}, [page]);
	const handleShowImage = (image) => {
		setImageToShow(image);
		setShowImageDialog(true);
	};

	const refresh = () => {
		orderService
			.getOrders(merchant?.id, null, page, count)
			.then((result) => {
				const { totalCount, orders } = result;

				console.log("ORDERS", result);

				const grouped = _.groupBy(orders, (o) => {
					return o.ProductQuery.queryGroupId;
				});

				const groupedByQueryGroupAndDate = _.mapValues(
					_.groupBy(result.orders, (o) => o.createdAt.split("T")[0]),
					(queryGroupOrders) => {
						return _.groupBy(
							queryGroupOrders,
							(o) => o.ProductQuery.queryGroupId
						);
					}
				);
				console.log("GROUPED BY DATE", groupedByQueryGroupAndDate);
				console.log(
					"GROUPED BY DATE Values",
					Object.values(groupedByQueryGroupAndDate)
				);
				console.log("GROUPED", grouped);
				setQueryGroupsByDate(groupedByQueryGroupAndDate);
				setQueryGroups(grouped);
				setOrders(orders);
				setTotalCount(totalCount);
			})
			.catch((err) => {
				console.log(err);
			});
	};
	return (
		<Box>
			<Snackbar
				open={snackOpen}
				autoHideDuration={4000}
				onClose={() => setSnackOpen(false)}
				message={
					<MKTypography variant='caption' color='white'>
						{message}
					</MKTypography>
				}
				action={
					<MKButton
						iconOnly
						color='white'
						circular
						size='small'
						onClick={() => {
							setSnackOpen(false);
						}}
					>
						<CheckCircle />
					</MKButton>
				}
			/>
			<Dialog
				open={dialogOpen}
				onClose={() => setDialogOpen(false)}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<DialogTitle id='alert-dialog-title'>
					<MKTypography variant='body2' color='dark'>
						<Warning /> Order Status Update
					</MKTypography>
				</DialogTitle>
				<DialogContent>
					<DialogContentText id='alert-dialog-description'>
						<MKTypography variant='caption' color='dark'>
							You are updating the order for {selectedProduct?.Product?.name}
							&nbsp; status from <strong>{prevStatus}</strong> &nbsp;to&nbsp;
							<strong>{selectedStatus?.label}</strong>. <br />
							Proceed?
						</MKTypography>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<MKButton
						onClick={() => setDialogOpen(false)}
						variant='contained'
						color='error'
						autoFocus
					>
						NO
					</MKButton>
					<MKButton
						onClick={updateOrderStatus}
						variant='contained'
						color='black'
					>
						Yes
					</MKButton>
				</DialogActions>
			</Dialog>
			<Box
				sx={{
					height: "10vh",
					width: "100%",
					alignItems: "center",
					justifyContent: "center",
					display: "flex",
				}}
			>
				<Pagination
					color='primary'
					count={Math.ceil(totalCount / count)}
					page={page}
					onChange={(e, val) => setPage(val)}
				/>
			</Box>
			{_.map(queryGroupsByDate, (queryGroups1) => {
				console.log("Groups in", queryGroups1);
				const groupsA = _.flatten(Object.values(queryGroups1));
				let total = 0;
				let hasNew = false;
				_.map(groupsA, (order) => {
					total += order.totalPrice.amount - order.markup;
					if (order.lineItemStatus == "pending") {
						hasNew = true;
					}
				});
				return (
					<Accordion
						defaultExpanded={false}
						sx={{ bgcolor: "#fcfcfc", mt: 3 }}
						elevation={0}
					>
						<AccordionSummary expandIcon={<ArrowDropDown />}>
							<Grid container spacing={2}>
								<Grid item xs={4} sm={3}>
									<Stack
										direction='column'
										gap={1}
										sx={{
											alignItems: "flex-start",
											justifyContent: "center",
											display: "flex",
										}}
									>
										<MKTypography
											variant='caption'
											color='dark'
											fontWeight='bold'
											sx={{
												alignItems: "center",
												justifyContent: "center",
												display: "flex",
											}}
										>
											<CiCalendarDate size={14} />
											&nbsp; Order Date
										</MKTypography>

										<MKTypography variant='caption' color='dark'>
											{new Date(
												groupsA[0].ProductQuery.createdAt
											).toLocaleDateString("en-US", {
												year: "numeric",
												month: "short",
												day: "numeric",
											})}
										</MKTypography>
									</Stack>
								</Grid>
								<Grid item xs={4} sm={2}>
									<Stack
										direction='column'
										gap={1}
										sx={{
											alignItems: "flex-start",
											justifyContent: "center",
											display: "flex",
										}}
									>
										<MKTypography
											variant='caption'
											color='dark'
											fontWeight='bold'
											sx={{
												alignItems: "center",
												justifyContent: "center",
												display: "flex",
											}}
										>
											<CiUser size={14} /> &nbsp; Client Id
										</MKTypography>

										<MKTypography variant='caption' color='dark'>
											J-1001-{groupsA[0].ProductQuery.storeId}
										</MKTypography>
									</Stack>
								</Grid>
								<Grid item xs={4} sm={2}>
									<Stack
										direction='column'
										gap={1}
										sx={{
											alignItems: "center",
											justifyContent: "center",
											display: "flex",
										}}
									>
										<MKTypography
											variant='caption'
											color='dark'
											fontWeight='bold'
											sx={{
												alignItems: "center",
												justifyContent: "center",
												display: "flex",
											}}
										>
											<CiUser size={14} /> &nbsp; Items
										</MKTypography>

										<MKTypography variant='caption' color='dark'>
											{groupsA.length}
										</MKTypography>
									</Stack>
								</Grid>
								<Grid item xs={4} sm={3}>
									<Stack
										direction='column'
										gap={1}
										sx={{
											alignItems: "center",
											justifyContent: "center",
											display: "flex",
										}}
									>
										<MKTypography
											variant='caption'
											color='dark'
											fontWeight='bold'
											sx={{
												alignItems: "center",
												justifyContent: "center",
												display: "flex",
											}}
										>
											<CiMoneyBill size={16} /> &nbsp; Total Bill
										</MKTypography>

										<MKTypography variant='caption' color='dark'>
											AED&nbsp;{total}
										</MKTypography>
									</Stack>
								</Grid>
								{hasNew && (
									<Grid item xs={4} sm={2}>
										<Chip
											size='small'
											sx={{
												backgroundColor: "blue",
												maxWidth: 80,
												borderRadius: 1,
											}}
											label={
												<MKTypography variant='caption' color='white'>
													New
												</MKTypography>
											}
										/>
									</Grid>
								)}
							</Grid>
						</AccordionSummary>
						<AccordionDetails>
							{_.map(groupsA, (order) => {
								return (
									<Grid
										container
										spacing={2}
										sx={{ padding: 1, backgroundColor: "#fafafa", mt: 1 }}
									>
										<Dialog
											open={showImageDialog}
											onClose={() => setShowImageDialog(false)}
										>
											<DialogContent>
												<div
													style={{
														width: "500px",
														height: "500px",
														objectFit: "contain",
													}}
												>
													<img src={imageToShow} width='100%' height='100%' />
												</div>
											</DialogContent>
										</Dialog>
										<Grid item sm={1}>
											<Stack direction='column' gap={1}>
												<MKTypography
													variant='caption'
													color='dark'
													fontWeight='bold'
												>
													Attachment
												</MKTypography>
												{order?.ProductQuery.image ? (
													<IconButton
														onClick={() =>
															handleShowImage(order?.ProductQuery.image)
														}
													>
														<IoDocumentAttach />
													</IconButton>
												) : (
													<MKTypography
														variant='caption'
														color='dark'
														fontWeight='bold'
													>
														NA
													</MKTypography>
												)}
											</Stack>
										</Grid>

										<Grid item sm={1}>
											<Stack direction='column' gap={1}>
												<MKTypography
													variant='caption'
													color='dark'
													fontWeight='bold'
												>
													OrderId
												</MKTypography>
												<MKTypography
													variant='caption'
													color='dark'
													sx={{ minWidth: 100 }}
												>
													{order?.id}
												</MKTypography>
											</Stack>
										</Grid>

										<Grid item sm={1}>
											<Stack direction='column' gap={1}>
												<MKTypography
													variant='caption'
													color='dark'
													fontWeight='bold'
												>
													Color
												</MKTypography>
												<Stack
													direction={"row"}
													sx={{
														alignItems: "center",
														justifyContent: "flex-start",
														display: "flex",
													}}
													gap={1}
												>
													<Box
														sx={{
															width: 14,
															height: 14,
															backgroundColor: order?.ProductQuery?.color.hex,
														}}
													></Box>
													<MKTypography variant='caption' color='dark'>
														{order?.ProductQuery?.color.name}
													</MKTypography>
												</Stack>
											</Stack>
										</Grid>
										<Grid item xs={2} sm={5}>
											<Stack direction='column' gap={1}>
												<MKTypography
													variant='caption'
													color='dark'
													fontWeight='bold'
												>
													Product Details
												</MKTypography>
												<MKTypography variant='caption' color='dark'>
													{order.ProductQuery?.Brand.name} &nbsp;
													{order.ProductQuery?.Models.name} &nbsp;
													{order.Product.name}&nbsp;
													<strong>
														({order?.Variant ? order.Variant?.name : "Original"}
														) &nbsp;X&nbsp;{order.quantity}
													</strong>
												</MKTypography>
											</Stack>
										</Grid>

										{/* <Grid item sm={2} xs={1}>
											<Stack direction='column' gap={1}>
												<MKTypography
													variant='caption'
													color='dark'
													fontWeight='bold'
												>
													Order Status
												</MKTypography>
												<MKTypography
													variant='caption'
													color='black'
													size='small'
													aria-controls={open ? "basic-menu" : undefined}
													aria-haspopup='true'
													aria-expanded={open ? "true" : undefined}
													onClick={handleClick}
												>
													{lineItemStatus[order.lineItemStatus]}
												</MKTypography>
											</Stack>
										</Grid> */}
										<Grid item sm={1}>
											<Stack direction='column' gap={1}>
												<MKTypography
													variant='caption'
													color='dark'
													fontWeight='bold'
												>
													Total
												</MKTypography>
												<MKTypography
													variant='caption'
													color='dark'
													fontWeight='bold'
												>
													AED&nbsp;
													{(
														order.totalPrice.amount -
														order.markup * order.quantity
													).toFixed(2)}
												</MKTypography>
											</Stack>
										</Grid>
										<Grid item sm={2}>
											<Stack direction={"column"} gap={2}>
												{/* <div
													aria-controls={open ? "basic-menu" : undefined}
													aria-haspopup='true'
													aria-expanded={open ? "true" : undefined}
													style={{ cursor: "pointer" }}
												>
													
												</div> */}
												<MKTypography
													variant='caption'
													color='dark'
													fontWeight='bold'
												>
													Status: {lineItemStatus[order.lineItemStatus]}
												</MKTypography>

												{menus[order.lineItemStatus] && (
													<Box sx={{ minWidth: 120 }}>
														<FormControl fullWidth>
															<InputLabel id='demo-simple-select-label'>
																Change
															</InputLabel>
															<Select
																labelId='demo-simple-select-label'
																id='demo-simple-select'
																value={order.lineItemStatus}
																label={
																	<MKTypography variant='caption' color='dark'>
																		Status
																	</MKTypography>
																}
																onChange={handleSelect}
															>
																{_.map(
																	menus[order.lineItemStatus],
																	(status) => {
																		if (status.label) {
																			return (
																				<MenuItem
																					// onClick={() => {
																					// 	setAnchorEl(undefined);
																					// 	handleSelect(
																					// 		order.lineItemStatus,
																					// 		status,
																					// 		order
																					// 	);
																					// }}
																					value={{
																						current: order.lineItemStatus,
																						selected: status,
																						product: order,
																					}}
																					style={{ cursor: "pointer" }}
																				>
																					{status.label}
																				</MenuItem>
																			);
																		}
																	}
																)}
															</Select>
														</FormControl>
													</Box>
												)}

												{/* {menus[order.lineItemStatus] ? (
													_.map(menus[order.lineItemStatus], (status) => {
														if (status.label) {
															return (
																<div
																	onClick={() => {
																		setAnchorEl(undefined);
																		handleSelect(
																			order.lineItemStatus,
																			status,
																			order
																		);
																	}}
																	style={{ cursor: "pointer" }}
																>
																	{status.label}
																</div>
															);
														}
													})
												) : (
													<MKTypography
														variant='caption'
														color='dark'
														sx={{ mt: 1 }}
													>
														--:--
													</MKTypography>
												)} */}
											</Stack>
										</Grid>
										{order.ProductQuery.queryText && (
											<Grid item xs={12} sm={12}>
												<MKTypography variant='body2' color='error'>
													Remarks: {order.ProductQuery.queryText}
												</MKTypography>
											</Grid>
										)}

										<Grid item xs={12} sm={12}>
											<Divider sx={{ width: "100%" }} />
										</Grid>
									</Grid>
								);
							})}
						</AccordionDetails>
					</Accordion>
				);
			})}
			{/* {_.map(queryGroups, (group) => {
				// console.log("GROUP", group);
				let amounts = 0;
				_.map(group, (o) => {
					amounts += Number(o.totalPrice.amount);
				});
				console.log(amounts);
				return (
					<Accordion
						defaultExpanded={false}
						sx={{ bgcolor: "#fcfcfc", mt: 3 }}
						elevation={0}
					>
						<AccordionSummary expandIcon={<ArrowDropDown />}>
							<Grid container spacing={2}>
								<Grid item xs={4} sm={3}>
									<Stack
										direction='column'
										gap={1}
										sx={{
											alignItems: "flex-start",
											justifyContent: "center",
											display: "flex",
										}}
									>
										<MKTypography
											variant='caption'
											color='dark'
											fontWeight='bold'
											sx={{
												alignItems: "center",
												justifyContent: "center",
												display: "flex",
											}}
										>
											<CiCalendarDate size={14} />
											&nbsp; Order Date
										</MKTypography>

										<MKTypography variant='caption' color='dark'>
											{new Date(
												group[0].ProductQuery.createdAt
											).toLocaleDateString("en-US", {
												year: "numeric",
												month: "short",
												day: "numeric",
											})}
										</MKTypography>
									</Stack>
								</Grid>
								<Grid item xs={4} sm={2}>
									<Stack
										direction='column'
										gap={1}
										sx={{
											alignItems: "flex-start",
											justifyContent: "center",
											display: "flex",
										}}
									>
										<MKTypography
											variant='caption'
											color='dark'
											fontWeight='bold'
											sx={{
												alignItems: "center",
												justifyContent: "center",
												display: "flex",
											}}
										>
											<CiUser size={14} /> &nbsp; Client Id
										</MKTypography>

										<MKTypography variant='caption' color='dark'>
											J-1001-{group[0].ProductQuery.storeId}
										</MKTypography>
									</Stack>
								</Grid>
								<Grid item xs={4} sm={2}>
									<Stack
										direction='column'
										gap={1}
										sx={{
											alignItems: "center",
											justifyContent: "center",
											display: "flex",
										}}
									>
										<MKTypography
											variant='caption'
											color='dark'
											fontWeight='bold'
											sx={{
												alignItems: "center",
												justifyContent: "center",
												display: "flex",
											}}
										>
											<CiUser size={14} /> &nbsp; Items
										</MKTypography>

										<MKTypography variant='caption' color='dark'>
											{group.length}
										</MKTypography>
									</Stack>
								</Grid>
								<Grid item xs={4} sm={3}>
									<Stack
										direction='column'
										gap={1}
										sx={{
											alignItems: "center",
											justifyContent: "center",
											display: "flex",
										}}
									>
										<MKTypography
											variant='caption'
											color='dark'
											fontWeight='bold'
											sx={{
												alignItems: "center",
												justifyContent: "center",
												display: "flex",
											}}
										>
											<CiMoneyBill size={16} /> &nbsp; Total Bill
										</MKTypography>

										<MKTypography variant='caption' color='dark'>
											AED&nbsp;{amounts}
										</MKTypography>
									</Stack>
								</Grid>
								<Grid item xs={4} sm={2}>
									<Stack
										direction='column'
										gap={1}
										sx={{
											alignItems: "center",
											justifyContent: "center",
											display: "flex",
										}}
									>
										<MKTypography
											variant='caption'
											color='dark'
											fontWeight='bold'
											sx={{
												alignItems: "center",
												justifyContent: "center",
												display: "flex",
											}}
										>
											<FaStreetView size={16} /> &nbsp; View Details
										</MKTypography>
										<MKButton
											variant='contained'
											color='black'
											size='small'
											onClick={() =>
												navigate("/order-detail", { state: { order: group } })
											}
										>
											Details
										</MKButton>
									</Stack>
								</Grid>
							</Grid>
						</AccordionSummary>
						<AccordionDetails>
							{_.map(group, (order) => {
								return (
									<Grid
										container
										spacing={2}
										sx={{ padding: 1, backgroundColor: "#fafafa", mt: 1 }}
									>
										<Grid item sm={1}>
											<Stack direction='column' gap={1}>
												<MKTypography
													variant='caption'
													color='dark'
													fontWeight='bold'
												>
													OrderId
												</MKTypography>
												<MKTypography
													variant='caption'
													color='dark'
													sx={{ minWidth: 100 }}
												>
													{order?.id}
												</MKTypography>
											</Stack>
										</Grid>

										<Grid item sm={1}>
											<Stack direction='column' gap={1}>
												<MKTypography
													variant='caption'
													color='dark'
													fontWeight='bold'
												>
													Color
												</MKTypography>
												<Stack
													direction={"row"}
													sx={{
														alignItems: "center",
														justifyContent: "flex-start",
														display: "flex",
													}}
													gap={1}
												>
													<Box
														sx={{
															width: 14,
															height: 14,
															backgroundColor: order?.ProductQuery?.color.hex,
														}}
													></Box>
													<MKTypography variant='caption' color='dark'>
														{order?.ProductQuery?.color.name}
													</MKTypography>
												</Stack>
											</Stack>
										</Grid>
										<Grid item xs={2} sm={4}>
											<Stack direction='column' gap={1}>
												<MKTypography
													variant='caption'
													color='dark'
													fontWeight='bold'
												>
													Product Details
												</MKTypography>
												<MKTypography variant='caption' color='dark'>
													{order.ProductQuery?.Brand.name} &nbsp;
													{order.ProductQuery?.Models.name} &nbsp;
													{order.Product.name}&nbsp;
													<strong>
														({order?.Variant ? order.Variant?.name : "Original"}
														) &nbsp;X&nbsp;{order.quantity}
													</strong>
												</MKTypography>
											</Stack>
										</Grid>

										<Grid item sm={2} xs={1}>
											<Stack direction='column' gap={1}>
												<MKTypography
													variant='caption'
													color='dark'
													fontWeight='bold'
												>
													Order Status
												</MKTypography>
												<MKTypography
													variant='caption'
													color='black'
													size='small'
													aria-controls={open ? "basic-menu" : undefined}
													aria-haspopup='true'
													aria-expanded={open ? "true" : undefined}
													onClick={handleClick}
												>
													{lineItemStatus[order.lineItemStatus]}
												</MKTypography>
											</Stack>
										</Grid>
										<Grid item sm={1}>
											<Stack direction='column' gap={1}>
												<MKTypography
													variant='caption'
													color='dark'
													fontWeight='bold'
												>
													Total
												</MKTypography>
												<MKTypography
													variant='caption'
													color='dark'
													fontWeight='bold'
												>
													AED&nbsp;
													{order.totalPrice.amount - order.markup}
												</MKTypography>
											</Stack>
										</Grid>
										<Grid item sm={2}>
											<Stack direction={"column"}>
												<MKTypography
													variant='caption'
													color='dark'
													fontWeight='bold'
												>
													Change Status
												</MKTypography>
												{menus[order.lineItemStatus] ? (
													_.map(menus[order.lineItemStatus], (status) => {
														if (status.label) {
															return (
																<div
																	onClick={() =>
																		handleSelect(
																			order.lineItemStatus,
																			status,
																			order
																		)
																	}
																	style={{ cursor: "pointer" }}
																>
																	<MKTypography
																		variant='caption'
																		color='success'
																		fontWeight='bold'
																	>
																		{status.label}
																	</MKTypography>
																</div>
															);
														}
													})
												) : (
													<MKTypography
														variant='caption'
														color='dark'
														sx={{ mt: 1 }}
													>
														--:--
													</MKTypography>
												)}
											</Stack>
										</Grid>
										{order.ProductQuery.queryText && (
											<Grid item xs={12} sm={12}>
												<MKTypography variant='body2' color='error'>
													Remarks: {order.ProductQuery.queryText}
												</MKTypography>
											</Grid>
										)}

										<Grid item xs={12} sm={12}>
											<Divider sx={{ width: "100%" }} />
										</Grid>
									</Grid>
								);
							})}
						</AccordionDetails>
					</Accordion>
				);
			})} */}

			{/* {orderList.length ? (
				_.map(orderList, (order) => {
					console.log(order);
					return (
						<Grid
							container
							spacing={2}
							sx={{ padding: 1, backgroundColor: "#FCFFFC", mt: 1 }}
						>
							<Grid item sm={1}>
								<Stack direction='column' gap={1}>
									<MKTypography
										variant='caption'
										color='dark'
										fontWeight='bold'
									>
										OrderId
									</MKTypography>
									<MKTypography
										variant='caption'
										color='dark'
										sx={{ minWidth: 100 }}
									>
										{order?.id}
									</MKTypography>
								</Stack>
							</Grid>
							<Grid item sm={1}>
								<Stack direction='column' gap={1}>
									<MKTypography
										variant='caption'
										color='dark'
										fontWeight='bold'
									>
										Order Date
									</MKTypography>
									<MKTypography
										variant='caption'
										color='dark'
										sx={{ minWidth: 100 }}
									>
										{new Date(order.createdAt).toLocaleDateString("en-US", {
											year: "numeric",
											month: "short",
											day: "numeric",
										})}
									</MKTypography>
								</Stack>
							</Grid>
							<Grid item sm={1}>
								<Stack direction='column' gap={1}>
									<MKTypography
										variant='caption'
										color='dark'
										fontWeight='bold'
									>
										Color
									</MKTypography>
									<Stack
										direction={"row"}
										sx={{
											alignItems: "center",
											justifyContent: "flex-start",
											display: "flex",
										}}
										gap={1}
									>
										<Box
											sx={{
												width: 14,
												height: 14,
												backgroundColor: order?.ProductQuery?.color.hex,
											}}
										></Box>
										<MKTypography variant='caption' color='dark'>
											{order?.ProductQuery?.color.name}
										</MKTypography>
									</Stack>
								</Stack>
							</Grid>
							<Grid item xs={2} sm={3}>
								<Stack direction='column' gap={1}>
									<MKTypography
										variant='caption'
										color='dark'
										fontWeight='bold'
									>
										Product Details
									</MKTypography>
									<MKTypography variant='caption' color='dark'>
										{order.ProductQuery?.Brand.name} &nbsp;
										{order.ProductQuery?.Models.name} &nbsp;
										{order.Product.name}&nbsp;
										<strong>
											({order?.Variant ? order.Variant?.name : "Original"})
											&nbsp;X&nbsp;{order.quantity}
										</strong>
									</MKTypography>
								</Stack>
							</Grid>
							 
							<Grid item sm={2} xs={1}>
								<Stack direction='column' gap={1}>
									<MKTypography
										variant='caption'
										color='dark'
										fontWeight='bold'
									>
										Order Status
									</MKTypography>
									<MKTypography
										variant='caption'
										color='black'
										size='small'
										aria-controls={open ? "basic-menu" : undefined}
										aria-haspopup='true'
										aria-expanded={open ? "true" : undefined}
										onClick={handleClick}
									>
										{lineItemStatus[order.lineItemStatus]}
									</MKTypography>
								</Stack>
							</Grid>
							<Grid item sm={1}>
								<Stack direction='column' gap={1}>
									<MKTypography
										variant='caption'
										color='dark'
										fontWeight='bold'
									>
										Total
									</MKTypography>
									<MKTypography
										variant='caption'
										color='dark'
										fontWeight='bold'
									>
										AED&nbsp;
										{order.totalPrice.amount - order.markup}
									</MKTypography>
								</Stack>
							</Grid>
							<Grid item sm={2}>
								<Stack direction={"column"}>
									<MKTypography
										variant='caption'
										color='dark'
										fontWeight='bold'
									>
										Change Status
									</MKTypography>
									{menus[order.lineItemStatus] ? (
										_.map(menus[order.lineItemStatus], (status) => {
											if (status.label) {
												return (
													<div
														onClick={() =>
															handleSelect(order.lineItemStatus, status, order)
														}
														style={{ cursor: "pointer" }}
													>
														<MKTypography
															variant='caption'
															color='success'
															fontWeight='bold'
														>
															{status.label}
														</MKTypography>
													</div>
												);
											}
										})
									) : (
										<MKTypography variant='caption' color='dark' sx={{ mt: 1 }}>
											--:--
										</MKTypography>
									)}
								</Stack>
							</Grid>
							{order.ProductQuery.queryText && (
								<Grid item xs={12} sm={12}>
									<MKTypography variant='body2' color='error'>
										Remarks: {order.ProductQuery.queryText}
									</MKTypography>
								</Grid>
							)}

							<Grid item xs={12} sm={12}>
								<Divider sx={{ width: "100%" }} />
							</Grid>
						</Grid>
					);
				})
			) : (
				<Stack
					direction={"row"}
					gap={2}
					sx={{
						alignItems: "center",
						justifyContent: "center",
						display: "flex",
						height: "30vh",
					}}
				>
					<MKNodata size={100} />
					<MKTypography variant='h2' color='dark'>
						No Orders Yet
					</MKTypography>
				</Stack>
			)} */}
			<Box
				sx={{
					height: "10vh",
					width: "100%",
					alignItems: "center",
					justifyContent: "center",
					display: "flex",
				}}
			>
				<Pagination
					color='primary'
					count={Math.ceil(totalCount / count)}
					page={page}
					onChange={(e, val) => setPage(val)}
				/>
			</Box>
		</Box>
	);
};
export default MerchantOrders;
const styles = {
	box: {
		padding: 5,
		alignItems: "center",
		justifyContent: "flex-start",
		display: "flex",
		backgroundColor: "#F8FAFF",
	},
};
